import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import { TableModel } from 'src/app/models/table.model';
import { PageUrls } from 'src/app/helpers/page-urls';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EventService } from 'src/app/admin/services/event.service';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PagedPremiumEventHistoryModel } from 'src/app/admin/models/paged.premium.event.history.detail.model';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';

@Component({
    selector: 'app-premium-event-history',
    templateUrl: './premium.event.history.component.html',
    styleUrls: ['./premium.event.history.component.scss']
})

export class PremiumEventHistoryComponent implements OnInit {
    isModelLoaded: boolean;
    subscriptions = new Array<Subscription>();
    pagedPremiumEventHistoryDetails = new PagedPremiumEventHistoryModel();
    buyers = new Array<SelectListItemModel>();
    totalRecords = 0;
    tableModel = new TableModel();
    selectedView: number;
    closeResult: string;
    selectedBuyers = [];

    get urls() { return PageUrls; }

    constructor(private route: ActivatedRoute,
        private eventService: EventService,
        private toastr: ToastrService,
        private ngxSpinner: NgxSpinnerService) {
        this.tableModel.orderBy = 'firstName';
        this.tableModel.orderByDirection = 'desc';

        // filter from query params
        const orderBy = this.route.snapshot.queryParams.orderBy;
        if (!isNullOrUndefined(orderBy)) {
            this.tableModel.orderBy = orderBy;
        }
        const orderByDirection = this.route.snapshot.queryParams.orderByDirection;
        if (!isNullOrUndefined(orderByDirection)) {
            this.tableModel.orderByDirection = orderByDirection;
        }
        const pageSize = this.route.snapshot.queryParams.pageSize;
        if (!isNullOrUndefined(pageSize)) {
            this.tableModel.pageSize = pageSize;
        }
        const filterKey = this.route.snapshot.queryParams.filterKey;
        if (!isNullOrUndefined(filterKey)) {
            this.tableModel.filterKey = filterKey;
        }
        const startDate = this.route.snapshot.queryParams.startDate;
        if (!isNullOrUndefined(startDate)) {
            this.tableModel.startDate = startDate;
        }
        const endDate = this.route.snapshot.queryParams.endDate;
        if (!isNullOrUndefined(endDate)) {
            this.tableModel.endDate = endDate;
        }
        const page = this.route.snapshot.queryParams.page;
        if (!isNullOrUndefined(page)) {
            this.tableModel.page = page;
        }

    }

    ngOnInit() {
        this.loadBuyers();
        this.getPremiumEventHistory();
        this.initSubscribers();
    }

    loadBuyers() {
        this.eventService.getSharedEventBuyersSelectItems().subscribe(
            (response: Array<SelectListItemModel>) => {
                Object.assign(this.buyers, response);
            },
            error => {
                console.log(error);
            });
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getPremiumEventHistory());
        this.subscriptions.push(subscription);
    }

    getPremiumEventHistory = () => {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.eventService.getPremiumEventHistory(this.tableModel)
            .subscribe(data => {
                Object.assign(this.pagedPremiumEventHistoryDetails, data);
                this.totalRecords = data.totalCount;
                this.tableModel.totalPages = data.totalPages;
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    reset() {
        this.tableModel.filterKey = '';
        this.tableModel.page = 1;
    }
}
