import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MosaicModule } from '@ngx-photo-gallery/mosaic';
import { BuyerRoutingModule } from './buyer-routing.module';
import { BuyerProfileService } from 'src/app/buyer/services/profile.service';
import { OrderService } from 'src/app/buyer/services/order.service';
import { BuyerSubscriptionService } from 'src/app/buyer/services/subscription.service';
import { BuyerImageService } from 'src/app/buyer/services/image.service';
import { WishlistComponent } from './components/wishlist/wishlist.component';
import { BuyerComponent } from './buyer.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OrderListComponent } from './components/order/list/order.list.component';
import { OrderDetailComponent } from './components/order/detail/order.detail.component';
import { PremiumEventListComponent } from './components/premium-event/list/premium.event.list.component';
import { PremiumEventDetailComponent } from './components/premium-event/detail/premium.event.detail.component';
import { AppPremiumPhotoGalleryMosaicComponent } from './components/partial/premium-photo-gallery/premium.photo.gallery.component';
import { PremiumEventImagePreviewComponent } from './components/premium-event/preview/premium.event.image.preview.component';

@NgModule({
  declarations: [
    WishlistComponent,
    BuyerComponent,
    ProfileComponent,
    OrderListComponent,
    OrderDetailComponent,
    PremiumEventListComponent,
    PremiumEventDetailComponent,
    AppPremiumPhotoGalleryMosaicComponent,
    PremiumEventImagePreviewComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    BuyerRoutingModule,
    NgbModule,
    MosaicModule,
  ],
  providers: [
    BuyerProfileService,
    BuyerSubscriptionService,
    OrderService,
    BuyerImageService
  ],
  entryComponents: [
    PremiumEventImagePreviewComponent
  ],
})

export class BuyerModule { }
