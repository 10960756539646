import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PageUrls } from 'src/app/helpers/page-urls';
import { TableModel } from 'src/app/models/table.model';
import { PagedSubscriberDetailModel } from 'src/app/admin/models/paged.subscriber.detail.model';
import { UserService } from 'src/app/admin/services/user.service';
import { AppUtils } from 'src/app/helpers/app.utils';

@Component({
    selector: 'app-subscriber-list',
    templateUrl: './subscriber.list.component.html',
    styleUrls: ['./subscriber.list.component.scss']
})

export class SubscriberListComponent implements OnInit, OnDestroy {
    tableModel = new TableModel();
    totalRecords = 1;
    subscriptions = new Array<Subscription>();
    pagedSubscriberDetail = new PagedSubscriberDetailModel();
    isModelLoaded = false;

    constructor(private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private userService: UserService) {
        this.tableModel.orderBy = 'CreatedOn';
        this.tableModel.orderByDirection = 'desc';
    }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
        this.initSubscribers();
        this.getSubscribers();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getSubscribers());
        this.subscriptions.push(subscription);
    }

    getSubscribers() {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.userService.listSubscribers(this.tableModel)
            .subscribe(data => {
                this.pagedSubscriberDetail = data;
                this.totalRecords = data.totalCount;
                this.pagedSubscriberDetail.items.forEach((item) => {
                    item.subscriptionDate = AppUtils.getFormattedDate(item.subscriptionDate, null);
                    item.subscriptionStartDate = AppUtils.getFormattedDate(item.subscriptionStartDate, null);
                    item.subscriptionEndDate = AppUtils.getFormattedDate(item.subscriptionEndDate, null);
                });
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    renewSubscription(userId: string) {
        this.ngxSpinner.show();
        this.userService.renewSubscription(userId)
            .subscribe(() => {
                this.ngxSpinner.hide();
                setTimeout(() => {
                    this.getSubscribers();
                });
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    resetTableModel() {
        this.tableModel.page = 1;
    }
}
