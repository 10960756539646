import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { SubscriptionPlanAddModel } from 'src/app/admin/models/subscription.plan.add.model';
import { SubscriptionPlanService } from 'src/app/admin/services/subscription.plan.service';

@Component({
    selector: 'app-subscription-plan-add',
    templateUrl: './subscription.plan.add.component.html'
})
export class SubscriptionPlanAddComponent implements OnInit {
    model = new SubscriptionPlanAddModel();

    constructor(private router: Router,
        private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private subscriptionPlanService: SubscriptionPlanService) {
    }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
    }

    isPremiumChanged() {
        console.log(typeof this.model.isPremium);
        if (!this.model.isPremium) {
            this.model.totalSpecialSmallImages = 0;
            this.model.totalSpecialMediumImages = 0;
            this.model.totalSpecialLargeImages = 0;
        }
    }

    submit() {
        if (this.model.price === 0) {
            this.toastr.error('Price must be greater that zero.');
            return;
        }
        this.ngxSpinner.show();
        this.subscriptionPlanService.add(this.model).subscribe(
            () => {
                this.ngxSpinner.hide();
                this.router.navigateByUrl(`${PageUrls.Admin.SubscriptionPlanManage}`);
                setTimeout(() => {
                    this.toastr.success('Subscription plan has been added successfully');
                });
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}
