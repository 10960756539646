import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UploadImagesForApprovalComponent } from './components/upload-images-for-approval/upload-images-for-approval.component';
import { PhotographerAuthGuard } from '../guards/photographer-auth.guard';
import { PageUrlsParts, PageUrls } from '../helpers/page-urls';
import { DashboardComponent } from './dashboard.component';
import { ImagesHistoryComponent } from './components/images-history/images-history.component';
import { PhotographerProfileComponent } from './components/profile/profile.component';


const routes: Routes = [
  { path: PageUrlsParts.Photographer.Self, component: DashboardComponent, canActivate: [PhotographerAuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: PageUrls.Photographer.UploadImages },
      { path: PageUrlsParts.Photographer.UploadImages, component: UploadImagesForApprovalComponent },
      { path: PageUrlsParts.Photographer.ImagesHistory, component: ImagesHistoryComponent },
      { path: PageUrlsParts.Photographer.Profile, component: PhotographerProfileComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PhotographerRoutingModule { }
