export class ImageFilterModel {
    public categoryId: string;
    public eventId: string;
    public collectionId: string;
    public key: string;
    public photographerName: string;
    public searchType: string;

    constructor() {
        this.categoryId = '';
        this.eventId = '';
        this.collectionId = '';
        this.key = '';
        this.photographerName = '';
    }
}
