import { EventEmitter } from '@angular/core';

export class TableModel {
    filterKey: string;
    startDate: string;
    endDate: string;
    isSpecial: String;

    orderBy: string;
    orderByDirection: string;
    pageSize: number;
    maxSize: number;
    totalPages: number;

    private _page: number;
    get page() {
        return this._page;
    }
    set page(value: number) {
        this._page = value;
        this.pageChanged.emit(null);
    }

    pageChanged: EventEmitter<string>;

    constructor() {
        this.filterKey = '';
        this.startDate = '';
        this.endDate = '';
        this.orderBy = '';
        this.orderByDirection = '';
        this._page = 1;
        this.pageSize = 20;
        this.maxSize = 10;
        this.totalPages = 0;
        this.isSpecial = '';
        this.pageChanged = new EventEmitter();
    }

    toQueryParams = () => {
        return {
            orderBy: this.orderBy,
            orderByDirection: this.orderByDirection,
            page: this.page,
            pageSize: this.pageSize,
            filterKey: this.filterKey,
            startDate: this.startDate,
            endDate: this.endDate
        };
    }

    toQueryString = () => `orderBy=${this.orderBy}&orderByDirection=${this.orderByDirection}&page=${this.page}&pageSize=${this.pageSize}` +
        `&filterKey=${this.filterKey}&startDate=${this.startDate}&endDate=${this.endDate}&isSpecial=${this.isSpecial}`
}
