import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/helpers/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { Router } from '@angular/router';
import { ContributorRegistrationModel } from 'src/app/models/contributor.registration.model';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { CountryService } from 'src/app/services/country.service';

@Component({
  selector: 'app-contributor-registration',
  templateUrl: './contributor.registration.component.html',
  styleUrls: ['./contributor.registration.component.scss']
})
export class ContributorRegistrationComponent implements OnInit {
  loginUrl: string;
  model = new ContributorRegistrationModel();
  countries = new Array<SelectListItemModel>();
  emailPattern: string;
  confirmPassword: string;
  passwordPattern: string;

  get urls() {
    return PageUrls;
  }

  constructor(private ngxSpinnerService: NgxSpinnerService, private authService: AuthService,
    private countryService: CountryService,
    private toastr: ToastrService, private router: Router) { }

  ngOnInit() {
    this.emailPattern = Constants.emailValidationRegEx;
    this.passwordPattern = Constants.passwordRegEx;
    this.getCountries();
  }

  getCountries() {
    this.ngxSpinnerService.show();
    this.countryService.getSelectList()
      .subscribe(response => {
        this.countries = response;
        this.ngxSpinnerService.hide();
      },
        error => {
          console.error(error);
          this.ngxSpinnerService.hide();
          AppUtils.processErrorResponse(this.toastr, error);
        });
  }

  register() {
    this.ngxSpinnerService.show();
    this.authService.contributorRegistration(this.model)
      .subscribe(response => {
        this.ngxSpinnerService.hide();
        this.toastr.success(`A verification link will be sent to your registered email once the admin approves the account.
         Please click on the link to proceed with the verification process.`,
          '', {
          timeOut: 20000
        });
        this.router.navigateByUrl('/');
      },
        error => {
          console.error(error);
          this.ngxSpinnerService.hide();
          AppUtils.processErrorResponse(this.toastr, error);
        });
  }

}
