import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PageUrls } from 'src/app/helpers/page-urls';
import { TableModel } from 'src/app/models/table.model';
import { PagedBuyerDetailModel } from 'src/app/admin/models/paged.buyer.detail.model';
import { UserService } from 'src/app/admin/services/user.service';
import { AppUtils } from 'src/app/helpers/app.utils';
import { SortEvent } from 'src/app/shared/directives/sortable-header';
import { NgbdSortableHeader } from 'src/app/shared/directives/app.directives';

@Component({
    selector: 'app-user-buyer',
    templateUrl: './user.buyer.component.html',
    styleUrls: ['./user.buyer.component.scss']
})
export class UserBuyerComponent implements OnInit, OnDestroy {
    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
    tableModel = new TableModel();
    totalRecords = 1;
    subscriptions = new Array<Subscription>();
    pagedBuyerDetail = new PagedBuyerDetailModel();
    isModelLoaded = false;

    constructor(private router: Router, private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private userService: UserService) {
        this.tableModel.orderBy = 'User.FirstName';
        this.tableModel.orderByDirection = 'asc';
    }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
        this.initSubscribers();
        this.getBuyers();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getBuyers());
        this.subscriptions.push(subscription);
    }

    onSort({ column, direction }: SortEvent) {

        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        console.log(this.headers);

        // sorting countries
        if (direction === '' || column === '') {
            this.tableModel.orderBy = 'User.FirstName';
            this.tableModel.orderByDirection = 'asc';
            this.getBuyers();
        } else {
            console.log(column + ' : ' + direction);
            this.tableModel.orderBy = column;
            this.tableModel.orderByDirection = direction;
            this.getBuyers();
        }
    }

    getBuyers() {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.userService.listBuyers(this.tableModel)
            .subscribe(data => {
                this.pagedBuyerDetail = data;
                this.totalRecords = data.totalCount;
                this.pagedBuyerDetail.items.forEach((item) => {
                    item.subscriptionStartDate = AppUtils.getFormattedDate(item.subscriptionStartDate, null);
                    item.subscriptionEndDate = AppUtils.getFormattedDate(item.subscriptionEndDate, null);
                    item.createdOn = AppUtils.getFormattedDate(item.createdOn, null);
                });
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    deleteBuyer(id: number) {
        if (!confirm('Are you sure you want to delete this buyer?')) {
            return;
        }
        this.ngxSpinner.show();
        this.userService.deleteBuyer(id)
            .subscribe(() => {
                this.toastr.success('Buyer has been deleted successfully');
                this.ngxSpinner.hide();
                this.getBuyers();
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    openEditBuyer(id: number) {
        this.router.navigateByUrl(`${PageUrls.Admin.UserBuyerEdit}?buyerId=${id}`);
    }

    resetTableModel() {
        this.tableModel.page = 1;
    }
}
