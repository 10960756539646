import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { AppUtils } from 'src/app/helpers/app.utils';
import { Constants } from 'src/app/helpers/constants';
import { TableModel } from 'src/app/models/table.model';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { AddCartItemModel } from 'src/app/models/add.cart.item.model';
import { ImageDetail } from 'src/app/models/image-detail.model';
import { ImageFilterModel } from 'src/app/models/image-filter.model';
import { EventDetailModel } from 'src/app/models/event.detail.model';
import { AlbumModel } from 'src/app/models/album.model';
import { ImageDetailModel } from 'src/app/admin/models/image.detail.model';
import { PagedResponseModel } from 'src/app/models/paged.response.model';
import { AuthService } from 'src/app/services/auth.service';
import { ListenerService } from 'src/app/services/listener.service';
import { ImageService } from 'src/app/services/image-service';
import { EventService } from 'src/app/services/event.service';
import { ImageCategoryService } from 'src/app/services/image-category.service';
import { CartService } from 'src/app/services/cart.service';
import { WishlistService } from 'src/app/buyer/services/wishlist.service';

@Component({
    selector: 'app-image-filter',
    templateUrl: './image.filter.component.html',
    styleUrls: ['./image.filter.component.scss']
})

export class ImageFilterComponent implements OnInit {
    photoGalleryMosaicItems: Array<any>;
    imageCategories = new Array<AlbumModel>();
    images = new Array<ImageDetail>();
    tableModel = new TableModel();
    events = new Array<SelectListItemModel>();
    photographerList = new Array<SelectListItemModel>();
    searchModel = new ImageFilterModel();
    isBuyerUser = false;
    resultLayout = 'g';
    searchType = 'i';
    isImageLoaded = false;
    isEventLoaded = false;
    subscriptions = new Array<Subscription>();
    pagedImageDetails = new PagedResponseModel<ImageDetailModel>();
    pagedEventDetails = new PagedResponseModel<EventDetailModel>();
    totalRecords = 0;
    totalImages = 0;
    totalEvents = 0;
    showingFilterImagesForKey = '';

    constructor(private imageCategoryService: ImageCategoryService,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private listenerService: ListenerService,
        private eventService: EventService,
        private imageService: ImageService,
        private cartService: CartService,
        private wishlistService: WishlistService) {

        this.isBuyerUser = this.authService.isLoggedInUserInRole(Constants.Roles.Buyer);
        this.tableModel.orderBy = 'EventDate';
        this.tableModel.orderByDirection = 'DESC';

        if (!isNullOrUndefined(this.route.snapshot.queryParams.layout)) {
            this.resultLayout = this.route.snapshot.queryParams.layout.toLowerCase();
        }
        if (!isNullOrUndefined(this.route.snapshot.queryParams.search)) {
            this.searchType = this.route.snapshot.queryParams.search.toLowerCase();
        }
        if (!isNullOrUndefined(this.route.snapshot.queryParams.category)) {
            this.searchModel.categoryId = this.route.snapshot.queryParams.category;
        }
        if (!isNullOrUndefined(this.route.snapshot.queryParams.event)) {
            this.searchModel.eventId = this.route.snapshot.queryParams.event;
        }
        if (!isNullOrUndefined(this.route.snapshot.queryParams.collection)) {
            this.searchModel.collectionId = this.route.snapshot.queryParams.collection;
        }
        if (!isNullOrUndefined(this.route.snapshot.queryParams.key)) {
            this.searchModel.key = this.route.snapshot.queryParams.key;
            this.showingFilterImagesForKey = this.searchModel.key;
        }
        if (!isNullOrUndefined(this.route.snapshot.queryParams.photographer)) {
            this.searchModel.photographerName = this.route.snapshot.queryParams.photographer;
        }
        if (!isNullOrUndefined(this.route.snapshot.queryParams.orderBy)) {
            this.tableModel.orderBy = this.route.snapshot.queryParams.orderBy;
        }
        if (!isNullOrUndefined(this.route.snapshot.queryParams.orderByDirection)) {
            this.tableModel.orderByDirection = this.route.snapshot.queryParams.orderByDirection;
        }
        if (!this.resultLayout || (this.resultLayout !== 'm' && this.resultLayout !== 'g')) {
            this.resultLayout = 'm';
        }
        if (!this.searchType || (this.searchType !== 'i' && this.searchType !== 'e')) {
            this.searchType = 'i';
        }
    }

    ngOnInit() {
        this.searchModel.searchType = this.searchType;
        this.initSubscribers();
        this.loadImageCategories();
        this.loadPhotographers();
        this.loadEventSelectItems();
        this.loadImages();
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.loadImages());
        this.subscriptions.push(subscription);
    }

    loadImageCategories() {
        this.imageCategoryService.getCategories().subscribe(items => {
            this.imageCategories = items;
        }, error => {
            AppUtils.processErrorResponse(this.toastr, error);
        });
    }

    loadImages() {
        this.isImageLoaded = false;
        setTimeout(() => {
            this.spinner.show();
        });
        this.imageService.filterImages(this.searchModel, this.tableModel).subscribe(data => {
            Object.assign(this.pagedImageDetails, data);
            this.pagedImageDetails.items.forEach((image) => {
                image.eventDate = AppUtils.getFormattedDate(image.eventDate, null);
            });
            this.totalImages = data.totalCount;
            if (this.searchType === 'i') {
                this.totalRecords = data.totalCount;
            }
            this.loadWishlistedImages();
            this.spinner.hide();
            // this.loadEventSelectItems();
            this.loadEvents();
            this.isImageLoaded = true;
            AppUtils.ScrollToTop(100);
        }, error => {
            this.spinner.hide();
            AppUtils.processErrorResponse(this.toastr, error);
        });
    }

    loadEvents() {
        this.eventService.filterEvents(this.searchModel, this.tableModel)
            .subscribe(data => {
                Object.assign(this.pagedEventDetails, data);
                this.pagedImageDetails.items.forEach((event) => {
                    event.eventDate = AppUtils.getFormattedDate(event.eventDate, null);
                });
                this.totalEvents = data.totalCount;
                if (this.searchType === 'e') {
                    this.totalRecords = data.totalCount;
                }

                const imageHeight = window.innerWidth > 1900 ? 350 : 300;
                const tempArray = new Array<any>();
                this.pagedEventDetails.items.forEach((item) => {

                    const imageWidth = Math.round(imageHeight * item.coverImageWidth / item.coverImageHeight);

                    tempArray.push(
                        {
                            identifier: item.id,
                            fullSizePreview: null,
                            lowSizePreview: item.coverImageUrl,
                            realWidth: imageWidth,
                            realHeight: imageHeight,
                            eventTitle: item.title,
                            eventDate: item.date,
                            imageCount: item.imageCount,
                            eventCount: 0,
                            itemCount: item.imageCount,
                            isParentEvent: true
                        });
                });

                this.photoGalleryMosaicItems = tempArray;

                this.isEventLoaded = true;
            }, error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    loadPhotographers() {
        this.authService.getContributorSelectItems(this.searchModel).subscribe(
            (data: Array<SelectListItemModel>) => {
                this.photographerList = data;
                this.photographerList = this.photographerList.filter(x => !isNullOrUndefined(x));
            },
            error => {
                console.log(error);
            });
    }

    loadEventSelectItems() {
        this.eventService.getSelectItems(this.searchModel).subscribe(
            (data: Array<SelectListItemModel>) => {
                this.events = data;
            },
            error => {
                console.log(error);
            });
    }

    loadWishlistedImages() {
        if (this.isBuyerUser) {
            this.wishlistService.getImageIdList()
                .subscribe(
                    (response: Array<number>) => {
                        if (this.images) {
                            this.images.forEach((image) => {
                                image.isAddedToWishList = response.indexOf(image.imageId) !== -1;
                            });
                        }
                    },
                    error => {
                        console.log(error);
                    });
        }
    }

    eventSelected(selectedEvent: SelectListItemModel) {
        this.searchModel.eventId = selectedEvent.key.toString();
        this.startSearch();
    }

    startSearch() {
        let navigateUrl = `image/filter?search=${this.searchType}&layout=${this.resultLayout}`;

        if (this.searchModel.categoryId) {
            navigateUrl += `&category=${this.searchModel.categoryId}`;
        }
        if (this.searchModel.eventId) {
            navigateUrl += `&event=${this.searchModel.eventId}`;
        }
        if (this.searchModel.collectionId) {
            navigateUrl += `&collection=${this.searchModel.collectionId}`;
        }
        if (this.searchModel.key) {
            navigateUrl += `&key=${this.searchModel.key}`;
        }
        if (this.searchModel.photographerName) {
            navigateUrl += `&photographer=${this.searchModel.photographerName}`;
        }
        navigateUrl += `&${this.tableModel.toQueryString()}`;

        this.router.navigateByUrl(navigateUrl);
    }

    toggleWishlistItem(image: any) {
        this.spinner.show();
        this.wishlistService.toggle(image.imageId).subscribe(() => {
            this.spinner.hide();
            image.isAddedToWishList = !image.isAddedToWishList;
        }, error => {
            this.spinner.hide();
            AppUtils.processErrorResponse(this.toastr, error);
        });
    }

    addToCart(imageId: number) {
        const cartItem = new AddCartItemModel();
        cartItem.imageId = imageId;
        cartItem.imageSize = 3; // add large image by default

        this.spinner.show();
        this.cartService.addToCart(cartItem).subscribe(
            (data) => {
                AppUtils.setCartId(data.toString());
                this.listenerService.refreshCartListner.next();
                this.spinner.hide();
                this.toastr.success('Image has been added in the cart successfully');
            },
            error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    clearFilterKey() {
        this.searchModel.key = null;
        this.startSearch();
    }

    clearPhotographerFilter() {
        this.searchModel.photographerName = null;
        this.startSearch();
    }

    clearEventFilter() {
        this.searchModel.eventId = null;
        this.startSearch();
    }

    viewImageDetail(imageId: string) {
        window.open(`image-detail/${imageId}`, '_blank');
    }

    changeLayout() {
        this.startSearch();
    }

    changeSearchType(searchType: string) {
        this.searchType = searchType;
        this.startSearch();
    }

    clearAllFilters() {
        this.searchModel.photographerName = null;
        this.startSearch();
    }
}
