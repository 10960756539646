import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TableModel } from '../models/table.model';
import { ImageDetail } from '../models/image-detail.model';
import { ImageFilterModel } from '../models/image-filter.model';
import { ImageVariantDetailModel } from '../models/image.variant.detail.model';
import { PagedList } from '../models/paged-list';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  getImageDetail = (id: number): Observable<ImageDetail> =>
    this.http.get<ImageDetail>(`${environment.apiUrl}/api/image/${id}`)

  getImageUrl = (id: number) =>
    this.http.get(`${environment.apiUrl}/api/image/show-image/${id}`, { responseType: 'blob' })
      .pipe(map(blob => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))))

  getImageVariantDetail = (id: number): Observable<Array<ImageVariantDetailModel>> =>
    this.http.get<Array<ImageVariantDetailModel>>(`${environment.apiUrl}/api/image/variant-details/${id}`)

  getImages = (tableModel: TableModel) => this.http.get<PagedList<ImageDetail>>(
    `${environment.apiUrl}/api/image/list-by-category?${tableModel.toQueryString()}`)

  getImagesByCategory(categoryId: number, tableModel: TableModel) {
    return this.http.get<PagedList<ImageDetail>>(
      `${environment.apiUrl}/api/image/list-by-category?categoryId=${categoryId}&${tableModel.toQueryString()}`);
  }

  getPremiumEventsImages( tableModel: TableModel) {
    return this.http.get<PagedList<ImageDetail>>(
      `${environment.apiUrl}/api/image/list-by-premium-events-images?${tableModel.toQueryString()}`);
  }

  filterImages = (filterModel: ImageFilterModel, tableModel: TableModel) => {
    let requestUrl = `${environment.apiUrl}/api/image/filter?`;

    if (filterModel.categoryId) {
      requestUrl += `categoryId=${filterModel.categoryId}`;
    }
    if (filterModel.eventId) {
      requestUrl += `${requestUrl.endsWith('?') ? '' : '&'}eventId=${filterModel.eventId}`;
    }
    if (filterModel.collectionId) {
      requestUrl += `${requestUrl.endsWith('?') ? '' : '&'}collectionId=${filterModel.collectionId}`;
    }
    if (filterModel.key) {
      requestUrl += `${requestUrl.endsWith('?') ? '' : '&'}filterKey=${filterModel.key}`;
    }
    if (filterModel.photographerName) {
      requestUrl += `${requestUrl.endsWith('?') ? '' : '&'}photographer=${filterModel.photographerName}`;
    }

    requestUrl += `${requestUrl.endsWith('?') ? '' : '&'}${tableModel.toQueryString()}&searchType=${filterModel.searchType}`;

    return this.http.get<PagedList<ImageDetail>>(requestUrl);
  }

  getPhotographerNameList = () => this.http.get<Array<string>>(`${environment.apiUrl}/api/image/list-photographer-name`);
}
