import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableModel } from 'src/app/models/table.model';
import { PageUrls } from 'src/app/helpers/page-urls';
import { Subscription } from 'rxjs';
import { PagedEventDetails } from 'src/app/admin/models/paged.event.detail';
import { isNullOrUndefined } from 'util';
import { EventTableViewComponent } from '../table-view/event.table.view.component';
import { EventCalenderViewComponent } from '../calender-view/event.calender.view.component';

@Component({
    selector: 'app-event-manage',
    templateUrl: './event.manage.component.html',
    styleUrls: ['./event.manage.component.scss']
})
export class EventManageComponent implements OnInit {
    @ViewChild(EventCalenderViewComponent, null) calenderView: EventCalenderViewComponent;
    @ViewChild(EventTableViewComponent, null) tableView: EventTableViewComponent;

    subscriptions = new Array<Subscription>();
    pagedEventDetails = new PagedEventDetails();
    totalRecords = 0;
    tableModel = new TableModel();
    isModelLoaded = false;
    // year = 0 , month = 1 , table = 2
    selectedView: number;


    get urls() {
        return PageUrls;
    }

    constructor(private router: Router, private route: ActivatedRoute) {

        if (this.route.snapshot.queryParams.isTableView) {
            this.selectedView = 2;
        } else {
            this.selectedView = 0;
        }

        this.tableModel.orderBy = 'Date';
        this.tableModel.orderByDirection = 'desc';

        // filter from query params
        const orderBy = this.route.snapshot.queryParams.orderBy;
        if (!isNullOrUndefined(orderBy)) {
            this.tableModel.orderBy = orderBy;
        }
        const orderByDirection = this.route.snapshot.queryParams.orderByDirection;
        if (!isNullOrUndefined(orderByDirection)) {
            this.tableModel.orderByDirection = orderByDirection;
        }
        const pageSize = this.route.snapshot.queryParams.pageSize;
        if (!isNullOrUndefined(pageSize)) {
            this.tableModel.pageSize = pageSize;
        }
        const filterKey = this.route.snapshot.queryParams.filterKey;
        if (!isNullOrUndefined(filterKey)) {
            this.tableModel.filterKey = filterKey;
        }
        const startDate = this.route.snapshot.queryParams.startDate;
        if (!isNullOrUndefined(startDate)) {
            this.tableModel.startDate = startDate;
        }
        const endDate = this.route.snapshot.queryParams.endDate;
        if (!isNullOrUndefined(endDate)) {
            this.tableModel.endDate = endDate;
        }
        const page = this.route.snapshot.queryParams.page;
        if (!isNullOrUndefined(page)) {
            this.tableModel.page = page;
        }

    }

    ngOnInit() {
    }






    resetTableModel() {
        this.tableView.resetTableModel();
    }

    selectYearView() {
        this.selectedView = 0;
        this.router.navigate([], { queryParams: {} });
    }

    monthViewSelected() {
        this.selectedView = 1;
    }
}
