import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SafeUrl } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FullScreenViewer } from 'iv-viewer';
import { Constants } from 'src/app/helpers/constants';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AddCartItemModel } from 'src/app/models/add.cart.item.model';
import { ImageDetail } from 'src/app/models/image-detail.model';
import { ImageVariantDetailModel } from 'src/app/models/image.variant.detail.model';
import { PaymentCreateModel } from 'src/app/models/payment.create.model';
import { ImagePurchaseValidationModel } from 'src/app/buyer/models/image.purchase.validation.model';
import { OrderCreateModel } from 'src/app/buyer/models/order.create.model';
import { UserInfo } from 'src/app/models/user-info';
import { ListenerService } from 'src/app/services/listener.service';
import { ImageService } from 'src/app/services/image-service';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { BuyerImageService } from 'src/app/buyer/services/image.service';
import { OrderService } from 'src/app/buyer/services/order.service';
import { BuyerProfileService } from 'src/app/buyer/services/profile.service';
import { WishlistService } from 'src/app/buyer/services/wishlist.service';

@Component({
  selector: 'app-image-detail',
  templateUrl: './image-detail.component.html',
  styleUrls: ['./image-detail.component.scss']
})

export class ImageDetailComponent implements OnInit, OnDestroy {
  safeImageUrl: SafeUrl;
  imageId: number;
  userInfo: UserInfo;
  smallImageVariantDetail: ImageVariantDetailModel;
  mediumImageVariantDetail: ImageVariantDetailModel;
  largeImageVariantDetail: ImageVariantDetailModel;
  selectedImageSize = 1;
  isLoggedInUserBuyer = false;
  isLoggedInUserAdmin = false;
  isLoggedInUserPhotographer = false;
  subscriptions = new Array<Subscription>();
  imageDetail = new ImageDetail();
  orderModel = new OrderCreateModel();
  imagePurchaseValidationModel = new ImagePurchaseValidationModel();
  paymentCreateModel = new PaymentCreateModel();
  isModelLoaded = false;
  isImageLaoading = false;
  isPaymentProcessing = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private listenerService: ListenerService,
    private wishlistService: WishlistService,
    private imageService: ImageService,
    private cartService: CartService,
    private orderService: OrderService,
    private buyerImageService: BuyerImageService,
    private buyerProfileService: BuyerProfileService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.imageId = Number(params.get('imageId'));
    });
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.spinner.hide();
  }

  initSubscriptions() {
    const subscription = this.authService.userInfo$.subscribe(userInfo => {
      this.userInfo = userInfo;
      this.isLoggedInUserAdmin = this.authService.isLoggedInUserInRole(Constants.Roles.Admin);
      this.isLoggedInUserPhotographer = this.authService.isLoggedInUserInRole(Constants.Roles.Photographer);
      this.isLoggedInUserBuyer = this.authService.isLoggedInUserInRole(Constants.Roles.Buyer);
      this.getImageDetails(this.imageId);
      this.getImageVariantDetail(this.imageId);
    });
    this.subscriptions.push(subscription);
  }

  getImageDetails(imageId: number) {
    this.spinner.show();
    this.imageService.getImageDetail(imageId)
      .subscribe(
        (data) => {
          this.imageDetail = data;
          this.spinner.hide();
          this.isModelLoaded = true;
        },
        error => {
          AppUtils.processErrorResponse(this.toastr, error);
        });
  }

  getImageVariantDetail(imageId: number) {
    this.imageService.getImageVariantDetail(imageId)
      .subscribe(
        (imageVariants) => {

          imageVariants.forEach((imageVariant) => {

            if (imageVariant.size === 1) {
              this.smallImageVariantDetail = imageVariant;
              this.smallImageVariantDetail.widthInCm = AppUtils.convertToCmsFromPixels(this.smallImageVariantDetail.width);
              this.smallImageVariantDetail.heightInCm = AppUtils.convertToCmsFromPixels(this.smallImageVariantDetail.height);
              this.smallImageVariantDetail.totalMegaMegaPixels =
                AppUtils.convertToMegaPixelsFromResolution(this.smallImageVariantDetail.width, this.smallImageVariantDetail.height);
            }
            if (imageVariant.size === 2) {
              this.mediumImageVariantDetail = imageVariant;
              this.mediumImageVariantDetail.widthInCm = AppUtils.convertToCmsFromPixels(this.mediumImageVariantDetail.width);
              this.mediumImageVariantDetail.heightInCm = AppUtils.convertToCmsFromPixels(this.mediumImageVariantDetail.height);
              this.mediumImageVariantDetail.totalMegaMegaPixels =
                AppUtils.convertToMegaPixelsFromResolution(this.mediumImageVariantDetail.width, this.mediumImageVariantDetail.height);
            }
            if (imageVariant.size === 3) {
              this.largeImageVariantDetail = imageVariant;
              this.largeImageVariantDetail.widthInCm = AppUtils.convertToCmsFromPixels(this.largeImageVariantDetail.width);
              this.largeImageVariantDetail.heightInCm = AppUtils.convertToCmsFromPixels(this.largeImageVariantDetail.height);
              this.largeImageVariantDetail.totalMegaMegaPixels =
                AppUtils.convertToMegaPixelsFromResolution(this.largeImageVariantDetail.width, this.largeImageVariantDetail.height);
            }

          });

        },
        error => {
          AppUtils.processErrorResponse(this.toastr, error);
        });
  }

  toggleWish() {
    if (!this.isLoggedInUserBuyer) {
      return;
    }

    this.spinner.show();
    this.wishlistService.toggle(this.imageDetail.imageId)
      .subscribe(() => {
        this.spinner.hide();
        this.imageDetail.isAddedToWishList = !this.imageDetail.isAddedToWishList;
      }, error => {
        this.spinner.hide();
        AppUtils.processErrorResponse(this.toastr, error);
      });
  }

  addToCart() {
    const cartItem = new AddCartItemModel();
    cartItem.imageId = this.imageDetail.imageId;
    cartItem.imageSize = this.selectedImageSize;

    this.spinner.show();
    this.cartService.addToCart(cartItem).subscribe(
      (data) => {
        AppUtils.setCartId(data.toString());
        this.listenerService.refreshCartListner.next();
        this.imageDetail.isCartItem = true;
        this.spinner.hide();
        this.toastr.success('Image has been added in the cart successfully');
      },
      error => {
        this.spinner.hide();
        AppUtils.processErrorResponse(this.toastr, error);
      });
  }

  openImagePreviewModal() {
    const viewer = new FullScreenViewer({ snapView: false, zoomValue: 100, maxZoom: 100 });
    viewer.show(this.imageDetail.imageUrl);
    setTimeout(() => {
      viewer.resetZoom();
    }, 2000);

  }

  imageLoaded() {
    this.isImageLaoading = false;
  }

  initOrder() {
    if (!this.isLoggedInUserBuyer) {
      const callbackUrl = `${PageUrls.ImageDetail}/${this.imageDetail.imageId}`;
      this.router.navigate([PageUrls.Login], { queryParams: { url: callbackUrl } });
      return;
    }
    this.orderModel.imageId = this.imageDetail.imageId;
    this.orderModel.imageSize = this.selectedImageSize;
    this.validatePurchase();
  }

  validatePurchase() {
    this.spinner.show();
    this.buyerImageService.validatePurchase(this.orderModel.imageId, this.orderModel.imageSize)
      .subscribe((response) => {
        this.spinner.hide();
        this.imagePurchaseValidationModel = response;
        if (this.imagePurchaseValidationModel.canPurchaseImage) {
          this.creatOrder();
        } else {
          setTimeout(() => {
            this.initPayment();
          });
        }
      },
        error => {
          this.spinner.hide();
          AppUtils.processErrorResponse(this.toastr, error);
        });
  }

  initPayment() {
    this.spinner.show();
    this.buyerProfileService.getProfile()
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.paymentCreateModel.amount = this.imagePurchaseValidationModel.paybleAmount;
          this.paymentCreateModel.firstName = response.firstName;
          this.paymentCreateModel.lastName = response.lastName;
          this.paymentCreateModel.email = response.email;
          this.paymentCreateModel.address = response.address;
          this.paymentCreateModel.city = response.city;
          this.paymentCreateModel.zipCode = response.zipCode;
          if (response.country) {
            this.paymentCreateModel.country = response.country;
          }

          this.isPaymentProcessing = true;
        },
        error => {
          this.spinner.hide();
          AppUtils.processErrorResponse(this.toastr, error);
        });
  }

  paymentCompleted(paymentIntentId: string) {
    this.orderModel.paymentId = paymentIntentId;
    this.creatOrder();
  }

  paymentCancelled() {
    this.isPaymentProcessing = false;
    this.paymentCreateModel = new PaymentCreateModel();
  }

  creatOrder() {
    this.spinner.show();
    this.orderService.createFromImage(this.orderModel).subscribe(
      (orderId: string) => {
        this.spinner.hide();
        this.router.navigate([PageUrls.Buyer.OrderDetail], { queryParams: { id: orderId } });
        setTimeout(() => {
          this.toastr.success('Your order has been placed successfully');
        }, 200);
      },
      error => {
        this.spinner.hide();
        AppUtils.processErrorResponse(this.toastr, error);
      });
  }

  clickedOnTag(keyword) {
    this.router.navigate(
      ['image/filter'],
      { queryParams: { key: keyword } });
  }

}
