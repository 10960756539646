import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TreeviewItem } from 'ngx-treeview';

@Component({
    selector: 'app-treeview-modal',
    templateUrl: './treeview.modal.component.html'
})

export class TreevewModalComponent {
    @ViewChild('treeviewModal', { static: false }) treeviewModal: any;
    @Input() items: Array<TreeviewItem>;
    @Output() modalClosed = new EventEmitter();
    modalReference: NgbModalRef;
    treeviewConfig: any;

    constructor(private modalService: NgbModal) {
        this.treeviewConfig = {
            hasAllCheckBox: true,
            hasFilter: true,
            hasCollapseExpand: false,
            decoupleChildFromParent: true,
            maxHeight: 500
        };
    }

    openModal() {
        this.modalReference = this.modalService.open(this.treeviewModal, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
    }

    closeModal() {
        this.modalClosed.emit();
        this.modalReference.close();
    }
}
