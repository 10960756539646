import { OrderItemDetailModel } from './order.item.detail.model';

export class OrderDetailModel {
    public id: string;
    public orderNumber: string;
    public userId: string;
    public customerName: string;
    public customerEmail: string;
    public subscriptionId: number;
    public totalAmount: number;
    public createdOn: string;
    public status: number;
    public orderItems: Array<OrderItemDetailModel>;

    constructor() {
        this.orderItems = new Array<OrderItemDetailModel>();
    }
}
