import { Component, OnInit } from '@angular/core';
import { SetPasswordModel } from 'src/app/models/set-password.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/helpers/constants';
import { AppUtils } from 'src/app/helpers/app.utils';
import { AuthService } from 'src/app/services/auth.service';
import { PageUrls } from 'src/app/helpers/page-urls';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  model: SetPasswordModel;
  confirmPassword: string;
  passwordPattern: string;

  constructor(private ngxSpinnerService: NgxSpinnerService, private authService: AuthService,
              private toastr: ToastrService, private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.authService.logout();
    this.passwordPattern = Constants.passwordRegEx;
    this.model = new SetPasswordModel();
    this.model.uid = this.activatedRoute.snapshot.queryParams.uid;
    this.model.Key = this.activatedRoute.snapshot.queryParams.key;
  }

  submit() {
    this.ngxSpinnerService.show();
    this.authService.setPassword(this.model)
      .subscribe(response => {
        console.log(response);
        this.ngxSpinnerService.hide();
        this.toastr.success('Your password has been reset successfully. Please login to continue.',
          '', {
          timeOut: 20000
        });
        this.router.navigateByUrl(`/${PageUrls.Login}`);
      },
        error => {
          console.error(error);
          this.ngxSpinnerService.hide();
          AppUtils.processErrorResponse(this.toastr, error);
        });
  }
}
