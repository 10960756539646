export enum UserType {
    Admin = 1,
    Buyer = 2,
    Photographer = 3
}

export enum ImageStatusEnum {
    WaitingForApproval = 1,
    Active = 2,
    Inactive = 3,
    Deleted = 4,
    Rejected = 5
}
