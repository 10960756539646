import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MosaicModule } from '@ngx-photo-gallery/mosaic';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { BuyerModule } from './buyer/buyer.module';
import { PhotographerModule } from './photographer/photographer.module';
import { AdminModule } from './admin/admin.module';

import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { AuthGuard } from './guards/auth.guard';
import { AnonymousGuard } from './guards/anonymous.guard';
import { PhotographerAuthGuard } from './guards/photographer-auth.guard';
import { AdminAuthGuard } from './guards/admin-auth.guard';
import { BuyerAuthGuard } from './guards/buyer-auth.guard';
import { AnonymousOrBuyerGuard } from './guards/anonymous.or.buyer.guard';

import { NgbCustomDateParserFormatter } from './helpers/ng.custom.date.formatter';

import { AppComponent } from './app.component';
import { TopBarComponent } from './components/shared/top-bar/top-bar.component';
import { HeroSectionComponent } from './components/shared/hero-section/hero-section.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { AppPhotoGalleryMosaicComponent } from './components/partials/photo-gallery/photo.gallery.mosaic.partial.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ImageFilterComponent } from './components/image';
import { RecentPhotosComponent } from './components/shared/recent-photos/recent-photos.component';
import { ImageDetailComponent } from './components/image-detail/image-detail.component';
import { CollectionListComponent, CollectionListNavComponent, CollectionPhotoGalleryComponent } from './components/collection';
import { CartComponent } from './components/cart/cart.component';
import { SubscriptionPlanListComponent } from './components/subscription';
import { StripePaymentComponent } from './components/stripe';
import { GoogleLoginProvider, AuthServiceConfig } from 'angularx-social-login';
import { AboutUsComponent } from './components/shared/about-us/about-us.component';
import { PrivacyPolicyComponent } from './components/shared/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './components/shared/contact-us/contact-us.component';
import { TermsAndConditionComponent } from './components/shared/terms-and-condition/terms-and-condition.component';
import { WorkWithUsComponent } from './components/shared/work-with-us/work-with-us.component';


// login with google and facebook start
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    // TODO
    provider: new GoogleLoginProvider('307142463311-3k7s54ah4qetcvfmc55gogsu3j05vkc6.apps.googleusercontent.com')
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    TopBarComponent,
    HeroSectionComponent,
    FooterComponent,
    AppPhotoGalleryMosaicComponent,
    RecentPhotosComponent,
    ImageFilterComponent,
    ImageDetailComponent,
    CollectionPhotoGalleryComponent,
    CollectionListNavComponent,
    CollectionListComponent,
    CartComponent,
    SubscriptionPlanListComponent,
    StripePaymentComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    ContactUsComponent,
    TermsAndConditionComponent,
    WorkWithUsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgbModule,
    MosaicModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    SharedModule,
    AuthModule,
    PhotographerModule,
    AdminModule,
    BuyerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbCustomDateParserFormatter
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    AuthGuard,
    AnonymousGuard,
    PhotographerAuthGuard,
    BuyerAuthGuard,
    AdminAuthGuard,
    AnonymousOrBuyerGuard,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
