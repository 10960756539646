import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ListenerService {

  public closeSidebarListner = new Subject<any>();
  public refreshCartListner = new Subject<any>();

  get closeSidebar() {
    return this.closeSidebarListner.asObservable();
  }

  get refreshCart() {
    return this.refreshCartListner.asObservable();
  }
}
