import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { AppUtils } from 'src/app/helpers/app.utils';
import { AlbumModel } from 'src/app/models/album.model';
import { TableModel } from 'src/app/models/table.model';
import { PagedList } from 'src/app/models/paged-list';
import { CollectionFilterModel } from 'src/app/models/collection.filter.model';
import { ImageCategoryService } from 'src/app/services/image-category.service';
import { CollectionService } from 'src/app/services/collection.service';
import { CollectionListItemModel } from 'src/app/models/collection.list.item.model';

@Component({
    selector: 'app-collection-list',
    templateUrl: './collection.list.component.html',
    styleUrls: ['./collection.list.component.scss']
})

export class CollectionListComponent implements OnInit, OnDestroy {
    subscriptions = new Array<Subscription>();
    imageCategories = new Array<AlbumModel>();
    tableModel = new TableModel();
    filterModel = new CollectionFilterModel();
    featuredCollections = new Array<CollectionListItemModel>();
    pagedList = new PagedList<CollectionListItemModel>();
    collections = new Array<CollectionListItemModel>();
    photoGalleryMosaicItems: Array<any>;
    isFeaturedCollectionsLoaded = false;
    isCollectionsLoaded = false;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private imageCategoryService: ImageCategoryService,
        private collectionService: CollectionService) {
        this.tableModel.orderBy = 'CreatedOn';
        this.tableModel.orderByDirection = 'DESC';

        if (!isNullOrUndefined(this.route.snapshot.queryParams.category)) {
            this.filterModel.categoryId = this.route.snapshot.queryParams.cId;
        }
        if (!isNullOrUndefined(this.route.snapshot.queryParams.filterKey)) {
            this.filterModel.filterKey = this.route.snapshot.queryParams.filterKey;
        }
        if (!isNullOrUndefined(this.route.snapshot.queryParams.orderBy)) {
            this.tableModel.orderBy = this.route.snapshot.queryParams.orderBy;
        }
        if (!isNullOrUndefined(this.route.snapshot.queryParams.orderByDirection)) {
            this.tableModel.orderByDirection = this.route.snapshot.queryParams.orderByDirection;
        }
    }

    ngOnInit() {
        this.loadImageCategories();
        this.loadFeaturedCollections();
        this.loadCollections();

        const subscription = this.tableModel.pageChanged.subscribe(() => this.loadCollections());
        this.subscriptions.push(subscription);
    }

    loadImageCategories() {
        this.imageCategoryService.getCategories().subscribe(items => {
            this.imageCategories = items;
        }, error => {
            AppUtils.processErrorResponse(this.toastr, error);
        });
    }

    loadFeaturedCollections() {
        this.spinner.show();
        this.collectionService.listFeatured().subscribe(
            (data) => {
                Object.assign(this.featuredCollections, data);
                this.spinner.hide();
                this.isFeaturedCollectionsLoaded = true;
            },
            error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    loadCollections() {
        this.isCollectionsLoaded = false;
        this.spinner.show();
        this.collectionService.filterCollections(this.filterModel, this.tableModel)
            .subscribe(
                (response) => {
                    Object.assign(this.pagedList, response);
                    if (this.pagedList.items.length > 0) {
                        this.pagedList.items.forEach(item => {
                            this.collections.push(item);
                        });
                    }
                    this.updateCollectionPhotoGallery();
                    this.isCollectionsLoaded = true;
                    this.spinner.hide();
                },
                error => {
                    this.spinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    updateCollectionPhotoGallery() {

        const imageHeight = window.innerWidth > 1900 ? 350 : 300;
        const tempArray = new Array<any>();

        this.collections.forEach((item) => {

            const imageWidth = Math.round(imageHeight * item.coverImageWidth / item.coverImageHeight);

            tempArray.push(
                {
                    identifier: item.id,
                    fullSizePreview: null,
                    lowSizePreview: item.coverImageUrl,
                    realWidth: imageWidth,
                    realHeight: imageHeight,
                    title: item.title,
                    date: item.createdOn,
                    itemCount: item.eventCount
                });
        });

        this.photoGalleryMosaicItems = tempArray;
    }

    startSearch() {
        const queryParams: Params = {};
        if (this.filterModel.categoryId) {
            const category = this.imageCategories.find(x => x.id === Number(this.filterModel.categoryId));
            queryParams.category = category.name;
            queryParams.cId = category.id;
        }

        if (this.filterModel.filterKey) {
            queryParams.filterKey = this.filterModel.filterKey;
        }

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: queryParams
            });
    }

    clearFilterKey() {
        this.filterModel.filterKey = null;
    }

    loadMore() {
        this.tableModel.page += 1;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

}


