import { Component, ViewChild, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/app/helpers/app.utils';
import { Constants } from 'src/app/helpers/constants';
import { CountryModel } from 'src/app/models/country.model';
import { PaymentCreateModel } from 'src/app/models/payment.create.model';
import { CountryService } from 'src/app/services/country.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
    selector: 'app-stripe-payment',
    templateUrl: './stripe.payment.component.html'
})

export class StripePaymentComponent implements OnInit {
    @ViewChild('f', { static: false }) form: NgForm;
    @Input() model: PaymentCreateModel;
    @Output() paymentCompleted = new EventEmitter<string>();
    @Output() paymentCancelled = new EventEmitter();
    stripe: any;
    stripeElements: any;
    stripeCard: any;
    cardError: any;
    paymentIntentResponse: any;
    stripeKey = Constants.stripeKey;
    stripeCardLoaded = false;
    stripeCardStyle = {
        base: {
            color: '#495057',
            fontFamily: '"Poppins", sans-serif',
            fontSize: '18px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#dc3545 ',
            iconColor: '#dc3545 '
        }
    };
    countries = new Array<CountryModel>();

    constructor(private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private countryService: CountryService,
        private paymentService: PaymentService) { }

    ngOnInit() {
        this.loadCountries();
        this.loadStripe();
    }

    loadCountries() {
        this.countryService.getList().subscribe(
            (countries: Array<CountryModel>) => {
                Object.assign(this.countries, countries);
            },
            error => {
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    loadStripe() {
        this.spinner.show();
        if (!window.document.getElementById('stripe-script')) {
            const s = window.document.createElement('script');
            s.id = 'stripe-script';
            s.type = 'text/javascript';
            s.src = 'https://js.stripe.com/v3/';
            s.onload = () => {
                console.log('stripe script loaded');
                this.spinner.hide();
                // init payment after script load done
                this.initPayment();
            };
            window.document.body.appendChild(s);
        } else {
            // init payment as script is already loaded
            this.initPayment();
        }
    }

    initPayment() {
        this.stripe = (<any>window).Stripe(this.stripeKey);
        this.stripeElements = this.stripe.elements();
        this.stripeCard = this.stripeElements.create('card', {
            style: this.stripeCardStyle,
            hidePostalCode: true
        });
        this.stripeCard.mount('#card-element');
        this.cardError = document.getElementById('card-errors');
        this.stripeCardLoaded = true;
    }

    cancelPayment() {
        this.paymentCancelled.emit();
    }

    submit() {
        if (this.stripeCard._empty) {
            this.toastr.error('Please fill the card detail before continue');
            return;
        }
        this.createPaymentMehod();
    }

    createPaymentMehod() {
        this.spinner.show();
        const self = this;
        this.stripe.createPaymentMethod({
            type: 'card',
            card: this.stripeCard,
            billing_details: {
                name: `${this.model.firstName} ${this.model.lastName}`,
                email: this.model.email,
                phone: this.model.phone,
                address: {
                    line1: this.model.address,
                    city: this.model.city,
                    country: this.model.country,
                    postal_code: this.model.zipCode
                },
            }
        }).then(function (result: any) {
            self.spinner.hide();

            if (result.error) {
                console.log(result);
                self.cardError.textContent = result.error.message;
                return;
            }
            // payment method created
            self.model.paymentMethodId = result.paymentMethod.id;
            // create payment intent
            setTimeout(() => {
                self.createPaymentIntent();
            });

        });
    }

    createPaymentIntent() {
        this.spinner.show();
        this.paymentService.createPaymentIntent(this.model)
            .subscribe(
                (response: any) => {
                    this.spinner.hide();
                    // payment intent created
                    this.paymentIntentResponse = response;

                    if (this.paymentIntentResponse.status === 'requires_action') {
                        // handle for 2fa authentication
                        setTimeout(() => {
                            this.handleCardAction();
                        });

                    } else if (this.paymentIntentResponse.status === 'succeeded') {
                        // payment completed
                        setTimeout(() => {
                            this.paymentCompleted.emit(this.paymentIntentResponse.id);
                        });
                    } else {
                        // confirm payment
                        setTimeout(() => {
                            this.confirmPaymentIntent();
                        });

                    }
                },
                error => {
                    this.spinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    handleCardAction() {
        const self = this;
        this.spinner.show();
        this.stripe.handleCardAction(this.paymentIntentResponse.client_secret)
            .then(function (result: any) {
                self.spinner.hide();

                if (result.error) {
                    console.log(result);
                    self.cardError.textContent = result.error.message;
                    return;
                }

                self.paymentIntentResponse = result.paymentIntent;

                if (self.paymentIntentResponse.status === 'succeeded') {
                    // payment completed
                    setTimeout(() => {
                        this.paymentCompleted.emit(this.paymentIntentResponse.id);
                    });

                } else {
                    // confirm payment
                    setTimeout(() => {
                        self.confirmPaymentIntent();
                    });
                }
            });
    }

    confirmPaymentIntent() {
        this.spinner.show();
        this.paymentService.confirmPaymentIntent(this.paymentIntentResponse.id)
            .subscribe(
                () => {
                    this.spinner.hide();
                    // payment completed
                    setTimeout(() => {
                        this.paymentCompleted.emit(this.paymentIntentResponse.id);
                    });
                },
                error => {
                    this.spinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    cancelPaymentIntent() {
        this.spinner.show();
        this.paymentService.cancelPaymentIntent(this.paymentIntentResponse.id)
            .subscribe(
                () => {
                    this.spinner.hide();
                    this.toastr.error('Sorry we are unable to process your payment');
                },
                error => {
                    this.spinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }
}
