import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-photo-gallery-mosaic',
    templateUrl: './photo.gallery.mosaic.partial.component.html',
    styleUrls: ['./photo.gallery.mosaic.partial.component.scss']
})

export class AppPhotoGalleryMosaicComponent {

    @Input() photoGalleryMosaicItems: Array<any>;
    @Output() itemSelected = new EventEmitter<any>();

    get perfectHeight() {
        return Number.parseInt((window.innerWidth > 1900 ? 350 : 300).toString(), 10);
    }

    widthGetterFn = (image: any) => image.realWidth;
    heightGetterFn = (image: any) => image.realHeight;
    lowSizePreviewGetter = (image: any) => image.lowSizePreview;

    imageSelected(item: any) {
        if (item.eventCount !== 0) {
            this.itemSelected.emit(item.identifier);
        } else {
            window.open(`${location.origin}/image/filter?event=${item.identifier}`, '_blank');
        }
    }

}
