import { Component, OnInit, OnDestroy } from '@angular/core';
import { TableModel } from 'src/app/models/table.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PagedWishlistDetails } from '../../models/pagedWishlistDetails';
import { WishlistService } from '../../services/wishlist.service';
import { AppUtils } from 'src/app/helpers/app.utils';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit, OnDestroy {
  tableModel = new TableModel();
  pagedWishlistDetails = new PagedWishlistDetails();
  subscriptions = new Array<Subscription>();
  totalRecords = 0;
  isModelLoaded = false;

  constructor(private ngxSpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private wishlistService: WishlistService) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  ngOnInit() {
    this.getImages();
  }

  initSubscribers() {
    const subscription = this.tableModel.pageChanged.subscribe(() => this.getImages());
    this.subscriptions.push(subscription);
  }

  getImages = () => {

    this.ngxSpinnerService.show();

    this.wishlistService.getWishlistImages(this.tableModel)
      .subscribe(data => {
        this.pagedWishlistDetails = data;
        this.totalRecords = data.totalCount;
        this.ngxSpinnerService.hide();
        this.isModelLoaded = true;
      }, error => {
        this.ngxSpinnerService.hide();
        AppUtils.processErrorResponse(this.toastr, error);
      });
  }

  openImageDetail(imageId: number) {
    this.router.navigateByUrl(`image-detail/${imageId}`);
  }
}
