import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppUtils } from 'src/app/helpers/app.utils';
import { TableModel } from 'src/app/models/table.model';
import { PageUrls } from 'src/app/helpers/page-urls';
import { Subscription } from 'rxjs';
import { PagedSubscriptionPlanDetails } from 'src/app/admin/models/paged.subscription.plan.detail.model';
import { SubscriptionPlanService } from 'src/app/admin/services/subscription.plan.service';
import { ImageTypeModel } from 'src/app/models/image.type.model';

@Component({
    selector: 'app-subscription-plan-manage',
    templateUrl: './subscription.plan.manage.component.html',
    styleUrls: ['./subscription.plan.manage.component.scss']
})
export class SubscriptionPlanManageComponent implements OnInit {

    subscriptions = new Array<Subscription>();
    imageSizes = new Array<ImageTypeModel>();
    pagedSubscriptionPlanDetails = new PagedSubscriptionPlanDetails();
    totalRecords = 1;
    tableModel = new TableModel();
    isModelLoaded = false;

    get urls() {
        return PageUrls;
    }

    constructor(private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private subscriptionPlanService: SubscriptionPlanService,
        private router: Router) {
        this.tableModel.orderBy = 'Name';
        this.tableModel.orderByDirection = 'asc';
    }


    ngOnInit() {
        this.imageSizes = AppUtils.getImageTypes();
        this.initSubscribers();
        this.getSubscriptionPlans();
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getSubscriptionPlans());
        this.subscriptions.push(subscription);
    }

    getSubscriptionPlans = () => {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.subscriptionPlanService.list(this.tableModel)
            .subscribe(data => {
                Object.assign(this.pagedSubscriptionPlanDetails, data);
                this.totalRecords = data.totalCount;
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    toggleSubscriptionPlan(id: number, isActive: boolean) {
        var selectedSubscriptionPlan = this.pagedSubscriptionPlanDetails.items.find(x => x.id === id);
        if (isActive && !selectedSubscriptionPlan.isPremium) {
            const activeSubscription = this.pagedSubscriptionPlanDetails.items.filter(x => Number(x.status) === 1 && selectedSubscriptionPlan.isPremium === x.isPremium);
            if (activeSubscription.length > 2) {
                this.toastr.error('Maximum 3 subscription plans can be activated at a time');
                return;
            }
        }
        this.ngxSpinner.show();
        this.subscriptionPlanService.toggle(id)
            .subscribe(() => {
                this.toastr.success('Subscription plan status has been changed successfully');
                this.ngxSpinner.hide();
                this.getSubscriptionPlans();
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    deleteSubscriptionPlan(id: number) {
        if (!confirm('Are you sure you want to delete this subscription plan?')) {
            return;
        }
        this.ngxSpinner.show();
        this.subscriptionPlanService.delete(id)
            .subscribe(() => {
                this.toastr.success('Subscription plan has been deleted successfully');
                this.ngxSpinner.hide();
                this.getSubscriptionPlans();
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    openEditSubscriptionPlan(id: number) {
        this.router.navigateByUrl(`${PageUrls.Admin.SubscriptionPlanEdit}?subscriptionPlanId=${id}`);
    }
}
