import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './components/login/login.component';
import { BuyerRegistrationComponent } from './components/buyer-registration/buyer.registration.component';
import { ContributorRegistrationComponent } from './components/contributor-registration/contributor.registration.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { SocialLoginModule } from 'angularx-social-login';

@NgModule({
  declarations: [
    LoginComponent,
    BuyerRegistrationComponent,
    ContributorRegistrationComponent,
    PasswordResetComponent,
    EmailVerificationComponent,
    ForgotPasswordComponent,
    SetPasswordComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    SocialLoginModule
  ]
})
export class AuthModule { }
