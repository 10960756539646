export class SelectionFilterModel<T> {
    gridFilter: T;
    selectedAll: boolean;
    selectedItems = new Array<string>();
    deselectedItems = new Array<string>();
    constructor() {
        this.selectedAll = false;
        this.selectedItems = new Array<string>();
        this.deselectedItems = new Array<string>();
    }
}
