import { Component, OnInit } from '@angular/core';
import { AppUtils } from 'src/app/helpers/app.utils';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderService } from 'src/app/buyer/services/order.service';
import { PageUrls } from 'src/app/helpers/page-urls';
import { OrderDetailModel } from 'src/app/buyer/models/order.detail.model';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-order-detail',
    templateUrl: './order.detail.component.html'
})
export class OrderDetailComponent implements OnInit {

    model = new OrderDetailModel();
    imageTypes = AppUtils.getImageTypes();
    isModelLoaded = false;

    get urls() {
        return PageUrls;
    }

    constructor(private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private orderService: OrderService,
        private route: ActivatedRoute) { }

    ngOnInit() {
        this.model.id = this.route.snapshot.queryParams.id;
        this.getOrder();
    }

    getOrder() {
        this.spinner.show();
        this.orderService.getOrderById(this.model.id).subscribe(
            data => {
                Object.assign(this.model, data);
                this.spinner.hide();
                this.model.createdOn = AppUtils.getFormattedDate(this.model.createdOn);
                this.model.orderItems.forEach(item => {
                    item.imageSize = this.imageTypes.find(x => x.id === item.imageSize.toString()).name;
                });
                this.isModelLoaded = true;
            },
            error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    downloadFiles() {
        this.spinner.show();
        this.orderService.downloadFiles(this.model.id).subscribe(fileBytes => {
            this.spinner.hide();
            const blob: any = new Blob([fileBytes], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const element = document.createElement('a');
            element.href = url;
            element.download = `${this.model.id}.zip`;
            document.body.appendChild(element);
            element.click();
        }, error => {
            this.spinner.hide();
            AppUtils.processErrorResponse(this.toastr, error);
        });
    }
}
