export class SelectionEventFilterModel<T> {
    gridFilter: T;
    selectedAll: boolean;
    selectedItems = new Array<number>();
    deselectedItems = new Array<number>();

    constructor() {
        this.selectedAll = false;
        this.selectedItems = new Array<number>();
        this.deselectedItems = new Array<number>();
    }
}
