import { Component, OnInit } from '@angular/core';
import { FileUploader, FileItem, ParsedResponseHeaders, FileLikeObject } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { IconDefinition, faBan, faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';
import { PhotographerImageService } from '../../services/photographer-image.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppUtils } from 'src/app/helpers/app.utils';

@Component({
  selector: 'app-upload-images-for-approval',
  templateUrl: './upload-images-for-approval.component.html',
  styleUrls: ['./upload-images-for-approval.component.scss']
})
export class UploadImagesForApprovalComponent implements OnInit {

  uploader: FileUploader;

  faUpload: IconDefinition;
  faBan: IconDefinition;
  faTimes: IconDefinition;

  constructor(private toastr: ToastrService, private authService: AuthService,
    private imageService: PhotographerImageService, private spinner: NgxSpinnerService) {
    this.uploader = new FileUploader({
      url: `${environment.apiUrl}/api/photographer/image`,
      disableMultipart: false,
      formatDataFunctionIsAsync: false,
      allowedMimeType: ['image/jpeg', 'image/jpg'],
      autoUpload: true,
      authToken: `Bearer ${this.authService.getAuthToken()}`
    });
    this.uploader.onErrorItem = this.onErrorItem;
    this.uploader.onWhenAddingFileFailed = this.onWhenAddingFileFailed;
    this.uploader.onAfterAddingFile = this.onAfterAddingFile;
  }

  ngOnInit() {
    this.faUpload = faUpload;
    this.faBan = faBan;
    this.faTimes = faTrash;
  }

  onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
    this.toastr.error(`Upload failed for file: ${item.file.name}`);
  }

  onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
    this.toastr.info('Only jpeg images are allowed.');
  }

  onAfterAddingFile = (fileItem: FileItem) => {
    if (!fileItem.file.name.trim().toLowerCase().endsWith('.jpeg') && !fileItem.file.name.trim().toLowerCase().endsWith('.jpg')) {
      fileItem.remove();
      this.toastr.info('Only jpeg images are allowed.');
    }
  }

  notifyAdmin() {
    this.spinner.show();
    this.imageService.notifyAdminToApproveImages()
      .subscribe(response => {
        this.spinner.hide();
        this.toastr.success('We have notified the admin to review your images.');
      }, error => {
        this.spinner.hide();
        AppUtils.processErrorResponse(this.toastr, error);
      });
  }
}
