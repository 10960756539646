import { Component, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { PageUrls } from 'src/app/helpers/page-urls';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnDestroy {
  routerSubscription: Subscription;
  currentUrl = '/';

  constructor(private router: Router) {

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  get urls() {
    return PageUrls;
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
