import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageUrlsParts, PageUrls } from '../helpers/page-urls';
import { BuyerAuthGuard } from '../guards/buyer-auth.guard';
import { BuyerComponent } from './buyer.component';
import { WishlistComponent } from './components/wishlist/wishlist.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OrderListComponent } from './components/order/list/order.list.component';
import { OrderDetailComponent } from './components/order/detail/order.detail.component';
import { PremiumEventListComponent } from './components/premium-event/list/premium.event.list.component';
import { PremiumEventDetailComponent } from './components/premium-event/detail/premium.event.detail.component';

const routes: Routes = [
  {
    path: PageUrlsParts.Buyer.Self,
    canActivate: [BuyerAuthGuard],
    component: BuyerComponent,
    children: [
      { path: '', redirectTo: PageUrls.Buyer.Dashboard, pathMatch: 'full' },
      { path: PageUrlsParts.Buyer.Wishlist, component: WishlistComponent },
      { path: PageUrlsParts.Buyer.Profile, component: ProfileComponent },
      { path: PageUrlsParts.Buyer.Order, component: OrderListComponent },
      { path: PageUrlsParts.Buyer.OrderDetail, component: OrderDetailComponent },
      { path: PageUrlsParts.Buyer.Premium, component: PremiumEventListComponent },
      { path: PageUrlsParts.Buyer.PremiumEventImages, component: PremiumEventDetailComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class BuyerRoutingModule { }
