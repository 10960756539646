import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { AdminAuthGuard } from '../guards/admin-auth.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PageUrlsParts, PageUrls } from '../helpers/page-urls';
import { CategoriesComponent } from './components/categories/categories.component';
import { ImageManageComponent, ImageUpdateComponent, ImageHistoryComponent, ImagePendingGroupedComponent } from './components/image';
import { EventManageComponent, EventAddComponent, EventEditComponent, EventManageImageComponent } from './components/event';
import { CollectionManageComponent, CollectionAddComponent, CollectionEditComponent } from './components/collection';
import {
  UserEditBuyerComponent, UserEditContributorComponent, UserAddBuyerComponent, UserAddContributorComponent, UserNavigationComponent,
  UserBuyerComponent, UserContributorComponent, UserPendingContributorComponent
} from './components/user';
import {
  SubscriptionPlanManageComponent, SubscriptionPlanAddComponent, SubscriptionPlanEditComponent
} from './components/subscription-plan';
import { OrderListComponent } from '../admin/components/order/list/order.list.component';
import { OrderDetailComponent } from '../admin/components/order/detail/order.detail.component';
import { ContributorEarningHistoryComponent } from './components/contributor';
import { SubscriberListComponent } from './components/subscriber';
import { PaymentHistoryComponent } from './components/payment';
import { FinanceNavigationComponent } from './components/finance/finance-navigation.component';
import { SubcategoryAddComponent, SubcategoryManageComponent } from './components/subcategory';
import { SubcategoryEditComponent } from './components/subcategory/edit/subcateogry.edit.component';
import { SearchedKeywordManageComponent, SearchedKeywordDetailComponent } from './components/searched-keyword';
import { PremiumEventManageComponent,  PremiumEventDetailComponent, PremiumEventHistoryComponent } from './components/premium-event';


const routes: Routes = [
  {
    path: PageUrlsParts.Admin.Self,
    component: AdminComponent,
    canActivate: [AdminAuthGuard],
    children: [
      { path: '', redirectTo: PageUrls.Admin.Dashboard, pathMatch: 'full' },
      { path: PageUrlsParts.Admin.Dashboard, component: DashboardComponent },
      { path: PageUrlsParts.Admin.Categories, component: CategoriesComponent },
      { path: PageUrlsParts.Admin.ImageManage, component: ImageManageComponent },
      { path: PageUrlsParts.Admin.ImageHistory, component: ImageHistoryComponent },
      { path: PageUrlsParts.Admin.UpdateImage, component: ImageUpdateComponent },
      { path: PageUrlsParts.Admin.EventManage, component: EventManageComponent },
      { path: PageUrlsParts.Admin.EventAdd, component: EventAddComponent },
      { path: PageUrlsParts.Admin.EventEdit, component: EventEditComponent },
      { path: PageUrlsParts.Admin.EventManageImage, component: EventManageImageComponent },
      { path: PageUrlsParts.Admin.CollectionManage, component: CollectionManageComponent },
      { path: PageUrlsParts.Admin.CollectionAdd, component: CollectionAddComponent },
      { path: PageUrlsParts.Admin.CollectionEdit, component: CollectionEditComponent },
      { path: PageUrlsParts.Admin.UserNavigation, component: UserNavigationComponent },
      { path: PageUrlsParts.Admin.UserBuyer, component: UserBuyerComponent },
      { path: PageUrlsParts.Admin.UserContributor, component: UserContributorComponent },
      { path: PageUrlsParts.Admin.UserPendingContributor, component: UserPendingContributorComponent },
      { path: PageUrlsParts.Admin.UserBuyerAdd, component: UserAddBuyerComponent },
      { path: PageUrlsParts.Admin.UserContributorAdd, component: UserAddContributorComponent },
      { path: PageUrlsParts.Admin.UserBuyerEdit, component: UserEditBuyerComponent },
      { path: PageUrlsParts.Admin.UserContributorEdit, component: UserEditContributorComponent },
      { path: PageUrlsParts.Admin.UserBuyerEdit, component: UserEditBuyerComponent },
      { path: PageUrlsParts.Admin.UserContributorEdit, component: UserEditContributorComponent },
      { path: PageUrlsParts.Admin.SubscriberList, component: SubscriberListComponent },
      { path: PageUrlsParts.Admin.SubscriptionPlanManage, component: SubscriptionPlanManageComponent },
      { path: PageUrlsParts.Admin.SubscriptionPlanAdd, component: SubscriptionPlanAddComponent },
      { path: PageUrlsParts.Admin.SubscriptionPlanEdit, component: SubscriptionPlanEditComponent },
      { path: PageUrlsParts.Admin.Order, component: OrderListComponent },
      { path: PageUrlsParts.Admin.OrderDetail, component: OrderDetailComponent },
      { path: PageUrlsParts.Admin.ContributorEarning, component: ContributorEarningHistoryComponent },
      { path: PageUrlsParts.Admin.PaymentHistory, component: PaymentHistoryComponent },
      { path: PageUrlsParts.Admin.PendingGrouped, component: ImagePendingGroupedComponent },
      { path: PageUrlsParts.Admin.FinanceNavigation, component: FinanceNavigationComponent },
      { path: PageUrlsParts.Admin.SubcategoryManage, component: SubcategoryManageComponent },
      { path: PageUrlsParts.Admin.SubcategoryAdd, component: SubcategoryAddComponent },
      { path: PageUrlsParts.Admin.SubcategoryEdit, component: SubcategoryEditComponent },
      { path: PageUrlsParts.Admin.SearchedKeywordManage, component: SearchedKeywordManageComponent },
      { path: PageUrlsParts.Admin.SearchedKeywordDetail, component: SearchedKeywordDetailComponent },
      { path: PageUrlsParts.Admin.PremiumEvent, component: PremiumEventManageComponent },
      { path: PageUrlsParts.Admin.PremiumEventDetail, component: PremiumEventDetailComponent },
      { path: PageUrlsParts.Admin.PremiumEventHistory, component: PremiumEventHistoryComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
