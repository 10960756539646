import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { Observable } from 'rxjs';
import { TableModel } from 'src/app/models/table.model';
import { PagedList } from 'src/app/models/paged-list';
import { ImageCategoryUpdateMultipleModel } from 'src/app/admin//models/image.category.update.multiple.model';
import { ImageFilterModel } from 'src/app/admin/models/image.filter.model';
import { ImageApproveMultipleModel } from 'src/app/admin/models/image.approve.multiple.model';
import { ImageRejectMultipleModel } from 'src/app/admin//models/image.reject.multiple.model';
import { ImageDetailModel } from 'src/app/admin/models/image.detail.model';
import { PagedImageDetails } from 'src/app/admin//models/PagedImageDetails';
import { PagedImageHistoryDetails } from 'src/app/admin/models/paged.image.history.detail';
import { ImageDeleteMultipleModel } from 'src/app/admin//models/image.delete.multiple.model';
import { PagedPendingGroupedModel } from 'src/app/admin//models/paged.pending.grouped.model';
import { PagedImageGroupeByContributorDetails } from 'src/app/admin//models/paged.image.groupe.by.contributor';
import { PagedImageGroupeByFolderDetails } from 'src/app/admin//models/paged.image.groupe.by.folder';
import { SelectionFilterModel } from '../models/selection.filter.model';

@Injectable()
export class AdminImageService {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  getImageUrl = (imageId: number) =>
    this.http.get(`${environment.apiUrl}/api/admin/original-image?imageId=${imageId}`, { responseType: 'blob' })
      .pipe(map(blob => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))))

  getImageIconUrl = (imageId: number) =>
    this.http.get(`${environment.apiUrl}/api/admin/original-image/icon?imageId=${imageId}`, { responseType: 'blob' })
      .pipe(map(blob => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))))

  getImageSmallUrl = (imageId: number) =>
    this.http.get(`${environment.apiUrl}/api/admin/original-image/small?imageId=${imageId}`, { responseType: 'blob' })
      .pipe(map(blob => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))))

  getImageMediumUrl = (imageId: number) =>
    this.http.get(`${environment.apiUrl}/api/admin/original-image/medium?imageId=${imageId}`, { responseType: 'blob' })
      .pipe(map(blob => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))))

  getImageLargeUrl = (imageId: number) =>
    this.http.get(`${environment.apiUrl}/api/admin/original-image/large?imageId=${imageId}`, { responseType: 'blob' })
      .pipe(map(blob => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))))

  getImageThumbnailUrl = (imageId: number) =>
    this.http.get(`${environment.apiUrl}/api/admin/original-image/thumbnail?imageId=${imageId}`, { responseType: 'blob' })
      .pipe(map(blob => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))))

  updateImageStatus = (imageDetail: ImageDetailModel, isApproved: boolean) =>
    this.http.post(`${environment.apiUrl}/api/admin/original-image/update-status`, { ...imageDetail, isApproved })

  list(filterModel: ImageFilterModel, tableModel: TableModel): Observable<PagedImageDetails> {
    let url = `${environment.apiUrl}/api/admin/image/list`;
    const tempArr = [];
    if (!isNullOrUndefined(filterModel.imageSize)) {
      tempArr.push(`imageSize=${filterModel.imageSize}`);
    }
    if (!isNullOrUndefined(filterModel.categoryId)) {
      tempArr.push(`categoryId=${filterModel.categoryId}`);
    }
    if (!isNullOrUndefined(filterModel.eventId)) {
      tempArr.push(`eventId=${filterModel.eventId}`);
    }
    if (!isNullOrUndefined(filterModel.status)) {
      tempArr.push(`status=${filterModel.status}`);
    }
    if (!isNullOrUndefined(filterModel.photographer)) {
      tempArr.push(`photographer=${filterModel.photographer}`);
    }
    if (!isNullOrUndefined(filterModel.folderName)) {
      tempArr.push(`folderName=${filterModel.folderName}`);
    }
    if (tableModel) {
      tempArr.push(tableModel.toQueryString());
    }
    if (tempArr.length) {
      url += '?' + tempArr.join('&');
    }
    return this.http.get<PagedImageDetails>(url);
  }

  getImagesByCategory(categoryId: number, tableModel: TableModel) {
    return this.http.get<PagedList<ImageDetailModel>>(
      `${environment.apiUrl}/api/admin/image/list-by-category?categoryId=${categoryId}&${tableModel.toQueryString()}`);
  }

  getImageIdList(categoryId: number, tableModel: TableModel) {
    return this.http.get<Array<number>>(
      `${environment.apiUrl}/api/admin/image/list-id?categoryId=${categoryId}&${tableModel.toQueryString()}`);
  }

  getImagesByEvents(eventIds: string, tableModel: TableModel) {
    return this.http.get<PagedList<ImageDetailModel>>(
      `${environment.apiUrl}/api/admin/image/list-by-events?eventIds=${eventIds}&${tableModel.toQueryString()}`);
  }

  getListGroupByContributor(filterModel: ImageFilterModel, tableModel: TableModel): Observable<PagedImageGroupeByContributorDetails> {
    let url = `${environment.apiUrl}/api/admin/image/list-group-by-contributor`;
    const tempArr = [];
    if (!isNullOrUndefined(filterModel.imageSize)) {
      tempArr.push(`imageSize=${filterModel.imageSize}`);
    }
    if (!isNullOrUndefined(filterModel.categoryId)) {
      tempArr.push(`categoryId=${filterModel.categoryId}`);
    }
    if (!isNullOrUndefined(filterModel.eventId)) {
      tempArr.push(`eventId=${filterModel.eventId}`);
    }
    if (!isNullOrUndefined(filterModel.status)) {
      tempArr.push(`status=${filterModel.status}`);
    }
    if (!isNullOrUndefined(filterModel.photographer)) {
      tempArr.push(`photographer=${filterModel.photographer}`);
    }
    if (tableModel) {
      tempArr.push(tableModel.toQueryString());
    }
    if (tempArr.length) {
      url += '?' + tempArr.join('&');
    }
    return this.http.get<PagedImageGroupeByContributorDetails>(url);
  }

  getListGroupByFolder(filterModel: ImageFilterModel, tableModel: TableModel): Observable<PagedImageGroupeByFolderDetails> {
    let url = `${environment.apiUrl}/api/admin/image/list-group-by-folder`;
    const tempArr = [];
    if (!isNullOrUndefined(filterModel.imageSize)) {
      tempArr.push(`imageSize=${filterModel.imageSize}`);
    }
    if (!isNullOrUndefined(filterModel.categoryId)) {
      tempArr.push(`categoryId=${filterModel.categoryId}`);
    }
    if (!isNullOrUndefined(filterModel.eventId)) {
      tempArr.push(`eventId=${filterModel.eventId}`);
    }
    if (!isNullOrUndefined(filterModel.status)) {
      tempArr.push(`status=${filterModel.status}`);
    }
    if (!isNullOrUndefined(filterModel.photographer)) {
      tempArr.push(`photographer=${filterModel.photographer}`);
    }
    if (tableModel) {
      tempArr.push(tableModel.toQueryString());
    }
    if (tempArr.length) {
      url += '?' + tempArr.join('&');
    }
    return this.http.get<PagedImageGroupeByFolderDetails>(url);
  }

  getImageDetails = (imageId: number) =>
    this.http.get<ImageDetailModel>(`${environment.apiUrl}/api/admin/image?imageId=${imageId}`)

  update = (model: any) =>
    this.http.post(`${environment.apiUrl}/api/admin/image/update`, model)

  approve = (id: number) =>
    this.http.post(`${environment.apiUrl}/api/admin/image/approve/${id}`, null)

  approveMultiple = (model: ImageApproveMultipleModel) =>
    this.http.post(`${environment.apiUrl}/api/admin/image/approve-multiple`, model)

  reject = (id: number) =>
    this.http.post(`${environment.apiUrl}/api/admin/image/reject/${id}`, null)

  rejectMultiple = (model: ImageRejectMultipleModel) =>
    this.http.post(`${environment.apiUrl}/api/admin/image/reject-multiple`, model)

  delete = (id: number) =>
    this.http.post(`${environment.apiUrl}/api/admin/image/delete/${id}`, {})

  deleteMultiple = (model: ImageDeleteMultipleModel) =>
    this.http.post(`${environment.apiUrl}/api/admin/image/delete-multiple`, model)

  hardDeleteMultiple = (model: ImageDeleteMultipleModel) =>
    this.http.post(`${environment.apiUrl}/api/admin/image/hard-delete-multiple`, model)

  updateCategoryMultiple(model: ImageCategoryUpdateMultipleModel) {
    return this.http.post(`${environment.apiUrl}/api/admin/image/update-category-multiple`, model);
  }

  getImageHistoryList(tableModel: TableModel): Observable<PagedImageHistoryDetails> {
    let url = `${environment.apiUrl}/api/admin/image/history`;
    const tempArr = [];
    if (tableModel) {
      tempArr.push(tableModel.toQueryString());
    }
    if (tempArr.length) {
      url += '?' + tempArr.join('&');
    }
    return this.http.get<PagedImageHistoryDetails>(url);
  }

  exportToExcelImageHistory(filterModel: any) {
    return this.http.post(`${environment.apiUrl}/api/admin/image/hitsory-export-as-excel`, filterModel, { responseType: 'blob' });
  }

  getPhotographerNameList = () => this.http.get<Array<string>>(`${environment.apiUrl}/api/admin/image/list-photographer-name`);

  pendingGroupedList(tableModel: TableModel): Observable<PagedPendingGroupedModel> {
    let url = `${environment.apiUrl}/api/admin/image/pending-grouped-list`;
    const tempArr = [];
    if (tableModel) {
      tempArr.push(tableModel.toQueryString());
    }
    if (tempArr.length) {
      url += '?' + tempArr.join('&');
    }
    return this.http.get<PagedPendingGroupedModel>(url);
  }
}
