import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Constants } from '../helpers/constants';
import { AuthService } from '../services/auth.service';
import { PageUrls } from '../helpers/page-urls';

@Injectable()
export class AnonymousOrBuyerGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.authService.isUserLoggedIn()) {
            return true;
        }
        if (this.authService.isLoggedInUserInRole(Constants.Roles.Admin)) {
            this.router.navigateByUrl(PageUrls.Admin.PendingGrouped);
            return false;
        }

        if (this.authService.isLoggedInUserInRole(Constants.Roles.Photographer)) {
            this.router.navigateByUrl(PageUrls.Photographer.UploadImages);
            return false;
        }

        return true;
    }
}
