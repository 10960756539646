import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CountryModel } from 'src/app/models/country.model';
import { SelectListItemModel } from '../models/select.list.item.model';

@Injectable({
    providedIn: 'root'
})

export class CountryService {

    constructor(private http: HttpClient) { }

    getList(): Observable<Array<CountryModel>> {
        return this.http.get<Array<CountryModel>>(`${environment.apiUrl}/api/country`);
    }

    getSelectList(): Observable<Array<SelectListItemModel>> {
        return this.http.get<Array<SelectListItemModel>>(`${environment.apiUrl}/api/country/select-list`);
    }

}

