import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PagedList } from 'src/app/models/paged-list';
import { PhotographerImageDetails } from '../models/photographer-image-detail.model';
import { TableModel } from 'src/app/models/table.model';

@Injectable()
export class PhotographerImageService {

  constructor(private http: HttpClient) { }

  list = (tableModel: TableModel) =>
    this.http.get<PagedList<PhotographerImageDetails>>(`${environment.apiUrl}/api/photographer/image/list?${tableModel.toQueryString()}`)

  notifyAdminToApproveImages = () => this.http.post(`${environment.apiUrl}/api/photographer/image/notify-admin-to-approve-images`, {});

}
