import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageUrlsParts } from './helpers/page-urls';
import { AnonymousOrBuyerGuard } from './guards/anonymous.or.buyer.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ImageFilterComponent } from './components/image';
import { ImageDetailComponent } from './components/image-detail/image-detail.component';
import { CollectionListComponent } from './components/collection';
import { CartComponent } from './components/cart/cart.component';
import { SubscriptionPlanListComponent } from './components/subscription';
import { AboutUsComponent } from './components/shared/about-us/about-us.component';
import { PrivacyPolicyComponent } from './components/shared/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './components/shared/contact-us/contact-us.component';
import { TermsAndConditionComponent } from './components/shared/terms-and-condition/terms-and-condition.component';
import { WorkWithUsComponent } from './components/shared/work-with-us/work-with-us.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: DashboardComponent, canActivate: [AnonymousOrBuyerGuard] },
  { path: PageUrlsParts.Dashboard, redirectTo: '/' },
  { path: 'image-detail/:imageId', component: ImageDetailComponent, canActivate: [AnonymousOrBuyerGuard] },
  { path: 'image/filter', component: ImageFilterComponent, canActivate: [AnonymousOrBuyerGuard] },
  { path: PageUrlsParts.Collections, component: CollectionListComponent, canActivate: [AnonymousOrBuyerGuard] },
  { path: PageUrlsParts.Cart, component: CartComponent, canActivate: [AnonymousOrBuyerGuard] },
  { path: PageUrlsParts.SubscriptionPlans, component: SubscriptionPlanListComponent, canActivate: [AnonymousOrBuyerGuard] },
  { path: PageUrlsParts.TermsAndConditions, component: TermsAndConditionComponent },
  { path: PageUrlsParts.AboutUs, component: AboutUsComponent },
  { path: PageUrlsParts.PrivacyPolicy, component: PrivacyPolicyComponent },
  { path: PageUrlsParts.ContactUs, component: ContactUsComponent },
  { path: PageUrlsParts.WorkWithUs, component: WorkWithUsComponent },

  // { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
