import { Component, OnInit } from '@angular/core';
import { AppUtils } from 'src/app/helpers/app.utils';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { PageUrls } from 'src/app/helpers/page-urls';
import { TableModel } from 'src/app/models/table.model';
import { Subscription } from 'rxjs';
import { PagedOrderDetails } from 'src/app/admin/models/paged.order.detail';
import { OrderService } from 'src/app/admin/services/order.service';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { OrderFilterModel } from 'src/app/admin/models/order.filter.model';
import { isNullOrUndefined } from 'util';
import { OrderListItemModel } from 'src/app/admin/models/order.list.item.model';
import { SelectionFilterModel } from 'src/app/admin/models/selection.filter.model';

@Component({
    selector: 'app-order-list',
    templateUrl: './order.list.component.html',
    styleUrls: ['./order.list.component.scss']
})
export class OrderListComponent implements OnInit {

    totalRecords = 0;
    pagedOrderDetails = new PagedOrderDetails();
    subscriptions = new Array<Subscription>();
    orderFilterModel = new OrderFilterModel();
    tableModel = new TableModel();
    isModelLoaded = false;
    customers: Array<SelectListItemModel>;
    selectedCustomer: SelectListItemModel;
    orderSelectionFilter = new SelectionFilterModel<OrderFilterModel>();

    get urls() {
        return PageUrls;
    }

    constructor(private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private orderService: OrderService) {
        this.tableModel.orderBy = 'CreatedOn';
        this.tableModel.orderByDirection = 'desc';
        this.selectedCustomer = this.getDefaultCustomer();
    }

    ngOnInit() {
        this.initSubscribers();
        this.getCustomerSelectItems();
        this.getOrders();
    }

    getDefaultCustomer() {
        const defaultCustomer = new SelectListItemModel();
        defaultCustomer.key = null;
        defaultCustomer.value = 'All';
        return defaultCustomer;
    }

    getCustomerSelectItems = () => {
        this.orderService.getCustomerSelectListItems()
            .subscribe(response => {
                this.customers = response;
            }, error => {
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }


    setCustomer = (Customer: SelectListItemModel) => {
        this.selectedCustomer = Customer || this.getDefaultCustomer();
        this.getOrders();
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getOrders());
        this.subscriptions.push(subscription);
    }

    getOrders() {
        this.spinner.show();
        if (isNullOrUndefined(this.selectedCustomer.key)) {
            this.orderFilterModel.customerId = '';
        } else {
            this.orderFilterModel.customerId = this.selectedCustomer.key;
        }
        this.orderService.list(this.orderFilterModel, this.tableModel).subscribe(
            data => {
                Object.assign(this.pagedOrderDetails, data);
                this.totalRecords = data.totalCount;
                this.pagedOrderDetails.items.forEach(order => {
                    order.createdOn = AppUtils.getFormattedDate(order.createdOn);
                });

                if (this.orderSelectionFilter.selectedAll) {
                    this.pagedOrderDetails.items.forEach(order => {
                        order.isSelected = this.orderSelectionFilter.selectedAll;
                        const check = this.orderSelectionFilter.selectedItems.includes(order.id);
                        if (!check) {
                            this.orderSelectionFilter.selectedItems.push(order.id);
                        }
                    });
                } else {
                    if (this.orderSelectionFilter.deselectedItems.length > 0) {
                        this.pagedOrderDetails.items.forEach(order => {
                            order.isSelected = !this.orderSelectionFilter.deselectedItems.includes(order.id);
                        });
                    } else {
                        if (this.orderSelectionFilter.selectedItems.length > 0) {
                            this.pagedOrderDetails.items.forEach(order => {
                                order.isSelected = this.orderSelectionFilter.selectedItems.includes(order.id);
                            });
                        }
                    }
                }
                this.spinner.hide();
                this.isModelLoaded = true;
            },
            error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    selectAll() {
        this.pagedOrderDetails.items.forEach(order => {
            order.isSelected = this.orderSelectionFilter.selectedAll;
        });
        if (this.orderSelectionFilter.selectedAll) {
            this.orderSelectionFilter.deselectedItems = new Array<string>();
        } else {
            this.orderSelectionFilter.selectedItems = new Array<string>();
        }
    }

    updateSelectAll(order: OrderListItemModel) {
        if (order.isSelected) {
            const check = this.orderSelectionFilter.selectedItems.includes(order.id);
            if (!check) {
                this.orderSelectionFilter.selectedItems.push(order.id);
            }
            const index = this.orderSelectionFilter.deselectedItems.indexOf(order.id);
            this.orderSelectionFilter.deselectedItems.splice(index, 1);
        } else {
            this.orderSelectionFilter.selectedAll = false;
            const index = this.orderSelectionFilter.selectedItems.indexOf(order.id);
            this.orderSelectionFilter.selectedItems.splice(index, 1);
            const check = this.orderSelectionFilter.deselectedItems.includes(order.id);
            if (!check) {
                this.orderSelectionFilter.deselectedItems.push(order.id);
            }
        }
    }

    exportToExcel() {
        if (!this.orderSelectionFilter.selectedAll) {
            if (this.orderSelectionFilter.deselectedItems.length === 0 && this.orderSelectionFilter.selectedItems.length === 0) {
                this.toastr.error('Please select an order to continue');
                return;
            }
            if (this.orderSelectionFilter.deselectedItems.length === this.pagedOrderDetails.totalCount) {
                this.toastr.error('Please select an order to continue');
                return;
            }
        }
        this.spinner.show();
        this.orderSelectionFilter.gridFilter = this.orderFilterModel;
        this.orderService.exportToExcel(this.orderSelectionFilter).subscribe(
            fileBytes => {
                const blob: any = new Blob([fileBytes], { type: 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const element = document.createElement('a');
                element.href = url;
                element.download = `${AppUtils.getFormattedDate(new Date())}.xlsx`;
                document.body.appendChild(element);
                this.spinner.hide();
                element.click();
            },
            error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}
