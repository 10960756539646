import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TableModel } from 'src/app/models/table.model';
import { PagedSearchedKeywordManageModel } from '../models/paged.searched.keyword.manage.model';
import { PagedSearchedKeywordDetailModel } from '../models/paged.searched.keyword.detail.model';

@Injectable()
export class SearchedKeywordService {

    constructor(private http: HttpClient) { }

    list(tableModel: TableModel): Observable<PagedSearchedKeywordManageModel> {
        let url = `${environment.apiUrl}/api/admin/searchedkeyword/list`;
        const queryParams = [];
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedSearchedKeywordManageModel>(url);
    }

    listForKeyword(keyword: string, tableModel: TableModel): Observable<PagedSearchedKeywordDetailModel> {
        let url = `${environment.apiUrl}/api/admin/searchedkeyword/list/${keyword}`;
        const queryParams = [];
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedSearchedKeywordDetailModel>(url);
    }

}
