export class ImageFilterModel {
    public categoryId: number;
    public eventId: string;
    public status: number;
    public photographer: string;
    public folderName: string;
    public imageSize: number;
    public resetPhotographer: boolean;

    constructor() {
        this.status = 1;
        this.eventId = '';
        this.photographer = '';
        this.folderName = '';
        this.resetPhotographer = true;
    }
}
