import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TreeviewModule } from 'ngx-treeview';
import { FileUploadModule } from 'ng2-file-upload';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';

import { AdminImageService } from './services/admin-image.service';
import { EventService } from './services/event.service';
import { CollectionService } from './services/collection.service';
import { UserService } from './services/user.service';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CategoriesComponent } from './components/categories/categories.component';
import {
  ImageFilterDrawerComponent, ImagePreviewComponent, ImageApproveMultipleComponent, ImageManageComponent,
  ImageUpdateComponent, ImageHistoryComponent, ImagePhotographerViewComponent, ImageViewComponent, ImageFolderViewComponent,
  ImagePendingGroupedComponent
} from './components/image';
import {
  EventManageComponent, EventAddComponent, EventEditComponent, EventManageImageComponent, EventCalenderViewComponent,
  EventTableViewComponent
} from './components/event';
import {
  CollectionManageComponent, CollectionAddComponent, CollectionEditComponent, CollectionSelectImageComponent
} from './components/collection';
import {
  UserBuyerComponent, UserContributorComponent, UserEditBuyerComponent, UserEditContributorComponent,
  UserPendingContributorComponent, UserAddBuyerComponent, UserAddContributorComponent,
  UserNavigationComponent
} from './components/user';
import {
  SubscriptionPlanAddComponent, SubscriptionPlanEditComponent, SubscriptionPlanManageComponent
} from './components/subscription-plan';
import { SubscriptionPlanService } from './services/subscription.plan.service';
import { OrderListComponent } from '../admin/components/order/list/order.list.component';
import { OrderDetailComponent } from '../admin/components/order/detail/order.detail.component';
import { OrderService } from './services/order.service';
import { SearchedKeywordService } from './services/searched.keyword.service';
import { ContributorEarningHistoryComponent } from './components/contributor';
import { SubscriberListComponent } from './components/subscriber';
import { PaymentHistoryComponent } from './components/payment';
import { PaymentLogService } from './services/payment.log.service';
import { FilterComponent, TreevewModalComponent } from './components/partial';
import { FinanceNavigationComponent } from './components/finance/finance-navigation.component';
import { SubcategoryAddComponent, SubcategoryEditComponent, SubcategoryManageComponent } from './components/subcategory';
import { SearchedKeywordManageComponent, SearchedKeywordDetailComponent } from './components/searched-keyword';
import { NgbdSortableHeader } from '../shared/directives/app.directives';
import {
  PremiumEventDetailComponent, PremiumEventManageComponent, PremiumEventTableComponent, PremiumEventSelectedWithComponent, PremiumEventHistoryComponent
} from './components/premium-event';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    AdminComponent,
    DashboardComponent,
    FilterComponent,
    TreevewModalComponent,
    CategoriesComponent,
    ImageFilterDrawerComponent,
    ImagePreviewComponent,
    ImageApproveMultipleComponent,
    ImageManageComponent,
    ImagePhotographerViewComponent,
    ImageFolderViewComponent,
    ImageViewComponent,
    ImageUpdateComponent,
    EventManageComponent,
    EventAddComponent,
    EventEditComponent,
    EventManageImageComponent,
    EventCalenderViewComponent,
    EventTableViewComponent,
    CollectionManageComponent,
    CollectionAddComponent,
    CollectionEditComponent,
    CollectionSelectImageComponent,
    UserNavigationComponent,
    UserBuyerComponent,
    UserPendingContributorComponent,
    UserAddBuyerComponent,
    UserAddContributorComponent,
    UserEditBuyerComponent,
    UserEditContributorComponent,
    UserContributorComponent,
    SubscriberListComponent,
    SubscriptionPlanAddComponent,
    SubscriptionPlanEditComponent,
    SubscriptionPlanManageComponent,
    OrderListComponent,
    OrderDetailComponent,
    ImageHistoryComponent,
    ContributorEarningHistoryComponent,
    PaymentHistoryComponent,
    ImagePendingGroupedComponent,
    FinanceNavigationComponent,
    SubcategoryManageComponent,
    SubcategoryAddComponent,
    SubcategoryEditComponent,
    SearchedKeywordManageComponent,
    SearchedKeywordDetailComponent,
    NgbdSortableHeader,
    PremiumEventManageComponent,
    PremiumEventTableComponent,
    PremiumEventDetailComponent,
    PremiumEventSelectedWithComponent,
    PremiumEventHistoryComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AdminRoutingModule,
    NgbModule,
    FileUploadModule,
    TreeviewModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  entryComponents: [
    ImagePreviewComponent
  ],
  providers: [
    AdminImageService,
    EventService,
    CollectionService,
    UserService,
    SubscriptionPlanService,
    OrderService,
    PaymentLogService,
    SearchedKeywordService
  ]
})

export class AdminModule { }
