import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AddCartItemModel } from 'src/app/models/add.cart.item.model';
import { CartPurchaseValidationModel } from 'src/app/models/cart.purchase.validation.model';
import { AppUtils } from 'src/app/helpers/app.utils';


@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private httpClient: HttpClient) { }

  addToCart(model: AddCartItemModel) {
    model.cartId = AppUtils.getCartId();
    return this.httpClient.post(`${environment.apiUrl}/api/user/cart/add-cart-item`, model);
  }

  addToCartMultiple(model: AddCartItemModel) {
    model.cartId = AppUtils.getCartId();
    return this.httpClient.post(`${environment.apiUrl}/api/user/cart/add-cart-item-multiple`, model);
  }

  addToCartByEvent(model: AddCartItemModel) {
    model.cartId = AppUtils.getCartId();
    return this.httpClient.post(`${environment.apiUrl}/api/user/cart/add-cart-item-by-event`, model);
  }

  getCartItems() {
    const cartId = AppUtils.getCartId();
    return this.httpClient.get(`${environment.apiUrl}/api/user/cart/${cartId}`);
  }

  cartItemCount() {
    const cartId = AppUtils.getCartId();
    return this.httpClient.get(`${environment.apiUrl}/api/user/cart/item-count/${cartId}`);
  }

  deleteCartItem(id: string) {
    return this.httpClient.post(`${environment.apiUrl}/api/user/cart/delete-cart-item/${id}`, null);
  }

  validateCart(cartId: string) {
    return this.httpClient.post<CartPurchaseValidationModel>(
      `${environment.apiUrl}/api/user/cart/validate-purchase/${cartId}`, null);
  }
}
