import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AdminImageService } from 'src/app/admin/services/admin-image.service';
import { PagedImageHistoryDetails } from 'src/app/admin/models/paged.image.history.detail';
import { TableModel } from 'src/app/models/table.model';
import { UserService } from 'src/app/admin/services/user.service';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { SelectionFilterModel } from 'src/app/admin/models/selection.filter.model';
import { ImageHistoryDetailModel } from 'src/app/admin/models/image.history.detail.model';

@Component({
    selector: 'app-image-history',
    templateUrl: './image.history.component.html',
    styleUrls: ['./image.history.component.scss']
})
export class ImageHistoryComponent implements OnInit {
    subscriptions = new Array<Subscription>();
    totalRecords = 1;
    tableModel = new TableModel();
    pagedImageHistoryDetails = new PagedImageHistoryDetails();
    photographers = new Array<SelectListItemModel>();
    isModelLoaded = false;
    imageHistorySelectionFilter = new SelectionFilterModel<string>();

    get urls() {
        return PageUrls;
    }

    constructor(private ngxSpinnerService: NgxSpinnerService,
        private toastr: ToastrService,
        private adminImageService: AdminImageService,
        private userService: UserService) {
        this.tableModel.orderBy = 'ApprovedRejectedOn';
        this.tableModel.orderByDirection = 'desc';
    }

    ngOnInit() {
        this.loadPhotographers();
        this.initSubscribers();
        this.getImages();
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getImages());
        this.subscriptions.push(subscription);
    }

    loadPhotographers() {
        this.adminImageService.getPhotographerNameList().subscribe(
            (response: Array<string>) => {
                const tempArray = new Array<SelectListItemModel>();
                response.forEach(name => {
                    const item = new SelectListItemModel();
                    item.key = name;
                    item.value = name;

                    tempArray.push(item);
                });
                this.photographers = tempArray;
            },
            error => {
                console.log(error);
            });
    }

    getImages = () => {
        this.isModelLoaded = false;
        this.ngxSpinnerService.show();
        this.adminImageService.getImageHistoryList(this.tableModel)
            .subscribe(data => {
                this.pagedImageHistoryDetails = data;
                this.pagedImageHistoryDetails.items.forEach((item) => {
                    item.approvedRejectedOn = AppUtils.getFormattedDate(item.approvedRejectedOn, null);
                });
                this.totalRecords = data.totalCount;
                this.ngxSpinnerService.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinnerService.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    selectAll() {
        this.pagedImageHistoryDetails.items.forEach(order => {
            order.isSelected = this.imageHistorySelectionFilter.selectedAll;
        });
        if (this.imageHistorySelectionFilter.selectedAll) {
            this.imageHistorySelectionFilter.deselectedItems = new Array<string>();
        } else {
            this.imageHistorySelectionFilter.selectedItems = new Array<string>();
        }
    }

    updateSelectAll(image: ImageHistoryDetailModel) {
        if (image.isSelected) {
            const check = this.imageHistorySelectionFilter.selectedItems.includes(image.imageId.toString());
            if (!check) {
                this.imageHistorySelectionFilter.selectedItems.push(image.imageId.toString());
            }
            const index = this.imageHistorySelectionFilter.deselectedItems.indexOf(image.imageId.toString());
            this.imageHistorySelectionFilter.deselectedItems.splice(index, 1);
        } else {
            this.imageHistorySelectionFilter.selectedAll = false;
            const index = this.imageHistorySelectionFilter.selectedItems.indexOf(image.imageId.toString());
            this.imageHistorySelectionFilter.selectedItems.splice(index, 1);
            const check = this.imageHistorySelectionFilter.deselectedItems.includes(image.imageId.toString());
            if (!check) {
                this.imageHistorySelectionFilter.deselectedItems.push(image.imageId.toString());
            }
        }
    }

    exportToExcel() {
        if (!this.imageHistorySelectionFilter.selectedAll) {
            if (this.imageHistorySelectionFilter.deselectedItems.length === 0
                && this.imageHistorySelectionFilter.selectedItems.length === 0) {
                this.toastr.error('Please select an image history to continue');
                return;
            }
            if (this.imageHistorySelectionFilter.deselectedItems.length === this.pagedImageHistoryDetails.totalCount) {
                this.toastr.error('Please select an image history to continue');
                return;
            }
        }
        this.ngxSpinnerService.show();
        this.imageHistorySelectionFilter.gridFilter = this.tableModel.filterKey;
        this.adminImageService.exportToExcelImageHistory(this.imageHistorySelectionFilter).subscribe(
            fileBytes => {
                const blob: any = new Blob([fileBytes], { type: 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const element = document.createElement('a');
                element.href = url;
                element.download = `${AppUtils.getFormattedDate(new Date())}.xlsx`;
                document.body.appendChild(element);
                this.ngxSpinnerService.hide();
                element.click();
            },
            error => {
                this.ngxSpinnerService.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}
