import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ContactUsModel } from 'src/app/models/contact.us.model';

@Injectable({
    providedIn: 'root'
})

export class ContactUsService {

    constructor(private httpClient: HttpClient) { }

    post(model: ContactUsModel) {
        return this.httpClient.post(`${environment.apiUrl}/api/contactUs`, model);
    }
}
