export class ContributorEarningFilterModel {
    public contributorId: string;
    public year: string;
    public month: string;

    constructor() {
        this.contributorId = '';
        this.year = '';
        this.month = '';
    }
}
