import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ContributorProfileEditModel } from 'src/app/photographer/models/contributor.profile.edit.model';

@Injectable()
export class PhotographerProfileService {

  constructor(private http: HttpClient) { }

  getProfile = (): Observable<ContributorProfileEditModel> =>
    this.http.get<ContributorProfileEditModel>(`${environment.apiUrl}/api/photographer/profile`)


  updateProfile = (model: ContributorProfileEditModel) =>
    this.http.post(`${environment.apiUrl}/api/photographer/profile`, model)
}
