import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SafeUrl } from '@angular/platform-browser';
import { IconDefinition, faUser, faPencilAlt, faInfo, faTags } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FileUtility } from 'src/app/utilities/file.utility';
import { Constants } from 'src/app/helpers/constants';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AppUtils } from 'src/app/helpers/app.utils';
import { ImagePreviewComponent } from 'src/app/admin/components/image/preview/image.preview.component';
import { ImageDetailModel } from 'src/app/admin/models/image.detail.model';
import { AdminImageService } from 'src/app/admin/services/admin-image.service';
import { isNullOrUndefined } from 'util';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { EventService } from 'src/app/admin/services/event.service';

@Component({
    selector: 'app-image-update',
    templateUrl: './image.update.component.html',
    styleUrls: ['./image.update.component.scss']
})

export class ImageUpdateComponent implements OnInit, OnDestroy {

    imageId: number;
    prevImageId: number;
    nextImageId: number;
    callBackUrl: string;
    callBackParams: string;

    faUser: IconDefinition;
    faPencil: IconDefinition;
    faInfo: IconDefinition;
    faTags: IconDefinition;

    imageUrl: SafeUrl;
    model: ImageDetailModel;

    events = new Array<SelectListItemModel>();
    headline: string;

    iconImage: any;
    smallImage: any;
    mediumImage: any;
    bigImage: any;
    thumbnailImage: any;
    selectedImageSize: number;
    actionType: number;

    constructor(private adminImageService: AdminImageService,
        private eventService: EventService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: NgbModal) { }

    ngOnInit() {
        this.faUser = faUser;
        this.faPencil = faPencilAlt;
        this.faInfo = faInfo;
        this.faTags = faTags;

        this.headline = '';
        this.selectedImageSize = 1;

        this.callBackUrl = `${this.route.snapshot.queryParams.url}`;
        this.callBackParams = `status=${this.route.snapshot.queryParams.status ? this.route.snapshot.queryParams.status : ''}` +
            `&folderName=${this.route.snapshot.queryParams.folderName ? this.route.snapshot.queryParams.folderName : ''}` +
            `&photographer=${this.route.snapshot.queryParams.photographer ? this.route.snapshot.queryParams.photographer : ''}`;

        this.imageId = this.route.snapshot.queryParams.imageId;
        this.model = new ImageDetailModel();

        this.loadFilteredImageIdList();
        this.loadEvents();
        this.loadImageDetail();
    }

    get urls() {
        return PageUrls;
    }

    loadFilteredImageIdList() {
        if (!localStorage.getItem(Constants.adminImageFilterIdList)
            || (this.callBackUrl.indexOf('/image/manage') === -1
                && this.callBackUrl.indexOf('/event/manage') === -1)) {
            return;
        }
        let filteredImageIdList: Array<number>;
        filteredImageIdList = JSON.parse(localStorage.getItem(Constants.adminImageFilterIdList));
        const filteredImageIdIndex = filteredImageIdList.indexOf(Number(this.imageId));
        this.prevImageId = filteredImageIdIndex > 0 ? filteredImageIdList[filteredImageIdIndex - 1] : null;
        this.nextImageId = filteredImageIdIndex < filteredImageIdList.length ? filteredImageIdList[filteredImageIdIndex + 1] : null;
    }

    loadEvents() {
        this.eventService.getSelectItems().subscribe(
            (events: Array<SelectListItemModel>) => {
                this.events = events;
            },
            error => {
                console.log(error);
            });
    }

    eventChanged() {
        const event = this.events.find(x => x.key === this.model.eventId);
        if (isNullOrUndefined(event)) {
            this.model.headline = this.headline;
            return;
        }
        this.model.headline = event.value;
    }

    loadImageDetail() {

        this.spinner.show();
        this.adminImageService.getImageDetails(this.imageId).subscribe(imageDetail => {
            this.model = imageDetail;
            if (!isNullOrUndefined(this.model.headline)) {
                this.headline = this.model.headline;
            }
            if (this.model.eventId === '0') {
                this.model.eventId = '';
            }
            this.spinner.hide();
        }, error => {
            this.spinner.hide();
            AppUtils.processErrorResponse(this.toastr, error);
        });

        this.adminImageService.getImageSmallUrl(this.imageId).subscribe(url => {
            this.imageUrl = url;
        });
    }

    openImage() {
        this.spinner.show();
        this.adminImageService.getImageLargeUrl(this.imageId).subscribe(
            (url) => {
                const modal = this.modalService.open(ImagePreviewComponent, { size: 'full-screen', centered: true });
                modal.componentInstance.setImage(url);
                this.spinner.hide();
            },
            error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    setImage(imageSize: number) {
        this.spinner.show();
        this.selectedImageSize = imageSize;
        switch (imageSize) {
            case 1:
                this.adminImageService.getImageSmallUrl(this.model.imageId).subscribe(url => {
                    this.imageUrl = url;
                    this.spinner.hide();
                }, error => {
                    this.spinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
                break;
            case 2:
                this.adminImageService.getImageMediumUrl(this.model.imageId).subscribe(url => {
                    this.imageUrl = url;
                    this.spinner.hide();
                }, error => {
                    this.spinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
                break;
            case 3:
                this.adminImageService.getImageLargeUrl(this.model.imageId).subscribe(url => {
                    this.imageUrl = url;
                    this.spinner.hide();
                }, error => {
                    this.spinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
                break;
        }
    }

    setActionType(actionType: number) {
        this.actionType = actionType;
    }

    approve() {
        if (this.model.priceForSmall === 0 || this.model.priceForMedium === 0 || this.model.priceForLarge === 0) {
            this.toastr.error('All prices must be greater that zero.');
            return;
        }
        this.adminImageService.approve(this.model.imageId).subscribe(
            () => {
                this.spinner.hide();
                this.processPostFormRedirection();
                setTimeout(() => {
                    this.toastr.success(`Image has been approved successfully.`);
                });
            }, error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    reject() {
        if (!confirm('Are you sure you want to reject this image?')) {
            return;
        }
        this.spinner.show();
        this.adminImageService.reject(this.model.imageId).subscribe(
            () => {
                this.spinner.hide();
                this.processPostFormRedirection();
                setTimeout(() => {
                    this.toastr.success(`Image has been rejected successfully.`);
                });
            }, error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    nextOrPrev(isNext: boolean) {
        if (this.model.status === 2) {
            const imageId = isNext ? this.nextImageId : this.prevImageId;
            this.router.navigateByUrl(`${PageUrls.Admin.UpdateImage}?imageId=${imageId}&url=${this.callBackUrl}&${this.callBackParams}`);
            return;
        }

        this.spinner.show();
        this.adminImageService.update(this.model).subscribe(
            () => {
                this.spinner.hide();
                const imageId = isNext ? this.nextImageId : this.prevImageId;
                this.router
                    .navigateByUrl(`${PageUrls.Admin.UpdateImage}?imageId=${imageId}&url=${this.callBackUrl}&${this.callBackParams}`);
            }, error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    update(updateAndApprove: boolean) {
        if (this.model.priceForSmall === 0 || this.model.priceForMedium === 0 || this.model.priceForLarge === 0) {
            this.toastr.error('All prices must be greater that zero.');
            return;
        }

        this.spinner.show();
        this.adminImageService.update(this.model).subscribe(
            () => {
                if (updateAndApprove) {
                    this.approve();
                    return;
                }
                this.spinner.hide();
                this.toastr.success(`Image has been updated successfully.`);
            }, error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    delete() {
        if (!confirm('Are you sure you want to delete this image?')) {
            return;
        }
        this.spinner.show();
        this.adminImageService.delete(this.model.imageId).subscribe(
            () => {
                this.spinner.hide();
                this.processPostFormRedirection();
                setTimeout(() => {
                    this.toastr.success(`Image has been deleted successfully.`);
                });
            }, error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    processPostFormRedirection() {
        if (isNullOrUndefined(this.callBackUrl)) {
            this.router.navigateByUrl(PageUrls.Admin.ImageManage);
        } else {
            this.router.navigateByUrl(`${this.callBackUrl}?${this.callBackParams}`);
        }
    }

    submit() {
        if (this.actionType === 1) {
            this.update(true);
        } else if (this.actionType === 2) {
            this.update(false);
        } else {
            this.toastr.error('Something went wrong. Please try again');
        }
    }

    ngOnDestroy() {
        if (this.imageUrl) {
            FileUtility.unlinkFileUrl(this.imageUrl);
        }
    }

}
