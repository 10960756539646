import { Component, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TreeviewItem } from 'ngx-treeview';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AlbumModel } from 'src/app/models/album.model';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { CollectionEditModel } from 'src/app/admin/models/collection.edit.model';
import { AlbumService } from 'src/app/services/album.service';
import { EventService } from 'src/app/admin/services/event.service';
import { CollectionService } from 'src/app/admin/services/collection.service';

@Component({
    selector: 'app-collection-edit',
    templateUrl: './collection.edit.component.html'
})

export class CollectionEditComponent implements OnInit {
    @ViewChild('eventTreeviewModal', null) eventTreeviewModal: any;
    categoryId = '';
    subCategoryId = '';
    showImages = false;
    categories = new Array<AlbumModel>();
    subCategories = new Array<AlbumModel>();
    eventTreeviewItems = new Array<TreeviewItem>();
    selectedEventList = new Array<SelectListItemModel>();
    model = new CollectionEditModel();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private albumService: AlbumService,
        private eventService: EventService,
        private collectionService: CollectionService) {
        this.model.id = this.route.snapshot.queryParams.id;
    }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
        this.loadCategories();
        this.loadCollection();
    }

    loadCategories() {
        this.albumService.getAlbumList().subscribe(
            (categories) => {
                this.categories = categories;
                this.categoryChanged(false);
            },
            error => {
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    loadCollection() {
        this.ngxSpinner.show();
        this.collectionService.get(this.model.id)
            .subscribe(
                (data: CollectionEditModel) => {
                    this.ngxSpinner.hide();
                    Object.assign(this.model, data);

                    if (this.model.eventSelectListItems
                        && this.model.eventSelectListItems.length > 0) {
                        this.selectedEventList = this.model.eventSelectListItems;
                        this.model.createWith = 'events';
                    } else {
                        this.model.createWith = 'images';
                    }
                    this.categoryId = this.model.categoryId.toString();
                    this.categoryChanged(false);
                },
                error => {
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    categoryChanged(resetCurrentSelection: boolean) {
        this.subCategoryId = '';

        if (resetCurrentSelection) {
            this.resetCurrentSelection();
        }

        if (!this.categories || this.categories.length === 0) {
            return;
        }

        const imageCategory = this.categories.find(x => x.id.toString() === this.categoryId);
        if (imageCategory) {
            this.subCategories = imageCategory.subCategories;
            return;
        }

        this.categories.forEach((category) => {
            if (!category.subCategories
                || category.subCategories.length === 0) {
                return;
            }
            const imageSubCategory = category.subCategories.find(x => x.id === Number(this.categoryId));
            if (imageSubCategory) {
                this.subCategories = category.subCategories;
                this.categoryId = category.id.toString();
                this.subCategoryId = imageSubCategory.id.toString();
            }
        });
    }

    subCategoryChanged() {
        this.resetCurrentSelection();
    }

    proceedWith() {
        this.resetCurrentSelection();
    }

    loadEventTreeviewItems() {
        if (this.categoryId === '' && this.subCategoryId === '') {
            this.toastr.error('Please select a category to continue');
            return;
        }

        this.model.categoryId = Number(this.subCategoryId) > 0
            ? Number(this.subCategoryId)
            : Number(this.categoryId);

        this.ngxSpinner.show();

        this.eventService.getTreeViewItems(this.model.categoryId)
            .subscribe(
                (data: Array<any>) => {
                    if (!data || data.length === 0) {
                        this.ngxSpinner.hide();
                        this.toastr.error('No event found for the selected category.');
                        return;
                    }

                    const treeviewItems = new Array<TreeviewItem>();

                    data.forEach(item => {
                        const treeviewItem = new TreeviewItem(item);

                        if (this.selectedEventList.length > 0) {
                            if (this.selectedEventList.findIndex(x => x.key === treeviewItem.value) !== -1) {
                                treeviewItem.checked = true;
                            }

                            if (treeviewItem.children && treeviewItem.children.length > 0) {
                                treeviewItem.children.forEach(childItem => {
                                    if (this.selectedEventList.findIndex(x => x.key === childItem.value) !== -1) {
                                        childItem.checked = true;
                                    }
                                });
                            }
                        }

                        treeviewItems.push(treeviewItem);
                    });

                    this.eventTreeviewItems = treeviewItems;

                    setTimeout(() => {
                        this.ngxSpinner.hide();
                        this.eventTreeviewModal.openModal();
                    });
                },
                error => {
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    openEventTreeviewModal() {
        this.loadEventTreeviewItems();
    }

    onTreeviewModalClosed() {
        this.resetCurrentSelection();
        this.eventTreeviewItems.forEach(eventTreeviewItem => {
            if (eventTreeviewItem.checked) {
                const selectedListItem = new SelectListItemModel();
                selectedListItem.key = eventTreeviewItem.value;
                selectedListItem.value = eventTreeviewItem.text;
                this.selectedEventList.push(selectedListItem);
            }
            if (eventTreeviewItem.children && eventTreeviewItem.children.length > 0 && !eventTreeviewItem.checked) {

                eventTreeviewItem.children.forEach(eventChildTreeviewItem => {
                    if (eventChildTreeviewItem.checked) {
                        const selectedListItem = new SelectListItemModel();
                        selectedListItem.key = eventChildTreeviewItem.value;
                        selectedListItem.value = eventChildTreeviewItem.text;
                        this.selectedEventList.push(selectedListItem);
                    }
                });
            }
        });

        // proceed to cover image selection if events selected
        if (this.selectedEventList.length > 0) {
            this.selectedEventList.forEach(item => {
                this.model.eventIdList.push(item.key);
            });
            this.showImages = true;
        } else {
            this.toastr.error('Please select event(s) to continue');
        }
    }

    unSelectEvent(item: SelectListItemModel) {
        const itemIndex = this.selectedEventList.indexOf(item);
        this.selectedEventList.splice(itemIndex, 1);
    }

    onImageSelectionCancelled() {
        this.showImages = false;
    }

    onImageSelectionCompleted() {
        this.save();
    }

    resetCurrentSelection() {
        this.selectedEventList = new Array<SelectListItemModel>();
        this.model.imageIdList = new Array<number>();
    }

    continue() {

        this.ngxSpinner.show();
        this.collectionService.isExists(this.model.id, this.model.title).subscribe(
            (data: boolean) => {
                this.ngxSpinner.hide();
                if (data) {
                    this.toastr.error('Another collection with same title already exists');
                    return;
                }


                this.model.categoryId = Number(this.subCategoryId) > 0
                    ? Number(this.subCategoryId)
                    : Number(this.categoryId);

                if (this.model.createWith === 'events') {
                    this.openEventTreeviewModal();
                } else if (this.model.createWith === 'images') {
                    this.showImages = true;
                } else {
                    this.toastr.error('Something went wrong. Please refresh the page and try again');
                }

            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    save() {
        if (this.model.createWith === 'events'
            && this.selectedEventList.length === 0) {
            this.toastr.error('Something went wrong. Please refresh the page and try again');
            return;
        }

        this.ngxSpinner.show();

        this.collectionService.edit(this.model).subscribe(
            () => {
                this.ngxSpinner.hide();
                this.router.navigate([PageUrls.Admin.CollectionManage]);
                setTimeout(() => {
                    this.toastr.success('Collection as been updated successfully');
                });
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}


