export class BuyerAddModel {
    public firstName: number;
    public lastName: string;
    public email: string;
    public address: string;
    public city: string;
    public country: string;
    public zipCode: string;
    public companyName: string;
    public password: string;

    public subscriptionPlanId: string;
    public subscriptionPlanName: string;
    public subscriptionStartDate: string;
    public subscriptionEndDate: string;
    public subscriptionStartDateObject: any;
    public subscriptionEndDateObject: any;
    constructor() {
        this.country = '';
        this.subscriptionPlanId = '';
    }
}
