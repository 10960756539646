import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {

  constructor(private router: Router,  private activatedRoute: ActivatedRoute,
              private authService: AuthService, private toastr: ToastrService,
              private ngxSpinner: NgxSpinnerService) { }

  ngOnInit() {
    this.authService.logout();
    const uid = this.activatedRoute.snapshot.queryParams.uid;
    const key = this.activatedRoute.snapshot.queryParams.key;
    this.ngxSpinner.show();
    this.authService.verifyEmail(uid, key).subscribe(response => {
      console.log(response);
      this.ngxSpinner.hide();
      this.toastr.success('Your account has been activated. Please log in to continue.', '', {
        timeOut: 10000
      });
      this.router.navigateByUrl(`/${PageUrls.Login}`);
    }, error => {
      console.error(error);
      this.ngxSpinner.hide();
      AppUtils.processErrorResponse(this.toastr, error);
      this.router.navigateByUrl('/');
    });
  }
}
