import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtils } from 'src/app/helpers/app.utils';
import { TableModel } from 'src/app/models/table.model';
import { PageUrls } from 'src/app/helpers/page-urls';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/admin/services/event.service';
import { PagedEventDetails } from 'src/app/admin/models/paged.event.detail';

@Component({
    selector: 'app-event-table-view',
    templateUrl: './event.table.view.component.html',
    styleUrls: ['./event.table.view.component.scss']
})
export class EventTableViewComponent implements OnInit {
    @Input() tableModel: TableModel;
    @Input() premium: boolean;
    subscriptions = new Array<Subscription>();
    pagedEventDetails = new PagedEventDetails();
    totalRecords = 0;
    isModelLoaded = false;
    isPremium = false;

    get urls() {
        return PageUrls;
    }

    constructor(private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private eventService: EventService,
        private router: Router,
        private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.initSubscribers();
        this.getEvents();
    }


    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getEvents());
        this.subscriptions.push(subscription);
    }

    getEvents = () => {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.eventService.list(this.tableModel)
            .subscribe(data => {
                Object.assign(this.pagedEventDetails, data);
                this.totalRecords = data.totalCount;
                this.tableModel.totalPages = data.totalPages;
                this.pagedEventDetails.items.forEach(event => {
                    event.date = AppUtils.getFormattedDate(event.date);
                });
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }


    deleteEvent(id: number) {
        if (!confirm('Are you sure you want to delete this event?')) {
            return;
        }
        this.ngxSpinner.show();
        this.eventService.delete(id)
            .subscribe(() => {
                this.toastr.success('Event has been deleted successfully');
                this.ngxSpinner.hide();
                this.getEvents();
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    openImageCount(id: number) {
        let queryParams = this.tableModel.toQueryParams();
        queryParams['isTableView'] = true;
        this.router.navigate([], { queryParams: queryParams });
        setTimeout(() => {
            this.router.navigateByUrl(`${PageUrls.Admin.EventManageImage}?id=${id}`);
        });
    }

    openEditEvent(id: number) {
        this.router.navigateByUrl(`${PageUrls.Admin.EventEdit}?eventId=${id}&${this.tableModel.toQueryString()}`);
    }

    resetTableModel() {
        this.tableModel.page = 1;
    }
}
