export class SubscriptionPlanAddModel {
    public name: string;
    public totalSmallImages: number;
    public totalMediumImages: number;
    public totalLargeImages: number;
    public totalSpecialSmallImages: number;
    public totalSpecialMediumImages: number;
    public totalSpecialLargeImages: number;
    public price: number;
    public isPremium: boolean;
    constructor() {
        this.isPremium = false;
    }
}