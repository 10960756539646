import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AppUtils } from 'src/app/helpers/app.utils';
import { TableModel } from 'src/app/models/table.model';
import { PagedPaymentLogModel } from 'src/app/admin/models/paged.payment.log.model';
import { PaymentLogService } from 'src/app/admin/services/payment.log.service';

@Component({
    selector: 'app-payment-history',
    templateUrl: './payment.history.component.html',
    styleUrls: ['./payment.history.component.scss']
})

export class PaymentHistoryComponent implements OnInit, OnDestroy {
    tableModel = new TableModel();
    totalRecords = 0;
    subscriptions = new Array<Subscription>();
    pagedPaymentLogDetail = new PagedPaymentLogModel();
    isModelLoaded = false;

    constructor(private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private paymentLogService: PaymentLogService) {
        this.tableModel.orderBy = 'CreatedOn';
        this.tableModel.orderByDirection = 'desc';
    }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
        this.initSubscribers();
        this.getPaymentLogs();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getPaymentLogs());
        this.subscriptions.push(subscription);
    }

    getPaymentLogs() {
        this.ngxSpinner.show();
        this.paymentLogService.list(this.tableModel)
            .subscribe(data => {
                this.pagedPaymentLogDetail = data;
                this.totalRecords = data.totalCount;
                this.pagedPaymentLogDetail.items.forEach((item) => {
                    item.createdOn = AppUtils.getFormattedDate(item.createdOn, null);
                });
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}
