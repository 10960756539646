import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { TableModel } from 'src/app/models/table.model';
import { PagedList } from 'src/app/models/paged-list';
import { EventDetailModel } from 'src/app/models/event.detail.model';
import { ImageFilterModel } from '../models/image-filter.model';
import { ParentEventDetailModel } from '../models/parent.event.detail.model';
import { ImageDetail } from 'src/app/models/image-detail.model';
import { PremiumEventDetailModel } from '../models/premium.event.detail.model';
import { PagedEventImageDetails } from '../models/paged.event.image.detail';

@Injectable({
    providedIn: 'root'
})

export class EventService {

    constructor(private http: HttpClient) { }

    getParentEventByCategory(categoryId: number, tableModel: TableModel) {
        const queryParams = `categoryId=${categoryId}&${tableModel.toQueryString()}`;
        return this.http.get<PagedList<ParentEventDetailModel>>(
            `${environment.apiUrl}/api/event/list-parent-event-by-category?${queryParams}`);
    }

    getPremiumEvent(tableModel: TableModel) {
        const queryParams = `${tableModel.toQueryString()}`;
        return this.http.get<PagedList<PremiumEventDetailModel>>(
            `${environment.apiUrl}/api/event/list-premium-event?${queryParams}`);
    }

    getPremiumEventsImages(tableModel: TableModel) {
        const queryParams = `${tableModel.toQueryString()}`;
        return this.http.get<PagedList<ImageDetail>>(
            `${environment.apiUrl}/api/event/list-premium-event-images`);
    }

    getByCategory(parentEventId: number, categoryId: number, tableModel: TableModel) {
        const queryParams = `parentEventId=${parentEventId}&categoryId=${categoryId}&${tableModel.toQueryString()}`;
        return this.http.get<PagedList<EventDetailModel>>(`${environment.apiUrl}/api/event/list-by-category?${queryParams}`);
    }

    getByCategoryPremiumEvent(parentEventId: number, categoryId: number, tableModel: TableModel) {
        const queryParams = `parentEventId=${parentEventId}&categoryId=${categoryId}&${tableModel.toQueryString()}`;
        return this.http.get<PagedList<EventDetailModel>>(`${environment.apiUrl}/api/event/list-by-category-premium-events?${queryParams}`);
    }


    getSelectItems(filterModel: ImageFilterModel) {
        let requestUrl = `${environment.apiUrl}/api/event/select-items?`;

        if (filterModel.categoryId) {
            requestUrl += `categoryId=${filterModel.categoryId}`;
        }
        if (filterModel.eventId) {
            requestUrl += `eventId=${filterModel.eventId}`;
        }
        if (filterModel.key) {
            requestUrl += `&filterKey=${filterModel.key}`;
        }
        if (filterModel.photographerName) {
            requestUrl += `&photographer=${filterModel.photographerName}`;
        }
        return this.http.get<Array<SelectListItemModel>>(requestUrl);
    }

    filterEvents = (filterModel: ImageFilterModel, tableModel: TableModel) => {
        let requestUrl = `${environment.apiUrl}/api/event/filter?`;

        if (filterModel.categoryId) {
            requestUrl += `categoryId=${filterModel.categoryId}`;
        }
        if (filterModel.eventId) {
            requestUrl += `${requestUrl.endsWith('?') ? '' : '&'}eventId=${filterModel.eventId}`;
        }
        if (filterModel.collectionId) {
            requestUrl += `${requestUrl.endsWith('?') ? '' : '&'}collectionId=${filterModel.collectionId}`;
        }
        if (filterModel.key) {
            requestUrl += `${requestUrl.endsWith('?') ? '' : '&'}filterKey=${filterModel.key}`;
        }
        if (filterModel.photographerName) {
            requestUrl += `${requestUrl.endsWith('?') ? '' : '&'}photographer=${filterModel.photographerName}`;
        }

        requestUrl += `${requestUrl.endsWith('?') ? '' : '&'}${tableModel.toQueryString()}&searchType=${filterModel.searchType}`;

        return this.http.get<PagedList<EventDetailModel>>(requestUrl);
    }

    getPremiumEventImageList(id: number, tableModel: TableModel): Observable<PagedEventImageDetails> {
        let url = `${environment.apiUrl}/api/event/premium-event-image-list`;
        const queryParams = [];
        if (!isNullOrUndefined(id)) {
            queryParams.push(`id=${id}`);
        }
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedEventImageDetails>(url);
    }

    downloadImage(imageId: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/event/download-file/${imageId}`, { responseType: 'blob' });
    }

    downloadMultipleImages(imagesId: Array<number>, imageSize: string): Observable<any> {
        const queryParams = `${imagesId.map(x => `imagesId=${x}`).join('&')}&imageSize=${imageSize}`;
        return this.http.get(`${environment.apiUrl}/api/event/download-multiple-file?${queryParams}`, { responseType: 'blob' });
    }

    getEventImageList(id: number, tableModel: TableModel): Observable<PagedEventImageDetails> {
        let url = `${environment.apiUrl}/api/event/image-list`;
        const queryParams = [];
        if (!isNullOrUndefined(id)) {
            queryParams.push(`id=${id}`);
        }
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedEventImageDetails>(url);
    }

    logImageViewActivity(eventId: number, imageId: number): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/event/log-image-view-activity/${eventId}/${imageId}`, null);
    }
}
