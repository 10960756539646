import { Component, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ForgotPasswordModel } from 'src/app/models/forgot-password.model';
import { Constants } from 'src/app/helpers/constants';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AppUtils } from 'src/app/helpers/app.utils';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  faUser: IconDefinition;
  model: ForgotPasswordModel;
  emailPattern: string;

  constructor(private ngxSpinnerService: NgxSpinnerService, private authService: AuthService,
              private toastr: ToastrService, private router: Router) { }
  ngOnInit() {
    this.faUser = faUser;
    this.model = new ForgotPasswordModel();
    this.emailPattern = Constants.emailValidationRegEx;
  }

  submit() {
    this.ngxSpinnerService.show();
    this.authService.forgotPassword(this.model)
      .subscribe(response => {
        console.log(response);
        this.ngxSpinnerService.hide();
        this.toastr.success('We have sent you a password reset link. Please check your inbox.',
          '', {
            timeOut: 20000
          });
        this.router.navigateByUrl('/');
      },
      error => {
        console.error(error);
        this.ngxSpinnerService.hide();
        AppUtils.processErrorResponse(this.toastr, error);
      });
}
}
