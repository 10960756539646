export const Constants = {
    authTokenName: 'auth_token',
    cartId: 'cartId',
    passwordRegEx: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$',
    emailValidationRegEx: '^.+@.+..+$',
    Roles: {
        Admin: 'Admin',
        Buyer: 'Buyer',
        Photographer: 'Photographer'
    },
    adminImageFilterIdList: 'admin_image_filter_id_list',
    // stripeKey: 'pk_live_KwZ9XPnqzAde1cDHPX5nSK4v00ZX4BoAJl'
    stripeKey: 'pk_test_51GuGvsJKaKTjXAAhgKxpQScBRfvVP1feU6AIMVsPRqSPUihMQsbK9qNqUbGp4NVfQ0207Zgr9p4XXTqiv2Ya32Ir00J66sIYGY'
};
