import { Component, OnInit, ViewChild, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AdminImageService } from 'src/app/admin/services/admin-image.service';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AppUtils } from 'src/app/helpers/app.utils';
import { TableModel } from 'src/app/models/table.model';
import { ImageFilterModel } from 'src/app/admin/models/image.filter.model';
import { PagedImageGroupeByContributorDetails } from 'src/app/admin/models/paged.image.groupe.by.contributor';

@Component({
    selector: 'app-image-photographer-view',
    templateUrl: './image.photographer.view.component.html',
    styleUrls: ['./image.photographer.view.component.scss']
})
export class ImagePhotographerViewComponent implements OnInit, OnDestroy {
    @ViewChild('imageApproveMultiple', { static: false }) imageApproveMultipleModal: any;
    @Input() imageFilterModel: ImageFilterModel;
    @Input() tableModel: TableModel;
    @Output() openFolderView = new EventEmitter();
    subscriptions = new Array<Subscription>();
    pagedImageGroupeByContributorDetails = new PagedImageGroupeByContributorDetails();
    totalRecords = 0;
    isModelLoaded = false;
    selectedAll = false;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private ngxSpinnerService: NgxSpinnerService,
        private toastr: ToastrService,
        private adminImageService: AdminImageService) { }

    ngOnInit() {
        if (this.route.snapshot.queryParams.status
            && Number(this.route.snapshot.queryParams.status) !== NaN) {
            this.imageFilterModel.status = Number(this.route.snapshot.queryParams.status);
        }
        if (this.route.snapshot.queryParams.photographer) {
            this.imageFilterModel.photographer = this.route.snapshot.queryParams.photographer;
        }

        this.tableModel.orderBy = '';
        this.tableModel.orderByDirection = '';

        this.initSubscribers();
        this.getContributors();

    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getContributors());
        this.subscriptions.push(subscription);
    }

    getSelectedImageIdList() {
        const selectedImages = (this.pagedImageGroupeByContributorDetails.items
            .filter(x => x.isSelected === true).map((item) => item.imageIdList));
        const selectedImageIdList = new Array<number>();
        selectedImages.forEach(imageArr => {
            imageArr.forEach(imageId => {
                selectedImageIdList.push(imageId);
            });
        });
        if (selectedImageIdList.length === 0) {
            this.toastr.error('Please select an image to continue');
            return;
        }
        return selectedImageIdList;
    }

    getContributors = () => {
        this.isModelLoaded = false;
        this.ngxSpinnerService.show();
        this.adminImageService.getListGroupByContributor(this.imageFilterModel, this.tableModel)
            .subscribe(data => {
                this.pagedImageGroupeByContributorDetails = data;
                this.selectedAll = false;
                this.totalRecords = data.totalCount;
                this.ngxSpinnerService.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinnerService.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    reloadImages(resetPagination: boolean) {
        if (resetPagination) {
            this.tableModel.page = 1;
        }
        this.getContributors();
    }

    openImageDetail(imageId: number) {
        let returnParams = `url=${PageUrls.Admin.ImageManage}&`;
        returnParams += `status=${this.imageFilterModel.status}&`;
        returnParams += `photographer=${this.imageFilterModel.photographer}`;
        this.router.navigateByUrl(
            `${PageUrls.Admin.UpdateImage}?imageId=${imageId}&${returnParams}`);
    }

    selectAll() {
        this.pagedImageGroupeByContributorDetails.items.forEach(image => {
            image.isSelected = this.selectedAll;
        });
    }

    updateSelectAll() {
        const selectedImages = this.pagedImageGroupeByContributorDetails.items.filter(x => x.isSelected);
        if (selectedImages.length !== this.pagedImageGroupeByContributorDetails.items.length) {
            this.selectedAll = false;
        } else {
            this.selectedAll = true;
        }
    }

    folderView(photographerId: string) {
        this.openFolderView.next(photographerId);
    }

    viewAllContributors() {
        this.tableModel.pageSize = this.totalRecords;
        this.getContributors();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

}
