import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TableModel } from 'src/app/models/table.model';
import { CollectionFilterModel } from 'src/app/models/collection.filter.model';
import { CollectionListItemModel } from 'src/app/models/collection.list.item.model';
import { CollectionNavItemModel } from 'src/app/models/collection.nav.item.model';
import { PagedList } from 'src/app/models/paged-list';

@Injectable({
    providedIn: 'root'
})
export class CollectionService {

    constructor(private http: HttpClient) { }

    filterCollections = (filterModel: CollectionFilterModel, tableModel: TableModel) => {
        let requestUrl = `${environment.apiUrl}/api/collection/filter?includeThumbnails=${filterModel.includeThumbnails}`;

        if (filterModel.categoryId) {
            requestUrl += `&categoryId=${filterModel.categoryId}`;
        }
        if (filterModel.filterKey) {
            requestUrl += `&filterKey=${filterModel.filterKey}`;
        }

        requestUrl += `&${tableModel.toQueryString()}`;

        return this.http.get<PagedList<CollectionListItemModel>>(requestUrl);
    }

    listFeatured = () => {
        return this.http.get<PagedList<CollectionListItemModel>>(`${environment.apiUrl}/api/collection/list-featured`);
    }

    listNavItems = () =>
        this.http.get<Array<CollectionNavItemModel>>(`${environment.apiUrl}/api/collection/list-nav-items`)
}
