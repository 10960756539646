import { CollectionListItemModel } from './collection.list.item.model';

export class PagedCollectionModel {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    items: Array<CollectionListItemModel>;

    constructor() {
        this.items = new Array<CollectionListItemModel>();
    }
}
