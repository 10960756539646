import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/admin/services/user.service';
import { ContributorEditModel } from 'src/app/admin/models/contributor.edit.model';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { CountryService } from 'src/app/services/country.service';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';

@Component({
    selector: 'app-user-edit-contributor',
    templateUrl: './user.edit.contributor.component.html'
})
export class UserEditContributorComponent implements OnInit {
    isModelLoaded = false;
    emailPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
    model: ContributorEditModel = new ContributorEditModel();
    countries = Array<SelectListItemModel>();

    get urls() {
        return PageUrls;
    }

    constructor(private router: Router, private route: ActivatedRoute,
        private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService, private countryService: CountryService,
        private userService: UserService) {
    }

    ngOnInit() {
        this.model.id = this.route.snapshot.queryParams.contributorId;
        this.getCountries();
        this.getContributor();
    }

    getCountries() {
        this.ngxSpinner.show();
        this.countryService.getSelectList()
            .subscribe(response => {
                this.countries = response;
                this.ngxSpinner.hide();
            },
                error => {
                    console.error(error);
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    getContributor() {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.userService.getContributor(this.model.id)
            .subscribe(data => {
                Object.assign(this.model, data);
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    submit() {
        this.ngxSpinner.show();
        this.userService.editContributor(this.model).subscribe(
            () => {
                this.ngxSpinner.hide();
                this.router.navigateByUrl(`${PageUrls.Admin.UserContributor}`);
                setTimeout(() => {
                    this.toastr.success('Photographer has been updated successfully');
                });
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}
