import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlbumModel } from '../models/album.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { SubcategoryAddModel } from '../admin/models/subcategory.add.model';
import { SubcategoryEditModel } from '../admin/models/subcategory.edit.model';
import { TableModel } from '../models/table.model';
import { PagedSubcategoryDetailModel } from '../admin/models/paged.subcategory.detail';

@Injectable({
  providedIn: 'root'
})
export class AlbumService {

  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) { }

  addAlbum = (albumModel: AlbumModel) =>
    this.httpClient.post(`${environment.apiUrl}/api/admin/image-category/add`, albumModel)

  editAlbum = (albumModel: AlbumModel) =>
    this.httpClient.post(`${environment.apiUrl}/api/admin/image-category/edit`, albumModel)

  getImageUrl = (imageId: number) =>
    this.httpClient.get(`${environment.apiUrl}/api/admin/image-category?imageId=${imageId}`, { responseType: 'blob' })
      .pipe(map(blob => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))))

  getAlbumList(): Observable<AlbumModel[]> {
    return this.httpClient.get<AlbumModel[]>(`${environment.apiUrl}/api/admin/image-category/list`);
  }

  getlabum(id: number): Observable<AlbumModel> {
    return this.httpClient.get<AlbumModel>(environment.apiUrl + '/api/admin/image-category/get/' + id);
  }

  addSubCategory = (model: SubcategoryAddModel) => {
    return this.httpClient.post(`${environment.apiUrl}/api/admin/image-category/add-sub-category`, model);
  }

  getSubcategory = (subcategoryId: string) => {
    return this.httpClient.get(`${environment.apiUrl}/api/admin/image-category/get-for-edit-sub-category/${subcategoryId}`);
  }

  editSubCategory = (model: SubcategoryEditModel) => {
    return this.httpClient.post(`${environment.apiUrl}/api/admin/image-category/edit-sub-category`, model);
  }

  listSubcategory(tableModel: TableModel): Observable<PagedSubcategoryDetailModel> {
    let url = `${environment.apiUrl}/api/admin/image-category/list-subcategory`;
    const queryParams = [];
    if (tableModel) {
      queryParams.push(tableModel.toQueryString());
    }
    if (queryParams.length) {
      url += '?' + queryParams.join('&');
    }
    return this.httpClient.get<PagedSubcategoryDetailModel>(url);
  }


  toggleSubcategory = (id: number) =>
    this.httpClient.post(`${environment.apiUrl}/api/admin/image-category/toggle-subcategory/${id}`, {})

  deleteSubcategory = (id: number) =>
    this.httpClient.post(`${environment.apiUrl}/api/admin/image-category/delete-subcategory/${id}`, {})

}

