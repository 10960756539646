export class EventRemoveImageMultiple {
    public eventId: number;
    public isDeleteImage: boolean;
    public imageIdList: Array<number>;

    constructor() {
        this.isDeleteImage = false;
        this.imageIdList = new Array<number>();
    }
}
