export class CollectionFilterModel {
    categoryId: string;
    filterKey: string;
    includeThumbnails: boolean;

    constructor() {
        this.includeThumbnails = true;
        this.categoryId = '';
        this.filterKey = '';
    }
}
