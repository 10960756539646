import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AdminImageService } from 'src/app/admin/services/admin-image.service';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AppUtils } from 'src/app/helpers/app.utils';
import { Constants } from 'src/app/helpers/constants';
import { PagedImageDetails } from 'src/app/admin/models/PagedImageDetails';
import { TableModel } from 'src/app/models/table.model';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { ImageFilterModel } from 'src/app/admin/models/image.filter.model';

@Component({
    selector: 'app-image-view',
    templateUrl: './image.veiw.component.html',
    styleUrls: ['./image.veiw.component.scss']
})
export class ImageViewComponent implements OnInit, OnDestroy {
    @ViewChild('imageApproveMultiple', { static: false }) imageApproveMultipleModal: any;
    @Input() imageFilterModel: ImageFilterModel;
    @Input() tableModel: TableModel;
    events = new Array<SelectListItemModel>();
    subscriptions = new Array<Subscription>();
    pagedImageDetails = new PagedImageDetails();
    totalRecords = 0;
    isModelLoaded = false;
    selectedAll = false;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private ngxSpinnerService: NgxSpinnerService,
        private toastr: ToastrService,
        private adminImageService: AdminImageService) {
    }

    ngOnInit() {
        if (this.route.snapshot.queryParams.status
            && Number(this.route.snapshot.queryParams.status) !== NaN) {
            this.imageFilterModel.status = Number(this.route.snapshot.queryParams.status);
        }
        if (this.route.snapshot.queryParams.photographer) {
            this.imageFilterModel.photographer = this.route.snapshot.queryParams.photographer;
        }

        this.tableModel.orderBy = 'ApprovedRejectedOn';
        this.tableModel.orderByDirection = 'desc';

        this.initSubscribers();
        this.getImages();
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getImages());
        this.subscriptions.push(subscription);
    }

    getSelectedImageIdList() {
        const selectedImageIdList = this.pagedImageDetails.items.filter(x => x.isSelected === true).map((item) => item.imageId);
        if (selectedImageIdList.length === 0) {
            this.toastr.error('Please select an image to continue');
            return;
        }
        return selectedImageIdList;
    }

    viewAllImages() {
        this.tableModel.pageSize = this.totalRecords;
        this.getImages();
    }

    getImages = () => {
        this.isModelLoaded = false;
        this.ngxSpinnerService.show();
        this.adminImageService.list(this.imageFilterModel, this.tableModel)
            .subscribe(data => {
                this.pagedImageDetails = data;
                this.selectedAll = false;
                this.totalRecords = data.totalCount;
                this.ngxSpinnerService.hide();
                this.isModelLoaded = true;

                if (this.pagedImageDetails
                    && this.pagedImageDetails.items
                    && this.pagedImageDetails.items.length > 0) {
                    const imageIdList = this.pagedImageDetails.items.map((item) => item.imageId);
                    localStorage.setItem(Constants.adminImageFilterIdList, JSON.stringify(imageIdList));
                } else {
                    localStorage.removeItem(Constants.adminImageFilterIdList);
                }
            }, error => {
                this.ngxSpinnerService.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    reloadImages(resetPagination: boolean) {
        if (resetPagination) {
            this.tableModel.page = 1;
        }

        this.getImages();
    }

    openImageDetail(imageId: number) {
        let returnParams = `url=${PageUrls.Admin.ImageManage}&`;
        returnParams += `status=${this.imageFilterModel.status}&`;
        returnParams += `folderName=${this.imageFilterModel.folderName}&`;
        returnParams += `photographer=${this.imageFilterModel.photographer}`;
        this.router.navigateByUrl(
            `${PageUrls.Admin.UpdateImage}?imageId=${imageId}&${returnParams}`);
    }

    selectAll() {
        this.pagedImageDetails.items.forEach(image => {
            image.isSelected = this.selectedAll;
        });
    }

    updateSelectAll() {
        const selectedImages = this.pagedImageDetails.items.filter(x => x.isSelected);
        if (selectedImages.length !== this.pagedImageDetails.items.length) {
            this.selectedAll = false;
        } else {
            this.selectedAll = true;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

}
