import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BuyerSubscriptionAddModel } from 'src/app/buyer/models/subscription.add.model';

@Injectable()
export class BuyerSubscriptionService {

    constructor(private http: HttpClient) { }

    add = (model: BuyerSubscriptionAddModel) =>
        this.http.post(`${environment.apiUrl}/api/user/subscription`, model)

    getUserSubscription = () =>
        this.http.get(`${environment.apiUrl}/api/user/subscription/get-user-subscription`)

}

