import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { faUser, IconDefinition, faKey } from '@fortawesome/free-solid-svg-icons';
import { LoginModel } from 'src/app/models/login.model';
import { Constants } from 'src/app/helpers/constants';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AuthService } from 'src/app/services/auth.service';
import { ListenerService } from 'src/app/services/listener.service';
import { LoginExternalModel } from 'src/app/models/login.external.model';
import { AuthService as SocialAuthService, GoogleLoginProvider } from 'angularx-social-login';

import { UserType } from 'src/app/helpers/enums';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  callBackUrl: string;
  faUser: IconDefinition;
  faKey: IconDefinition;

  model: LoginModel;
  isBuyer: boolean;

  emailPattern: string;
  loginExternalModel: LoginExternalModel = new LoginExternalModel();

  constructor(private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private listenerService: ListenerService,
    private socialAuthService: SocialAuthService) {
    this.callBackUrl = this.route.snapshot.queryParams.url;
    const checkForAdmin = this.route.snapshot.routeConfig.path.indexOf('admin') !== -1;
    const checkForPhotographer = this.route.snapshot.routeConfig.path.indexOf('photographer') !== -1;
    this.isBuyer = (!checkForAdmin && !checkForPhotographer);
    this.model = new LoginModel();
    this.model.userRole = checkForAdmin ? UserType.Admin : (checkForPhotographer ? UserType.Photographer : UserType.Buyer);
  }

  ngOnInit() {
    this.faUser = faUser;
    this.faKey = faKey;
    this.emailPattern = Constants.emailValidationRegEx;
  }

  login() {
    this.spinner.show();
    this.model.cartId = AppUtils.getCartId();
    this.authService.login(this.model).subscribe(response => {
      this.spinner.hide();
      this.listenerService.refreshCartListner.next();
      if (isNullOrUndefined(this.callBackUrl)) {
        this.router.navigateByUrl(`/${PageUrls.Dashboard}`);
      } else {
        this.router.navigateByUrl(this.callBackUrl);
      }
    }, error => {
      this.spinner.hide();
      AppUtils.processErrorResponse(this.toastr, error);
    });
  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)
      .then(
        data => {
          this.externalLoginGoogle(data);
        },
        error => {
          this.toastr.error(error);
        }
      );
  }

  externalLoginGoogle(loginResponse: any) {
    this.loginExternalModel.authToken = loginResponse.authToken;
    this.loginExternalModel.provider = loginResponse.provider;
    this.loginExternalModel.providerKey = loginResponse.id;
    this.loginExternalModel.cartId = AppUtils.getCartId();
    this.spinner.show();
    this.authService.externalLoginGoogle(this.loginExternalModel)
      .subscribe(
        () => {
          this.spinner.hide();
          setTimeout(() => {
            this.listenerService.refreshCartListner.next();
            if (isNullOrUndefined(this.callBackUrl)) {
              this.router.navigateByUrl(`/${PageUrls.Dashboard}`);
            } else {
              this.router.navigateByUrl(this.callBackUrl);
            }
          }, 100);
        },
        error => {
          this.spinner.hide();
          this.socialAuthService.signOut();
          AppUtils.processErrorResponse(this.toastr, error);
        });
  }
}
