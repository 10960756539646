import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/app/helpers/app.utils';
import { TableModel } from 'src/app/models/table.model';
import { PagedContributorEarningDetail } from 'src/app/admin/models/paged.contributor.earning.detail';
import { UserService } from 'src/app/admin/services/user.service';
import { SelectionFilterModel } from 'src/app/admin/models/selection.filter.model';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { ContributorEarningFilterModel } from 'src/app/admin/models/contributor.earning.filter.model';
import { isNullOrUndefined } from 'util';
import { ContributorEarningModel } from 'src/app/admin/models/contributor.earning.model';
import { months } from 'moment';

@Component({
    selector: 'app-contributor-earning',
    templateUrl: './contributor.earning.history.component.html',
    styleUrls: ['./contributor.earning.history.component.scss']
})
export class ContributorEarningHistoryComponent implements OnInit {
    subscriptions: Subscription[];
    totalRecords: number;
    tableModel: TableModel;
    pagedContributorEarningDetails: PagedContributorEarningDetail = new PagedContributorEarningDetail();
    isModelLoaded = false;
    contributors: Array<SelectListItemModel>;
    contributorSelectionFilter = new SelectionFilterModel<ContributorEarningFilterModel>();
    selectedContributor: SelectListItemModel;
    earningFilterModel = new ContributorEarningFilterModel();

    years: Array<number>;
    months: Array<string>;

    constructor(private ngxSpinnerService: NgxSpinnerService,
        private toastr: ToastrService,
        private userService: UserService) {
        this.selectedContributor = this.getDefaultContributor();
    }

    ngOnInit() {
        this.years = AppUtils.getYears();

        this.tableModel = new TableModel();
        this.totalRecords = 0;
        this.subscriptions = [];
        this.initSubscribers();
        this.getContributorSelectItems();
        this.getContributorEarnings();
    }

    getDefaultContributor() {
        const defaultContributor = new SelectListItemModel();
        defaultContributor.key = null;
        defaultContributor.value = 'All';
        return defaultContributor;
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getContributorEarnings());
        this.subscriptions.push(subscription);
    }

    getContributorSelectItems = () => {
        this.userService.getContributorSelectItems()
            .subscribe(response => {
                this.contributors = response;
            }, error => {
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    selectAll() {
        this.pagedContributorEarningDetails.items.forEach(order => {
            order.isSelected = this.contributorSelectionFilter.selectedAll;
        });
        if (this.contributorSelectionFilter.selectedAll) {
            this.contributorSelectionFilter.deselectedItems = new Array<string>();
        } else {
            this.contributorSelectionFilter.selectedItems = new Array<string>();
        }
    }

    updateSelectAll(order: ContributorEarningModel) {
        if (order.isSelected) {
            const check = this.contributorSelectionFilter.selectedItems.includes(order.id);
            if (!check) {
                this.contributorSelectionFilter.selectedItems.push(order.id);
            }
            const index = this.contributorSelectionFilter.deselectedItems.indexOf(order.id);
            this.contributorSelectionFilter.deselectedItems.splice(index, 1);
        } else {
            this.contributorSelectionFilter.selectedAll = false;
            const index = this.contributorSelectionFilter.selectedItems.indexOf(order.id);
            this.contributorSelectionFilter.selectedItems.splice(index, 1);
            const check = this.contributorSelectionFilter.deselectedItems.includes(order.id);
            if (!check) {
                this.contributorSelectionFilter.deselectedItems.push(order.id);
            }
        }
    }

    setContributor = (contributor: SelectListItemModel) => {
        this.selectedContributor = contributor || this.getDefaultContributor();
        this.getContributorEarnings();
    }

    getYearMonthSerialized() {
        if (isNullOrUndefined(this.earningFilterModel.year) || this.earningFilterModel.year === '') {
            this.months.splice(0, this.months.length);
            this.earningFilterModel.month = '';
        } else {
            this.months = AppUtils.getMonths(Number(this.earningFilterModel.year));
        }
        this.getContributorEarnings();
    }

    getContributorEarnings = () => {
        this.isModelLoaded = false;
        this.ngxSpinnerService.show();
        if (isNullOrUndefined(this.selectedContributor.key)) {
            this.earningFilterModel.contributorId = '';
        } else {
            this.earningFilterModel.contributorId = this.selectedContributor.key;
        }
        this.userService.getContributorEarning(this.earningFilterModel, this.tableModel)
            .subscribe(data => {
                this.pagedContributorEarningDetails = data;
                this.totalRecords = data.totalCount;

                if (this.contributorSelectionFilter.selectedAll) {
                    this.pagedContributorEarningDetails.items.forEach(order => {
                        order.isSelected = this.contributorSelectionFilter.selectedAll;
                        const check = this.contributorSelectionFilter.selectedItems.includes(order.id);
                        if (!check) {
                            this.contributorSelectionFilter.selectedItems.push(order.id);
                        }
                    });
                } else {
                    if (this.contributorSelectionFilter.deselectedItems.length > 0) {
                        this.pagedContributorEarningDetails.items.forEach(order => {
                            order.isSelected = !this.contributorSelectionFilter.deselectedItems.includes(order.id);
                        });
                    } else {
                        if (this.contributorSelectionFilter.selectedItems.length > 0) {
                            this.pagedContributorEarningDetails.items.forEach(order => {
                                order.isSelected = this.contributorSelectionFilter.selectedItems.includes(order.id);
                            });
                        }
                    }
                }

                this.ngxSpinnerService.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinnerService.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    exportToExcel() {
        if (!this.contributorSelectionFilter.selectedAll) {
            if (this.contributorSelectionFilter.deselectedItems.length === 0
                && this.contributorSelectionFilter.selectedItems.length === 0) {
                this.toastr.error('Please select an photographer earning to continue');
                return;
            }
            if (this.contributorSelectionFilter.deselectedItems.length === this.pagedContributorEarningDetails.totalCount) {
                this.toastr.error('Please select an photographer earning to continue');
                return;
            }
        }
        this.ngxSpinnerService.show();
        this.contributorSelectionFilter.gridFilter = this.earningFilterModel;
        this.userService.exportToExcelContributorEarning(this.contributorSelectionFilter).subscribe(
            fileBytes => {
                const blob: any = new Blob([fileBytes], { type: 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const element = document.createElement('a');
                element.href = url;
                element.download = `${AppUtils.getFormattedDate(new Date())}.xlsx`;
                document.body.appendChild(element);
                this.ngxSpinnerService.hide();
                element.click();
            },
            error => {
                this.ngxSpinnerService.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}
