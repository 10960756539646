import { Component, OnInit, OnDestroy } from '@angular/core';
import { PhotographerImageService } from '../../services/photographer-image.service';
import { AppUtils } from 'src/app/helpers/app.utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PhotographerImageDetails } from '../../models/photographer-image-detail.model';
import { ImageStatusEnum } from 'src/app/helpers/enums';
import { TableModel } from 'src/app/models/table.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-images-history',
  templateUrl: './images-history.component.html',
  styleUrls: ['./images-history.component.scss']
})
export class ImagesHistoryComponent implements OnInit, OnDestroy {

  images: PhotographerImageDetails[];

  tableModel: TableModel;

  totalRecords: number;

  private subscriptions: Subscription[];

  get imageStatus() {
    return ImageStatusEnum;
  }

  constructor(private imageService: PhotographerImageService, private spinner: NgxSpinnerService,
    private toastrService: ToastrService) { }

  ngOnInit() {
    this.images = [];
    this.tableModel = new TableModel();
    this.tableModel.orderBy = 'CreatedOn';
    this.tableModel.orderByDirection = 'DESC';
    this.totalRecords = 0;
    this.subscriptions = [];
    this.loadImages();
    this.initSubscribers();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  initSubscribers() {
    const subscription = this.tableModel.pageChanged.subscribe(() => this.loadImages());
    this.subscriptions.push(subscription);
  }

  loadImages() {
    this.spinner.show();
    this.imageService.list(this.tableModel).subscribe(data => {
      this.images = data.items;
      this.totalRecords = data.totalCount;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      AppUtils.processErrorResponse(this.toastrService, error);
    });
  }
}
