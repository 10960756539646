import { Component, OnInit } from '@angular/core';
import { ImageCategoryService } from 'src/app/services/image-category.service';
import { AlbumModel } from 'src/app/models/album.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/app/helpers/app.utils';
import { ActivatedRoute, Router } from '@angular/router';
import { HeroSearchModel } from 'src/app/models/hero.search.model';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss']
})
export class HeroSectionComponent implements OnInit {

  searchModel = new HeroSearchModel();
  imageCategories: AlbumModel[];

  constructor(private imageCategoryService: ImageCategoryService, private spinner: NgxSpinnerService,
    private toastr: ToastrService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.searchModel.key = this.route.snapshot.queryParams.key;
    this.loadImageCategories();
  }

  loadImageCategories() {
    this.spinner.show();
    this.imageCategoryService.getCategories().subscribe(items => {
      this.imageCategories = items;
      if (!isNullOrUndefined(this.route.snapshot.queryParams.category)) {
        const selectedCategory = this.imageCategories.find(x => x.name === this.route.snapshot.queryParams.category);
        this.searchModel.categoryId = selectedCategory.id.toString();
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      AppUtils.processErrorResponse(this.toastr, error);
    });
  }

  startSearch() {
    let requestUrl = `image/filter?category=${this.searchModel.categoryId}`;
    if (this.searchModel.key) {
      requestUrl += `&key=${this.searchModel.key}`;
    }
    this.router.navigateByUrl(requestUrl);
  }

  clearFilterKey() {
    this.searchModel.key = null;
  }
}
