import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';;
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventFilterModel } from 'src/app/admin/models/event.filter.model';
import { SelectionEventFilterModel } from 'src/app/admin/models/selection.event.filter.model';
import { PagedEventDetails } from 'src/app/admin/models/paged.event.detail';
import { TableModel } from 'src/app/models/table.model';
import { EventService } from 'src/app/admin/services/event.service';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { ToastrService } from 'ngx-toastr';
import { EventDetailModel } from 'src/app/admin/models/event.detail.model';
import { BuyerListModel } from 'src/app/admin/models/buyer.list.model';
import { PremiumEventSelectedWithComponent } from '../shared-with/premium.event.shared.with.component';

@Component({
    selector: 'app-premium-event-table-view',
    templateUrl: './premium.event.table.view.component.html',
})

export class PremiumEventTableComponent implements OnInit {
    @ViewChild('eventSharedWith', null) eventSharedWith: PremiumEventSelectedWithComponent;
    @Input() tableModel: TableModel;
    modalRef: NgbModalRef;
    pagedEventDetails = new PagedEventDetails();
    subscriptions = new Array<Subscription>();
    totalRecords = 0;
    eventFilterModel = new Array<EventFilterModel>();
    eventSelectionFilter = new SelectionEventFilterModel<EventFilterModel>();
    isModelLoaded: boolean;
    closeResult: string;
    buyerList = new Array<BuyerListModel>();

    constructor(private ngxSpinner: NgxSpinnerService,
        private modalService: NgbModal,
        private router: Router,
        private eventService: EventService,
        private toastr: ToastrService) { }

    ngOnInit() {
        this.getPremiumEvents();
        this.initSubscribers();
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getPremiumEvents());
        this.subscriptions.push(subscription);
    }

    getPremiumEvents = () => {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.eventService.premiumEventslist(this.tableModel)
            .subscribe(data => {
                Object.assign(this.pagedEventDetails, data);
                this.totalRecords = data.totalCount;
                this.tableModel.totalPages = data.totalPages;
                this.pagedEventDetails.items.forEach(event => {
                    event.date = AppUtils.getFormattedDate(event.date);
                });
                if (this.eventSelectionFilter.selectedAll) {
                    this.pagedEventDetails.items.forEach(event => {
                        event.isSelected = this.eventSelectionFilter.selectedAll;
                        const check = this.eventSelectionFilter.selectedItems.includes(event.id);
                        if (!check) {
                            this.eventSelectionFilter.selectedItems.push(event.id);
                        }
                    });
                } else {
                    if (this.eventSelectionFilter.deselectedItems.length > 0) {
                        this.pagedEventDetails.items.forEach(event => {
                            event.isSelected = !this.eventSelectionFilter.deselectedItems.includes(event.id);
                        });
                    } else {
                        if (this.eventSelectionFilter.selectedItems.length > 0) {
                            this.pagedEventDetails.items.forEach(event => {
                                event.isSelected = this.eventSelectionFilter.selectedItems.includes(event.id);
                            });
                        }
                    }
                }
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    deleteEvent(id: number) {
        if (!confirm('Are you sure you want to delete this event?')) {
            return;
        }
        this.ngxSpinner.show();
        this.eventService.delete(id)
            .subscribe(() => {
                this.toastr.success('Event has been deleted successfully');
                this.ngxSpinner.hide();
                this.getPremiumEvents();
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    openEditEvent(id: number) {
        this.router.navigateByUrl(`${PageUrls.Admin.EventEdit}?eventId=${id}&${this.tableModel.toQueryString()}`);
    }

    openImageCount(id: number) {
        const queryParams = this.tableModel.toQueryParams();
        queryParams['isTableView'] = true;
        this.router.navigate([], { queryParams: queryParams });
        setTimeout(() => {
            this.router.navigateByUrl(`${PageUrls.Admin.EventManageImage}?id=${id}`);
        });
    }

    openEventSharedWithDialog(eventId: number) {
        this.eventSharedWith.openModal(eventId);
    }

    resetTableModel() {
        this.tableModel.page = 1;
    }

    updateSelectAll(event: EventDetailModel) {
        if (event.isSelected) {
            const check = this.eventSelectionFilter.selectedItems.includes(event.id);
            if (!check) {
                this.eventSelectionFilter.selectedItems.push(event.id);
            }
            const index = this.eventSelectionFilter.deselectedItems.indexOf(event.id);
            this.eventSelectionFilter.deselectedItems.splice(index, 1);
        } else {
            this.eventSelectionFilter.selectedAll = false;
            const index = this.eventSelectionFilter.selectedItems.indexOf(event.id);
            this.eventSelectionFilter.selectedItems.splice(index, 1);
            const check = this.eventSelectionFilter.deselectedItems.includes(event.id);
            if (!check) {
                this.eventSelectionFilter.deselectedItems.push(event.id);
            }
        }
    }
}
