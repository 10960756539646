import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AppUtils } from 'src/app/helpers/app.utils';
import { EventService } from 'src/app/admin/services/event.service';
import { AlbumService } from 'src/app/services/album.service';
import { AlbumModel } from 'src/app/models/album.model';
import { EventEditModel } from 'src/app/admin/models/event.edit.model';
import { FileUploader, FileItem, FileLikeObject } from 'ng2-file-upload';
import { IconDefinition, faBan, faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { UserService } from 'src/app/admin/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { isNullOrUndefined } from 'util';
import { ParentEventListItemModel } from 'src/app/models/parent.event.list.item.model';
import { TableModel } from 'src/app/models/table.model';

@Component({
    selector: 'app-event-edit',
    templateUrl: './event.edit.component.html'
})
export class EventEditComponent implements OnInit {
    parentEvents = new Array<ParentEventListItemModel>();
    imageCategories = new Array<AlbumModel>();
    imageSubCategories = new Array<AlbumModel>();
    eventDate: any;
    minEventDate: any;
    maxEventDate: any;
    uploader: FileUploader;
    faUpload: IconDefinition;
    faBan: IconDefinition;
    faTimes: IconDefinition;
    categoryId = '';
    subCategoryId = '';
    model = new EventEditModel();
    photographers = new Array<SelectListItemModel>();
    tableModel = new TableModel();

    constructor(private router: Router,
        private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private albumService: AlbumService,
        private userService: UserService,
        private authService: AuthService,
        private eventService: EventService) {
        const currentDate = new Date();
        this.minEventDate = { year: 1800, month: 1, day: 1 };
        this.maxEventDate = { year: currentDate.getFullYear(), month: (currentDate.getMonth() + 1), day: currentDate.getDate() };
        this.tableModel.orderBy = this.route.snapshot.queryParams.orderBy;
        this.tableModel.orderByDirection = this.route.snapshot.queryParams.orderBy;
        this.tableModel.page = this.route.snapshot.queryParams.page;
        this.tableModel.pageSize = this.route.snapshot.queryParams.pageSize;
        this.tableModel.filterKey = this.route.snapshot.queryParams.filterKey;
        this.tableModel.startDate = this.route.snapshot.queryParams.startDate;
        this.tableModel.endDate = this.route.snapshot.queryParams.endDate;
    }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
        this.model.id = this.route.snapshot.queryParams.eventId;

        this.uploader = new FileUploader({
            url: `${environment.apiUrl}/api/admin/image/upload-image`,
            disableMultipart: false,
            formatDataFunctionIsAsync: false,
            allowedMimeType: ['image/jpeg', 'image/jpg'],
            autoUpload: false,
            authToken: `Bearer ${this.authService.getAuthToken()} `
        });

        this.uploader.onWhenAddingFileFailed = this.onWhenAddingFileFailed;
        this.uploader.onAfterAddingFile = this.onAfterAddingFile;
        this.uploader.onCompleteAll = this.onCompleteAll;

        this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
            form.append('eventId', this.model.id);
            form.append('contributorId', this.model.photographerId);
            form.append('priceForSmall', this.model.priceForSmall);
            form.append('priceForMedium', this.model.priceForMedium);
            form.append('priceForLarge', this.model.priceForLarge);
        };

        this.faUpload = faUpload;
        this.faBan = faBan;
        this.faTimes = faTrash;

        this.loadParentEvent();
        this.loadCategories();
        this.loadPhotographers();
        this.getEvent();
    }

    loadParentEvent() {
        this.ngxSpinner.show();
        this.eventService.getParentEventSelectItems().subscribe(
            (events) => {
                this.ngxSpinner.hide();
                this.parentEvents = events;
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    loadCategories() {
        this.ngxSpinner.show();
        this.albumService.getAlbumList().subscribe(
            (categories) => {
                this.ngxSpinner.hide();
                this.imageCategories = categories;
                this.categoryChanged();
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    loadPhotographers() {
        this.userService.getContributorSelectItems().subscribe(
            (response) => {
                this.photographers = response;
            },
            error => {
                console.log(error);
            });
    }


    categoryChanged() {
        this.subCategoryId = '';

        if (!this.imageCategories || this.imageCategories.length === 0) {
            return;
        }

        const imageCategory = this.imageCategories.find(x => x.id.toString() === this.categoryId);
        if (imageCategory) {
            this.imageSubCategories = imageCategory.subCategories;
            return;
        }

        this.imageCategories.forEach((category) => {
            if (!category.subCategories
                || category.subCategories.length === 0) {
                return;
            }
            const imageSubCategory = category.subCategories.find(x => x.id === Number(this.categoryId));
            if (imageSubCategory) {
                this.imageSubCategories = category.subCategories;
                this.categoryId = category.id.toString();
                this.subCategoryId = imageSubCategory.id.toString();
            }
        });
    }

    getEvent() {
        this.ngxSpinner.show();
        this.eventService.get(this.model.id).subscribe(
            data => {
                this.ngxSpinner.hide();
                Object.assign(this.model, data);
                this.eventDate = AppUtils.getNgDatePickerDate(this.model.date);
                this.categoryId = this.model.categoryId.toString();
                this.categoryChanged();
                if (!this.model.photographerId) {
                    this.model.photographerId = '';
                }
                if (isNullOrUndefined(this.model.parentId)) {
                    this.model.parentId = '';
                }
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    parentEventChanged() {
        if (isNullOrUndefined(this.model.parentId) || this.model.parentId === '') {
            this.categoryId = '';
            this.subCategoryId = '';
            this.imageSubCategories = new Array<AlbumModel>();
            return;
        }
        this.categoryId = this.parentEvents.find(x => x.id === Number(this.model.parentId)).categoryId;
        this.categoryChanged();
    }

    submit() {
        this.model.categoryId = this.subCategoryId
            ? Number(this.subCategoryId)
            : Number(this.categoryId);

        this.model.date = AppUtils.getFormattedDate(this.eventDate);
        console.log(this.model);
        this.ngxSpinner.show();
        this.eventService.edit(this.model).subscribe(
            () => {
                this.uploadImages();
                if (this.uploader.queue.length === 0) {
                    this.ngxSpinner.hide();
                    if (this.model.isPremium === true) {
                        this.router.navigateByUrl(`${PageUrls.Admin.PremiumEvent}?isTableView=true&${this.tableModel.toQueryString()}`);
                    } else {
                        this.router.navigateByUrl(`${PageUrls.Admin.EventManage}?isTableView=true&${this.tableModel.toQueryString()}`);
                    }
                    setTimeout(() => {
                        this.toastr.success('Event has been updated successfully');
                    });
                }
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
        this.toastr.info('Only jpg/jpeg images are allowed.');
    }

    onAfterAddingFile = (fileItem: FileItem) => {
        if (!fileItem.file.name.trim().toLowerCase().endsWith('.jpeg')
            && !fileItem.file.name.trim().toLowerCase().endsWith('.jpg')) {
            fileItem.remove();
            this.toastr.info('Only jpg/jpeg images are allowed.');
        }
    }

    onCompleteAll = () => {
        this.ngxSpinner.hide();
        const failedItems = this.uploader.queue.filter(x => x.isError);
        if (failedItems.length === 0) {
            this.router.navigateByUrl(`${PageUrls.Admin.EventManage} `);
            setTimeout(() => {
                this.toastr.success('Event has been updated successfully');
            });
        }
    }

    resetImageUpload() {
        this.model.priceForSmall = '';
        this.model.priceForMedium = '';
        this.model.priceForLarge = '';
        this.uploader.queue.forEach(query => {
            query.remove();
        });
    }

    uploadImages() {
        const itemsToUpload = this.uploader.queue.filter(x => !x.isUploaded || x.isCancel || x.isError);
        itemsToUpload.forEach(query => {
            query.upload();
        });
    }

    cancel() {
        if (this.model.isPremium === false) {
            this.router.navigateByUrl(`${PageUrls.Admin.EventManage}?isTableView=true&${this.tableModel.toQueryString()}`);
        } else {
            this.router.navigateByUrl(`${PageUrls.Admin.PremiumEvent}?isTableView=true&${this.tableModel.toQueryString()}`);
        }

    }
}
