export class AddCartItemModel {
    cartId: string;
    imageId: number;
    imageSize: number;
    imageIds: Array<number>;
    eventId: number;

    constructor() {
        this.imageIds = new Array<number>();
    }
}
