import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/admin/services/user.service';
import { ContributorAddModel } from 'src/app/admin/models/contributor.add.model';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { CountryService } from 'src/app/services/country.service';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { Constants } from 'src/app/helpers/constants';

@Component({
    selector: 'app-user-add-contributor',
    templateUrl: './user.add.contributor.component.html'
})
export class UserAddContributorComponent implements OnInit {
    isModelLoaded = false;
    emailPattern: string;
    confirmPassword: string;
    model: ContributorAddModel = new ContributorAddModel();
    countries = Array<SelectListItemModel>();

    passwordPattern: string;

    get urls() {
        return PageUrls;
    }

    constructor(private router: Router, private route: ActivatedRoute,
        private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService, private countryService: CountryService,
        private userService: UserService) {
    }

    ngOnInit() {
        this.emailPattern = Constants.emailValidationRegEx;
        this.passwordPattern = Constants.passwordRegEx;
        this.getCountries();
    }

    getCountries() {
        this.ngxSpinner.show();
        this.countryService.getSelectList()
            .subscribe(response => {
                this.countries = response;
                this.ngxSpinner.hide();
            },
                error => {
                    console.error(error);
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    submit() {
        this.ngxSpinner.show();
        this.userService.addContributor(this.model).subscribe(
            () => {
                this.ngxSpinner.hide();
                this.router.navigateByUrl(`${PageUrls.Admin.UserContributor}`);
                setTimeout(() => {
                    this.toastr.success('Photographer has been added successfully');
                });
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}
