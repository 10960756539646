import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/app/helpers/app.utils';
import { AuthService } from 'src/app/services/auth.service';
import { Constants } from 'src/app/helpers/constants';
import { BuyerRegistrationModel } from 'src/app/models/buyer.registration.model';

@Component({
  selector: 'app-buyer-registration',
  templateUrl: './buyer.registration.component.html',
  styleUrls: ['./buyer.registration.component.scss']
})

export class BuyerRegistrationComponent implements OnInit {

  callBackUrl: string;
  confirmPassword: string;
  emailPattern: string;
  passwordPattern: string;

  model = new BuyerRegistrationModel();

  constructor(private router: Router,
    private ngxSpinner: NgxSpinnerService,
    private authService: AuthService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.emailPattern = Constants.emailValidationRegEx;
    this.passwordPattern = Constants.passwordRegEx;
  }

  register() {
    this.ngxSpinner.show();
    this.authService.buyerRegistration(this.model)
      .subscribe(() => {
        this.ngxSpinner.hide();
        this.toastr.success(`A verification link has been sent to your registered email.
         Please click on the link to proceed with the verification process.`,
          '', {
          timeOut: 20000
        });

        this.router.navigateByUrl('/');

      },
        error => {
          this.ngxSpinner.hide();
          AppUtils.processErrorResponse(this.toastr, error);
        });
  }
}
