import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TableModel } from 'src/app/models/table.model';
import { Observable } from 'rxjs';
import { PagedOrderDetails } from '../models/paged.order.detail';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { OrderFilterModel } from 'src/app/admin/models/order.filter.model';
import { isNullOrUndefined } from 'util';
import { SelectionFilterModel } from '../models/selection.filter.model';

@Injectable()
export class OrderService {

    constructor(private http: HttpClient) { }

    list(filterModel: OrderFilterModel, tableModel: TableModel): Observable<PagedOrderDetails> {
        let url = `${environment.apiUrl}/api/admin/order/list`;
        const queryParams = [];
        const tempArr = [];
        if (!isNullOrUndefined(filterModel.customerId)) {
            tempArr.push(`customerId=${filterModel.customerId}`);
        }
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&') + '&' + tempArr.join('&');
        }
        return this.http.get<PagedOrderDetails>(url);
    }

    getOrderById(id: string) {
        return this.http.get(`${environment.apiUrl}/api/admin/order/${id}`);
    }

    getCustomerSelectListItems(): Observable<SelectListItemModel[]> {
        return this.http.get<SelectListItemModel[]>(`${environment.apiUrl}/api/admin/order/customer-select-items`);
    }

    exportToExcel(filterModel: any) {
        return this.http.post(`${environment.apiUrl}/api/admin/order/export-as-excel`, filterModel, { responseType: 'blob' });
    }
}
