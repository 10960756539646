import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { BuyerRegistrationComponent } from './components/buyer-registration/buyer.registration.component';
import { ContributorRegistrationComponent } from './components/contributor-registration/contributor.registration.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { AnonymousGuard } from 'src/app/guards/anonymous.guard';
import { PageUrlsParts } from 'src/app/helpers/page-urls';

const routes: Routes = [
  { path: PageUrlsParts.Login, component: LoginComponent, canActivate: [AnonymousGuard] },
  { path: PageUrlsParts.Registration, component: BuyerRegistrationComponent, canActivate: [AnonymousGuard] },
  { path: PageUrlsParts.VerifyEmail, component: EmailVerificationComponent },
  { path: PageUrlsParts.ResetPassword, component: PasswordResetComponent, canActivate: [AuthGuard] },
  { path: PageUrlsParts.ForgotPassword, component: ForgotPasswordComponent, canActivate: [AnonymousGuard] },
  { path: PageUrlsParts.SetPassword, component: SetPasswordComponent },
  { path: PageUrlsParts.PhotographerSignUp, component: ContributorRegistrationComponent, canActivate: [AnonymousGuard] },
  { path: PageUrlsParts.Admin.Self, component: LoginComponent, canActivate: [AnonymousGuard] },
  { path: PageUrlsParts.Photographer.Self, component: ContributorRegistrationComponent, canActivate: [AnonymousGuard] },
  { path: PageUrlsParts.Admin.Login, component: LoginComponent, canActivate: [AnonymousGuard] },
  { path: PageUrlsParts.Photographer.Login, component: LoginComponent, canActivate: [AnonymousGuard] }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
