import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PageUrls } from 'src/app/helpers/page-urls';
import { EventService } from 'src/app/admin/services/event.service';
import { TableModel } from 'src/app/models/table.model';
import { Subscription } from 'rxjs';
import { AppUtils } from 'src/app/helpers/app.utils';
import { Constants } from 'src/app/helpers/constants';
import { PagedEventImageDetails } from 'src/app/admin/models/paged.event.image.detail';
import { AdminImageService } from 'src/app/admin/services/admin-image.service';
import { EventRemoveImageMultiple } from 'src/app/admin/models/event.remove.image.multiple.model';
import { EventSetCoverImageModel } from 'src/app/admin/models/event.set.cover.image.model';
import { ImageDeleteMultipleModel } from 'src/app/admin/models/image.delete.multiple.model';

@Component({
    selector: 'app-event-manage-image',
    templateUrl: './event.manage.image.component.html',
    styleUrls: ['./event.manage.image.component.scss']
})
export class EventManageImageComponent implements OnInit, OnDestroy {
    tableModel = new TableModel();
    eventId: number;
    totalRecords: number;
    private subscriptions: Subscription[];
    pagedEventImageDetails = new PagedEventImageDetails();
    eventRemoveImageMultiple = new EventRemoveImageMultiple();
    eventSetCoverImage = new EventSetCoverImageModel();
    imageDeleteMultiple = new ImageDeleteMultipleModel();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private eventService: EventService,
        private adminImageService: AdminImageService) {

        this.route.queryParams.subscribe(params => {
            this.eventId = params['id'];
            this.eventRemoveImageMultiple.eventId = this.eventId;
            this.eventSetCoverImage.eventId = this.eventId;
        });
    }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
        this.tableModel = new TableModel();
        this.totalRecords = 0;
        this.subscriptions = [];
        this.initSubscribers();
        this.getEventImages();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getEventImages());
        this.subscriptions.push(subscription);
    }

    getEventImages = () => {
        this.ngxSpinner.show();
        this.eventService.getEventImageList(this.eventId, this.tableModel)
            .subscribe(data => {
                Object.assign(this.pagedEventImageDetails, data);
                this.totalRecords = this.pagedEventImageDetails.totalCount;
                if (this.pagedEventImageDetails.items.length === 0) {
                    this.router.navigateByUrl(`${PageUrls.Admin.EventManage}`);
                }
                this.pagedEventImageDetails.items.forEach((item) => {
                    item.approvedRejectedOn = AppUtils.getFormattedDate(item.approvedRejectedOn, null);
                });
                if (this.pagedEventImageDetails
                    && this.pagedEventImageDetails.items
                    && this.pagedEventImageDetails.items.length > 0) {
                    const imageIdList = this.pagedEventImageDetails.items.map((item) => item.imageId);
                    localStorage.setItem(Constants.adminImageFilterIdList, JSON.stringify(imageIdList));
                } else {
                    localStorage.removeItem(Constants.adminImageFilterIdList);
                }
                this.ngxSpinner.hide();
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    removeEventImages() {
        const selectedImageIdList = this.pagedEventImageDetails.items.filter(x => x.isSelected === true).map((item) => item.imageId);
        if (selectedImageIdList.length === 0) {
            this.toastr.error('Please select an image to remove');
            return;
        }
        this.eventRemoveImageMultiple.imageIdList = selectedImageIdList;
        this.ngxSpinner.show();
        this.eventService.removeImageMultiple(this.eventRemoveImageMultiple)
            .subscribe(
                () => {
                    this.ngxSpinner.hide();
                    this.toastr.success('Selected images has been removed from event successfully');
                    this.getEventImages();
                },
                error => {
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    deleteImages() {
        const selectedImageIdList = this.pagedEventImageDetails.items.filter(x => x.isSelected === true).map((item) => item.imageId);
        if (selectedImageIdList.length === 0) {
            this.toastr.error('Please select an image to remove');
            return;
        }

        if (!confirm('Are you sure you want to delete selected images?')) {
            return;
        }

        this.imageDeleteMultiple.imageIdList = selectedImageIdList;
        this.ngxSpinner.show();
        this.adminImageService.deleteMultiple(this.imageDeleteMultiple)
            .subscribe(
                () => {
                    this.ngxSpinner.hide();
                    this.toastr.success('Selected images has been removed from event and deleted successfully');
                    this.getEventImages();
                },
                error => {
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    setCoverImage() {
        const selectedImageIdList = this.pagedEventImageDetails.items.filter(x => x.isSelected === true).map((item) => item.imageId);
        if (selectedImageIdList.length === 0) {
            this.toastr.error('Please select an image to set cover');
            return;
        }
        if (selectedImageIdList.length > 1) {
            this.toastr.error('Only one image can be set as cover image.');
            return;
        }
        this.eventSetCoverImage.imageId = selectedImageIdList[0];
        this.ngxSpinner.show();
        this.eventService.setCoverImage(this.eventSetCoverImage)
            .subscribe(
                () => {
                    this.ngxSpinner.hide();
                    this.toastr.success('Selected image has been set as event cover successfully');
                    this.getEventImages();
                },
                error => {
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    openImageDetail(imageId: number) {
        this.router.navigate([PageUrls.Admin.UpdateImage], { queryParams: { imageId: imageId, url: PageUrls.Admin.EventManage } });
    }
}
