import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { LoginModel } from '../models/login.model';
import { ChangePasswordModel } from '../models/changepassword.model';
import { ForgotPasswordModel } from '../models/forgot-password.model';
import { SetPasswordModel } from '../models/set-password.model';
import { UserInfo } from '../models/user-info';
import { Constants } from '../helpers/constants';
import { AppUtils } from '../helpers/app.utils';
import { BuyerRegistrationModel } from '../models/buyer.registration.model';
import { ContributorRegistrationModel } from '../models/contributor.registration.model';
import { ContributorProfileEditModel } from '../photographer/models/contributor.profile.edit.model';
import { LoginExternalModel } from '../models/login.external.model';
import { SelectListItemModel } from '../models/select.list.item.model';
import { ImageFilterModel } from '../models/image-filter.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userInfoSubject: BehaviorSubject<UserInfo>;

  get userInfo$() {
    return this.userInfoSubject.asObservable();
  }

  constructor(private httpClient: HttpClient) {
    this.userInfoSubject = new BehaviorSubject(undefined);
  }

  getAuthToken = () => localStorage.getItem(Constants.authTokenName);

  initUserIfLoggedIn() {
    const token = this.getAuthToken();
    if (token) {
      this.saveUserInfoFromAuthToken(token);
    }
  }

  isUserLoggedIn = () => !!this.userInfoSubject.value;

  isLoggedInUserInRole = (role: string) => !!this.userInfoSubject.value && this.userInfoSubject.value.role === role;

  logout = () => {
    localStorage.removeItem(Constants.authTokenName);
    localStorage.removeItem(Constants.cartId);
    this.userInfoSubject.next(undefined);
  }

  login = (loginModel: LoginModel) =>
    this.httpClient.post(`${environment.apiUrl}/api/account/login`, loginModel)
      .pipe(tap((token: string) => this.processAuthToken(token)))

  buyerRegistration = (registrationModel: BuyerRegistrationModel) =>
    this.httpClient.post(`${environment.apiUrl}/api/account/register-buyer`, registrationModel)

  contributorRegistration = (registrationModel: ContributorRegistrationModel) =>
    this.httpClient.post(`${environment.apiUrl}/api/account/register-contributor`, registrationModel)

  changepassword = (changePasswordModel: ChangePasswordModel) =>
    this.httpClient.post(`${environment.apiUrl}/api/account/change-password`, changePasswordModel)

  verifyEmail = (uid: string, key: string) =>
    this.httpClient.post(`${environment.apiUrl}/api/account/email-verification`, { uid, key })

  forgotPassword = (forgotPasswordModel: ForgotPasswordModel) =>
    this.httpClient.post(`${environment.apiUrl}/api/account/send-forgot-password-link`, forgotPasswordModel)

  setPassword = (setPasswordModel: SetPasswordModel) =>
    this.httpClient.post(`${environment.apiUrl}/api/account/set-password`, setPasswordModel)

  getUserHealth = () =>
    this.httpClient.get(`${environment.apiUrl}/api/account/user-health`)


  private processAuthToken(token: string) {
    this.saveUserInfoFromAuthToken(token);
    localStorage.setItem(Constants.authTokenName, token);
  }

  private saveUserInfoFromAuthToken(token: string) {
    const tokenInfo = jwt_decode(token);
    const userInfo = new UserInfo();
    userInfo.id = tokenInfo.nameid;
    userInfo.email = tokenInfo.email;
    userInfo.name = tokenInfo.given_name;
    userInfo.role = tokenInfo.role;
    AppUtils.setCartId(tokenInfo.cart_id);
    this.userInfoSubject.next(userInfo);
  }

  getBuyerProfile = () =>
    this.httpClient.get(`${environment.apiUrl}/api/account/buyer-profile`)

  getContributorProfile = () =>
    this.httpClient.get(`${environment.apiUrl}/api/account/contributor-profile`)

  updateContributorProfile = (model: ContributorProfileEditModel) =>
    this.httpClient.post(`${environment.apiUrl}/api/account/contributor/update-profile`, model)

  externalLoginGoogle = (model: LoginExternalModel) =>
    this.httpClient.post(`${environment.apiUrl}/api/account/external-login-google`, model)
      .pipe(tap((token: string) => this.processAuthToken(token)))

  getContributorSelectItems(filterModel: ImageFilterModel): Observable<Array<SelectListItemModel>> {
    let requestUrl = `${environment.apiUrl}/api/account/contributor/select-items?`;

    if (filterModel.categoryId) {
      requestUrl += `categoryId=${filterModel.categoryId}`;
    }
    if (filterModel.eventId) {
      requestUrl += `eventId=${filterModel.eventId}`;
    }
    if (filterModel.key) {
      requestUrl += `&filterKey=${filterModel.key}`;
    }
    if (filterModel.photographerName) {
      requestUrl += `&photographer=${filterModel.photographerName}`;
    }
    return this.httpClient.get<Array<SelectListItemModel>>(requestUrl);
  }
}
