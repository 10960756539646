import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TableModel } from 'src/app/models/table.model';
import { EventService } from 'src/app/admin/services/event.service';
import { Subscription } from 'rxjs';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PagedPremiumEventImageViewModel } from 'src/app/admin/models/paged.premium.event.images.view.model';

@Component({
    selector: 'app-premium-event-detail',
    templateUrl: './premium.event.detail.component.html',
})

export class PremiumEventDetailComponent implements OnInit, OnDestroy {
    userId: string;
    eventId: number;
    tableModel = new TableModel();
    subscriptions = new Array<Subscription>();
    pagedPremiumEventImage = new PagedPremiumEventImageViewModel();
    totalRecords = 0;
    isModelLoaded = false;

    constructor(private route: ActivatedRoute,
        private ngxSpinnerService: NgxSpinnerService,
        private toastr: ToastrService,
        private eventService: EventService) {
        this.route.queryParams.subscribe(params => {
            this.userId = params['id'];
            this.eventId = params['eventId'];
        });
    }

    ngOnInit() {
        this.initSubscribers();
        this.getImages();
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getImages());
        this.subscriptions.push(subscription);
    }

    viewAllImages() {
        this.tableModel.pageSize = this.totalRecords;
        this.getImages();
    }

    getImages = () => {
        this.isModelLoaded = false;
        this.ngxSpinnerService.show();
        this.eventService.getBuyersImagesDetail(this.userId, this.eventId, this.tableModel)
            .subscribe(data => {
                this.pagedPremiumEventImage = data;
                this.totalRecords = data.totalCount;
                this.pagedPremiumEventImage.items.forEach(image => {
                    if (image.viewedOn) {
                        image.viewedOn = AppUtils.getFormattedDate(image.viewedOn, null);
                    }
                    if (image.downloadedOn) {
                        image.downloadedOn = AppUtils.getFormattedDate(image.downloadedOn, null);
                    }
                });
                this.ngxSpinnerService.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinnerService.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    reloadImages(resetPagination: boolean) {
        if (resetPagination) {
            this.tableModel.page = 1;
        }
        this.getImages();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}
