export class ContributorRegistrationModel {
    firstName: string;
    lastName: string;
    address: string;
    country: string;
    city: string;
    zipCode: string;
    email: string;
    bankName: string;
    accountNumber: string;
    ifscCode: string;
    password: string;
    constructor() {
        this.country = '';
    }
}
