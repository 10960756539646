import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TableModel } from 'src/app/models/table.model';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { PagedContributorDetailModel } from '../models/paged.contributor.detail.model';
import { PagedBuyerDetailModel } from '../models/paged.buyer.detail.model';
import { BuyerEditModel } from '../models/buyer.edit.model';
import { ContributorEditModel } from '../models/contributor.edit.model';
import { PagedContributorEarningDetail } from '../models/paged.contributor.earning.detail';
import { PagedSubscriberDetailModel } from '../models/paged.subscriber.detail.model';
import { BuyerAddModel } from '../models/buyer.add.model';
import { ContributorAddModel } from '../models/contributor.add.model';
import { isNullOrUndefined } from 'util';
import { ContributorEarningFilterModel } from '../models/contributor.earning.filter.model';

@Injectable()
export class UserService {

    constructor(private http: HttpClient) { }

    listContributor(tableModel: TableModel): Observable<PagedContributorDetailModel> {
        let url = `${environment.apiUrl}/api/admin/user/list-contributor`;
        const queryParams = [];
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedContributorDetailModel>(url);
    }

    listPendingContributor(tableModel: TableModel): Observable<PagedContributorDetailModel> {
        let url = `${environment.apiUrl}/api/admin/user/list-pending-contributor`;
        const queryParams = [];
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedContributorDetailModel>(url);
    }

    listBuyers(tableModel: TableModel): Observable<PagedBuyerDetailModel> {
        let url = `${environment.apiUrl}/api/admin/user/list-buyer`;
        const queryParams = [];
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedBuyerDetailModel>(url);
    }

    getBuyer = (id: number) =>
        this.http.get(`${environment.apiUrl}/api/admin/user/buyer/${id}`)

    getBuyersList = () => this.http.get(`${environment.apiUrl}/api/admin/user/buyer-list`);

    addBuyer = (model: BuyerAddModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/user/buyer/add`, model)

    editBuyer = (model: BuyerEditModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/user/buyer/edit`, model)

    deleteBuyer = (id: number) =>
        this.http.post(`${environment.apiUrl}/api/admin/user/buyer/delete/${id}`, {})

    renewSubscription(userId: string) {
        return this.http.post(`${environment.apiUrl}/api/admin/user/renew-subscription/${userId}`, null);
    }

    getContributor = (id: number) =>
        this.http.get(`${environment.apiUrl}/api/admin/user/contributor/${id}`)

    addContributor = (model: ContributorAddModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/user/contributor/add`, model)

    editContributor = (model: ContributorEditModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/user/contributor/edit`, model)

    deleteContributor = (id: number) =>
        this.http.post(`${environment.apiUrl}/api/admin/user/contributor/delete/${id}`, {})

    deletePendingContributor = (id: number) =>
        this.http.post(`${environment.apiUrl}/api/admin/user/pending-contributor/delete/${id}`, {})

    approveContributor = (id: number) =>
        this.http.post(`${environment.apiUrl}/api/admin/user/contributor/approve/${id}`, {})

    getContributorEarning(filterModel: ContributorEarningFilterModel, tableModel: TableModel): Observable<PagedContributorEarningDetail> {
        let url = `${environment.apiUrl}/api/admin/user/contributor-earning`;
        const queryParams = [];
        const tempArr = [];
        if (!isNullOrUndefined(filterModel.contributorId)) {
            tempArr.push(`contributorId=${filterModel.contributorId}`);
        }
        if (!isNullOrUndefined(filterModel.year)) {
            tempArr.push(`year=${filterModel.year}`);
        }
        if (!isNullOrUndefined(filterModel.month)) {
            tempArr.push(`month=${filterModel.month}`);
        }
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&') + '&' + tempArr.join('&');
        }
        return this.http.get<PagedContributorEarningDetail>(url);
    }

    getContributorSelectItems(): Observable<Array<SelectListItemModel>> {
        return this.http.get<Array<SelectListItemModel>>(`${environment.apiUrl}/api/admin/user/contributor/select-items`);
    }

    listSubscribers(tableModel: TableModel): Observable<PagedSubscriberDetailModel> {
        let url = `${environment.apiUrl}/api/admin/user/list-subscriber`;
        const queryParams = [];
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedSubscriberDetailModel>(url);
    }

    exportToExcelContributorEarning(filterModel: any) {
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${environment.apiUrl}/api/admin/user/contributor-earning/export-as-excel`, filterModel, { responseType: 'blob' });
    }

}
