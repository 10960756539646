import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PhotographerProfileService } from 'src/app/photographer/services/profile.service';
import { CountryService } from 'src/app/services/country.service';
import { AppUtils } from 'src/app/helpers/app.utils';
import { Constants } from 'src/app/helpers/constants';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { ContributorProfileEditModel } from 'src/app/photographer/models/contributor.profile.edit.model';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class PhotographerProfileComponent implements OnInit {
    emailPattern = Constants.emailValidationRegEx;
    model = new ContributorProfileEditModel();
    countries = Array<SelectListItemModel>();

    constructor(private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private countryService: CountryService,
        private profileService: PhotographerProfileService
    ) { }

    ngOnInit() {
        this.getCountries();
        this.getContributorProfile();
    }

    getCountries() {
        this.ngxSpinner.show();
        this.countryService.getSelectList()
            .subscribe(response => {
                this.countries = response;
                this.ngxSpinner.hide();
            },
                error => {
                    console.error(error);
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    getContributorProfile() {
        this.ngxSpinner.show();
        this.profileService.getProfile()
            .subscribe(data => {
                Object.assign(this.model, data);
                this.ngxSpinner.hide();
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    submit() {
        this.ngxSpinner.show();
        this.profileService.updateProfile(this.model).subscribe(
            () => {
                this.ngxSpinner.hide();
                setTimeout(() => {
                    this.toastr.success('Your profile has been updated successfully');
                });
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}
