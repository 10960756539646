import { Component, OnInit } from '@angular/core';
import { ImageDetail } from 'src/app/models/image-detail.model';
import { TableModel } from 'src/app/models/table.model';
import { ImageService } from 'src/app/services/image-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/app/helpers/app.utils';

@Component({
  selector: 'app-recent-photos',
  templateUrl: './recent-photos.component.html',
  styleUrls: ['./recent-photos.component.scss']
})
export class RecentPhotosComponent implements OnInit {

  images: ImageDetail[];
  tableModel: TableModel;
  isImagesLoaded = false;

  constructor(private imageService: ImageService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.tableModel = new TableModel();
    this.tableModel.orderBy = 'ApprovedRejectedOn';
    this.tableModel.orderByDirection = 'DESC';
    this.loadImages();
  }

  loadImages() {
    this.spinner.show();
    this.imageService.getImages(this.tableModel).subscribe(pagedList => {
      this.images = pagedList.items;
      this.spinner.hide();
      this.isImagesLoaded = true;
    }, error => {
      this.spinner.hide();
      AppUtils.processErrorResponse(this.toastr, error);
    });
  }
}
