import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CollectionNavItemModel } from 'src/app/models/collection.nav.item.model';
import { CollectionService } from 'src/app/services/collection.service';
import { AppUtils } from 'src/app/helpers/app.utils';

@Component({
    selector: 'app-collection-nav',
    templateUrl: './collection.list.nav.component.html'
})

export class CollectionListNavComponent implements OnInit {
    isNavItemsProcessed = false;
    collections = new Array<any>();
    column1Records = new Array<any>();
    column2Records = new Array<any>();
    column3Records = new Array<any>();
    column4Records = new Array<any>();

    constructor(private toastr: ToastrService,
        private collectionService: CollectionService) { }

    ngOnInit() {
        this.loadCollections();
    }

    loadCollections() {
        this.collectionService.listNavItems()
            .subscribe(
                (response: Array<CollectionNavItemModel>) => {
                    this.processCollections(response);
                },
                error => {
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    processCollections(navItems: Array<CollectionNavItemModel>) {
        if (!navItems || navItems.length === 0) {
            return;
        }

        const parentCategories = [...new Set(navItems.map(x => x.parentCategory))];

        let counter = 0;

        parentCategories.forEach(parentCategory => {
            counter++;

            const columnNavItems = new Array<any>();

            columnNavItems.push({ key: -1, value: parentCategory, isParentCategory: true });

            const categories = [... new Set(navItems.filter(x => x.parentCategory === parentCategory).map(x => x.category))];

            categories.forEach(category => {
                columnNavItems.push({ key: -1, value: category, isParentCategory: false });

                const items = navItems.filter(x => x.parentCategory === parentCategory && x.category === category);

                items.forEach(item => {
                    columnNavItems.push({ key: item.id, value: item.title, isParentCategory: false });
                });
            });

            if (counter === 1) {
                this.column1Records = columnNavItems;
            } else if (counter === 2) {
                this.column2Records = columnNavItems;
            } else if (counter === 3) {
                this.column3Records = columnNavItems;
            } else if (counter === 4) {
                this.column4Records = columnNavItems;
            }
        });
        this.isNavItemsProcessed = true;
    }

    gotoImageFilter(collectionId: number) {
        window.open(`${location.origin}/image/filter?collection=${collectionId}`, '_blank');
    }
}
