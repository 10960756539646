import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AdminImageService } from 'src/app/admin/services/admin-image.service';
import { TableModel } from 'src/app/models/table.model';
import { PagedPendingGroupedModel } from 'src/app/admin/models/paged.pending.grouped.model';

@Component({
    selector: 'app-image-pending-grouped',
    templateUrl: './image.pending.grouped.component.html',
    styleUrls: ['./image.pending.grouped.component.scss']
})
export class ImagePendingGroupedComponent implements OnInit {
    subscriptions = new Array<Subscription>();
    tableModel = new TableModel();
    pagedPendingGroupedDetails = new PagedPendingGroupedModel();
    totalRecords = 0;
    isModelLoaded = false;

    get urls() {
        return PageUrls;
    }

    constructor(private ngxSpinnerService: NgxSpinnerService,
        private toastr: ToastrService,
        private adminImageService: AdminImageService) {
    }

    ngOnInit() {
        this.tableModel = new TableModel();
        this.totalRecords = 0;
        this.subscriptions = [];

        this.initSubscribers();
        this.getImages();

    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getImages());
        this.subscriptions.push(subscription);
    }

    getImages = () => {
        setTimeout(() => {
            this.ngxSpinnerService.show();
        });
        this.adminImageService.pendingGroupedList(this.tableModel)
            .subscribe(data => {
                this.pagedPendingGroupedDetails = data;
                this.totalRecords = data.totalCount;
                this.ngxSpinnerService.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinnerService.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

}
