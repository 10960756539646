import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import { TableModel } from 'src/app/models/table.model';
import { PageUrls } from 'src/app/helpers/page-urls';
import { PagedEventDetails } from 'src/app/admin/models/paged.event.detail';
import { PagedBuyerDetailModel } from 'src/app/admin/models/paged.buyer.detail.model';
import { UserService } from 'src/app/admin/services/user.service';
import { BuyerListModel } from 'src/app/admin/models/buyer.list.model';
import { EventService } from 'src/app/admin/services/event.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SharedBuyerAddModel } from 'src/app/admin/models/shared.buyer.add.model';
import { PremiumEventTableComponent } from '../table-view/premium.event.table.view.component';

@Component({
  selector: 'app-premium-event-manage',
  templateUrl: './premium.event.manage.component.html',
})

export class PremiumEventManageComponent implements OnInit, AfterViewChecked {
  @ViewChild(PremiumEventTableComponent, null) tableView: PremiumEventTableComponent;
  modalRef: NgbModalRef;
  subscriptions = new Array<Subscription>();
  pagedEventDetails = new PagedEventDetails();
  totalRecords = 0;
  tableModel = new TableModel();
  selectedView: number;
  closeResult: string;
  pagedBuyerDetail = new PagedBuyerDetailModel();
  selectedEvents = new Array<number>();
  sharedModel = new SharedBuyerAddModel();
  buyerList = new Array<BuyerListModel>();
  dropdownSettings = {};
  selectedBuyers = [];

  get urls() { return PageUrls; }

  constructor(private route: ActivatedRoute,
    private modalService: NgbModal,
    private userService: UserService,
    private eventService: EventService,
    private toastr: ToastrService,
    private ngxSpinner: NgxSpinnerService) {
    this.tableModel.orderBy = 'Date';
    this.tableModel.orderByDirection = 'desc';

    // filter from query params
    const orderBy = this.route.snapshot.queryParams.orderBy;
    if (!isNullOrUndefined(orderBy)) {
      this.tableModel.orderBy = orderBy;
    }
    const orderByDirection = this.route.snapshot.queryParams.orderByDirection;
    if (!isNullOrUndefined(orderByDirection)) {
      this.tableModel.orderByDirection = orderByDirection;
    }
    const pageSize = this.route.snapshot.queryParams.pageSize;
    if (!isNullOrUndefined(pageSize)) {
      this.tableModel.pageSize = pageSize;
    }
    const filterKey = this.route.snapshot.queryParams.filterKey;
    if (!isNullOrUndefined(filterKey)) {
      this.tableModel.filterKey = filterKey;
    }
    const startDate = this.route.snapshot.queryParams.startDate;
    if (!isNullOrUndefined(startDate)) {
      this.tableModel.startDate = startDate;
    }
    const endDate = this.route.snapshot.queryParams.endDate;
    if (!isNullOrUndefined(endDate)) {
      this.tableModel.endDate = endDate;
    }
    const page = this.route.snapshot.queryParams.page;
    if (!isNullOrUndefined(page)) {
      this.tableModel.page = page;
    }

  }

  ngOnInit() {
    this.loadBuyers();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'email',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
  }

  openShareEventModal(content: any) {
    this.modalRef = this.modalService.open(content);
  }

  ngAfterViewChecked() {
    this.selectedEvents = this.tableView.eventSelectionFilter.selectedItems;
  }

  resetTableModel() {
    this.tableModel.page = 1;
  }

  loadBuyers() {
    this.userService.getBuyersList().subscribe(
      data => {
        Object.assign(this.buyerList, data);
      },
      error => {
        console.log(error);
      });
  }

  makePublic() {
    this.ngxSpinner.show();
    this.eventService.makePublic(this.selectedEvents).subscribe(
      () => {
        setTimeout(() => {
          this.toastr.success('Event(s) has been updated successfully');
        });
        this.ngxSpinner.hide();
        this.tableView.getPremiumEvents();
        this.selectedEvents = new Array<number>();
      },
      error => {
        console.log(error);
      }
    );
  }

  shareEvent() {
    this.selectedEvents.forEach(element => {
      this.sharedModel.eventId.push(element);
    });
    this.selectedBuyers.forEach(element => {
      this.sharedModel.buyerId.push(element.id);
    });
    this.ngxSpinner.show();
    this.eventService.addSharedEvent(this.sharedModel).subscribe(
      () => {
        this.ngxSpinner.hide();
        this.modalRef.close();
        this.tableView.getPremiumEvents();
        this.selectedEvents = new Array<number>();
        setTimeout(() => {
          this.toastr.success('Event has been shared with selected buyers successfully');
        });
      },
      error => {
        console.log(error);
      });
  }
}
