import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-collection-photo-gallery',
    templateUrl: './collection.photo.gallery.component.html',
    styleUrls: ['./collection.photo.gallery.component.scss']
})

export class CollectionPhotoGalleryComponent {
    @Input() photoGalleryMosaicItems: Array<any>;

    get perfectHeight() {
        return Number.parseInt((window.innerWidth > 1900 ? 350 : 300).toString(), 10);
    }

    widthGetterFn = (image: any) => image.realWidth;
    heightGetterFn = (image: any) => image.realHeight;
    lowSizePreviewGetter = (image: any) => image.lowSizePreview;

    imageSelected(item: any) {
        window.open(`${location.origin}/image/filter?collection=${item.identifier}`, '_blank');
    }
}
