import { SelectListItemModel } from 'src/app/models/select.list.item.model';

export class CollectionEditModel {
    public id: number;
    public categoryId: number;
    public title: string;
    public description: string;
    public eventIdList: Array<number>;
    public imageIdList: Array<number>;
    public selectedAllImages: boolean;
    public eventSelectListItems: Array<SelectListItemModel>;
    public coverImageId: string;
    public createWith: string;

    constructor() {
        this.selectedAllImages = false;
        this.createWith = 'events';
        this.eventIdList = new Array<number>();
        this.imageIdList = new Array<number>();
    }
}
