import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ImagePurchaseValidationModel } from 'src/app/buyer/models/image.purchase.validation.model';

@Injectable()
export class BuyerImageService {

    constructor(private http: HttpClient) { }

    validatePurchase(imageId: number, imageSize: number) {
        return this.http.post<ImagePurchaseValidationModel>(
            `${environment.apiUrl}/api/user/image/validate-purchase`, { imageId, imageSize });
    }

}
