export class EventAddModel {
    public parentId: string;
    public categoryId: number;
    public title: string;
    public date: string;
    public description: string;
    public isPremium: boolean;
    public isSpecial: boolean;

    public photographerId: string;
    public priceForSmall: string;
    public priceForMedium: string;
    public priceForLarge: string;

    constructor() {
        this.parentId = '';
        this.photographerId = '';
    }
}
