import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BuyerProfileModel } from 'src/app/buyer/models/buyer.profile.model';

@Injectable()
export class BuyerProfileService {

  constructor(private http: HttpClient) { }

  getProfile = (): Observable<BuyerProfileModel> =>
    this.http.get<BuyerProfileModel>(`${environment.apiUrl}/api/user/profile`)


  updateProfile = (model: BuyerProfileModel) =>
    this.http.post(`${environment.apiUrl}/api/user/profile`, model)

  checkForSubscription(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/api/user/profile/has-active-subscription`);
  }
}
