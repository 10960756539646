import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UserInfo } from 'src/app/models/user-info';
import { UserHealthModel } from 'src/app/models/user.health.model';
import { Constants } from 'src/app/helpers/constants';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { ListenerService } from 'src/app/services/listener.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy {

  registrationUrl: string;
  loginUrl: string;
  userInfo: UserInfo;
  isLoggedInUserAdmin = false;
  isLoggedInUserPhotographer = false;
  isLoggedInUserBuyer = false;
  isAdminLoginPage = false;
  isPhotographerLoginPage = false;
  totalCartItem = '';
  sidebarFlag = false;
  subscriptions = new Array<Subscription>();

  get isLoggedIn() {
    return !!this.userInfo;
  }

  get urls() {
    return PageUrls;
  }

  constructor(private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private cartService: CartService,
    private listenerService: ListenerService) {

    const closeSidebarSubscription = this.listenerService
      .closeSidebar
      .subscribe(() => {
        this.closeSidebar();
      });

    this.subscriptions.push(closeSidebarSubscription);

    const refreshCartListnerSubscription = this.listenerService
      .refreshCart
      .subscribe(() => {
        this.getCartItemCount();
      });

    this.subscriptions.push(refreshCartListnerSubscription);

    const routerSubscription = this.router.events
      .subscribe((e: NavigationEnd) => {
        if (e instanceof NavigationEnd) {
          this.setLoginUrl();
        }
      });

    this.subscriptions.push(routerSubscription);
  }

  ngOnInit() {
    this.checkUserHealth();
    this.initSubscriptions();
    this.getCartItemCount();
    this.setLoginUrl();
  }

  initSubscriptions() {
    const subscription = this.authService.userInfo$.subscribe(userInfo => {
      this.userInfo = userInfo;
      this.isLoggedInUserAdmin = this.authService.isLoggedInUserInRole(Constants.Roles.Admin);
      this.isLoggedInUserPhotographer = this.authService.isLoggedInUserInRole(Constants.Roles.Photographer);
      this.isLoggedInUserBuyer = this.authService.isLoggedInUserInRole(Constants.Roles.Buyer);
    });
    this.subscriptions.push(subscription);
  }

  checkUserHealth() {
    if (!this.authService.isUserLoggedIn()) {
      return;
    }
    this.authService.getUserHealth()
      .subscribe(
        (data: UserHealthModel) => {
          if (data.recordStatus !== 1) {
            this.authService.logout();
            this.getCartItemCount();
            this.router.navigateByUrl(PageUrls.ContactUs);
          }
          setTimeout(() => {
            if (data.recordStatus === 2) {
              this.toastr.error('Your account is inactive. Please contact to administrator');
            } else if (data.recordStatus === 3) {
              this.toastr.error('Your account is deleted. Please contact to administrator');
            }
          });
        },
        error => {
          console.log(error);
        });
  }

  getCartItemCount() {
    this.cartService.cartItemCount().subscribe(
      data => {
        this.totalCartItem = data.toString();
      },
      error => {
        console.error(error);
      });
  }

  setLoginUrl() {

    this.isAdminLoginPage = window.location.href.indexOf('admin') !== -1;
    this.isPhotographerLoginPage = window.location.href.indexOf('photographer') !== -1;

    if (this.isAdminLoginPage) {
      this.loginUrl = PageUrls.Admin.Login;
    } else if (this.isPhotographerLoginPage) {
      this.registrationUrl = PageUrls.PhotographerSignUp;
      this.loginUrl = PageUrls.Photographer.Login;
    } else {
      this.registrationUrl = PageUrls.Registration;
      this.loginUrl = PageUrls.Login;
    }
  }

  openSidebar() {
    this.sidebarFlag = true;
  }

  closeSidebar() {
    this.sidebarFlag = false;
  }

  logout() {
    this.authService.logout();
    this.getCartItemCount();
    this.router.navigateByUrl('/');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
