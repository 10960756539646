import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventAddModel } from '../models/event.add.model';
import { EventAddImageMultiple } from '../models/event.add.image.multiple.model';
import { TableModel } from 'src/app/models/table.model';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { isNullOrUndefined } from 'util';
import { EventRemoveImageMultiple } from '../models/event.remove.image.multiple.model';
import { EventSetCoverImageModel } from '../models/event.set.cover.image.model';
import { PagedEventImageDetails } from '../models/paged.event.image.detail';
import { EventEditModel } from '../models/event.edit.model';
import { PagedEventDetails } from '../models/paged.event.detail';
import { ParentEventListItemModel } from 'src/app/models/parent.event.list.item.model';
import { PagedEventCalenderViewDetails } from '../models/event.calender.view.detail.model';
import { SharedBuyerAddModel } from '../models/shared.buyer.add.model';
import { PagedPremiumEventImageViewModel } from '../models/paged.premium.event.images.view.model';
import { PagedPremiumEventHistoryModel } from '../models/paged.premium.event.history.detail.model';

@Injectable()
export class EventService {

    constructor(private http: HttpClient) { }

    add = (model: EventAddModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/event/add`, model)

    edit = (model: EventEditModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/event/edit`, model)

    addImageMultiple = (model: EventAddImageMultiple) =>
        this.http.post(`${environment.apiUrl}/api/admin/event/add-image-multiple`, model)

    list(tableModel: TableModel): Observable<PagedEventDetails> {
        let url = `${environment.apiUrl}/api/admin/event/list`;
        const queryParams = [];
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedEventDetails>(url);
    }

    premiumEventslist(tableModel: TableModel): Observable<PagedEventDetails> {
        let url = `${environment.apiUrl}/api/admin/event/list-premium`;
        const queryParams = [];
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedEventDetails>(url);
    }

    getEventImageList(id: number, tableModel: TableModel): Observable<PagedEventImageDetails> {
        let url = `${environment.apiUrl}/api/admin/event/image-list`;
        const queryParams = [];
        if (!isNullOrUndefined(id)) {
            queryParams.push(`id=${id}`);
        }
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedEventImageDetails>(url);
    }

    get = (id: number) =>
        this.http.get(`${environment.apiUrl}/api/admin/event/${id}`)

    getSelectItems = () =>
        this.http.get<Array<SelectListItemModel>>(`${environment.apiUrl}/api/admin/event/select-items`)

    getParentEventSelectItems = () =>
        this.http.get<Array<ParentEventListItemModel>>(`${environment.apiUrl}/api/admin/event/parent-event-select-items`)

    getTreeViewItems = (categoryId: number) =>
        this.http.get<Array<any>>(`${environment.apiUrl}/api/admin/event/list-tree-view-items?categoryId=${categoryId}`)

    delete = (id: number) =>
        this.http.post(`${environment.apiUrl}/api/admin/event/delete/${id}`, {})

    removeImageMultiple = (model: EventRemoveImageMultiple) =>
        this.http.post(`${environment.apiUrl}/api/admin/event/remove-image-multiple`, model)

    setCoverImage = (model: EventSetCoverImageModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/event/set-cover-image`, model)

    yearList(tableModel: TableModel): Observable<PagedEventCalenderViewDetails> {
        let url = `${environment.apiUrl}/api/admin/event/year-select-items`;
        const queryParams = [];
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedEventCalenderViewDetails>(url);
    }

    monthList(year: number): Observable<PagedEventCalenderViewDetails> {
        const url = `${environment.apiUrl}/api/admin/event/month-select-items/${year}`;
        return this.http.get<PagedEventCalenderViewDetails>(url);
    }

    addSharedEvent = (model: SharedBuyerAddModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/event/add-shared-event`, model)

    getListEventSharedWith = (id: number) =>
        this.http.get(`${environment.apiUrl}/api/admin/event/list-shared-with/${id}`)

    makePublic = (model: Array<number>) =>
        this.http.post(`${environment.apiUrl}/api/admin/event/make-public`, model)


    getBuyersImagesDetail(userId: string, eventId: number, tableModel: TableModel): Observable<PagedPremiumEventImageViewModel> {
        let url = `${environment.apiUrl}/api/admin/event/buyer-image-detail-list`;
        const queryParams = [];
        if (!isNullOrUndefined(userId)) {
            queryParams.push(`userId=${userId}`);
        }
        if (!isNullOrUndefined(eventId)) {
            queryParams.push(`eventId=${eventId}`);
        }
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedPremiumEventImageViewModel>(url);
    }

    deleteSharedEvent(eventId: number, userId: string) {
        return this.http.post(`${environment.apiUrl}/api/admin/event/delete-shared-event/${eventId}/${userId}`, null);
    }

    getPremiumEventHistory(tableModel: TableModel): Observable<PagedPremiumEventHistoryModel> {
        let url = `${environment.apiUrl}/api/admin/event/premium-event-history`;
        const queryParams = [];
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedPremiumEventHistoryModel>(url);
    }

    getSharedEventBuyersSelectItems(): Observable<Array<SelectListItemModel>> {
        return this.http.get<Array<SelectListItemModel>>(`${environment.apiUrl}/api/admin/event/shared-event-buyers-select-items`);
    }
}
