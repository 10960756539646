import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TableModel } from 'src/app/models/table.model';
import { PagedImageDetails } from '../models/PagedImageDetails';
import { SubscriptionPlanAddModel } from '../models/subscription.plan.add.model';
import { SubscriptionPlanEditModel } from '../models/subscription.plan.edit.model';
import { PagedSubscriptionPlanDetails } from '../models/paged.subscription.plan.detail.model';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';

@Injectable()
export class SubscriptionPlanService {

    constructor(private http: HttpClient) { }

    add = (model: SubscriptionPlanAddModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/subscriptionPlan/add`, model)

    edit = (model: SubscriptionPlanEditModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/subscriptionPlan/edit`, model)

    list(tableModel: TableModel): Observable<PagedSubscriptionPlanDetails> {
        let url = `${environment.apiUrl}/api/admin/subscriptionPlan/list`;
        const queryParams = [];
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedSubscriptionPlanDetails>(url);
    }

    get = (id: number) =>
        this.http.get(`${environment.apiUrl}/api/admin/subscriptionPlan/${id}`)

    getSelectList(): Observable<Array<SelectListItemModel>> {
        return this.http.get<Array<SelectListItemModel>>(`${environment.apiUrl}/api/admin/subscriptionPlan/select-items`);
    }

    toggle = (id: number) =>
        this.http.post(`${environment.apiUrl}/api/admin/subscriptionPlan/toggle/${id}`, {})

    delete = (id: number) =>
        this.http.post(`${environment.apiUrl}/api/admin/subscriptionPlan/delete/${id}`, {})
}
