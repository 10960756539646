import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from 'src/app/helpers/constants';
import { AppUtils } from 'src/app/helpers/app.utils';
import { ContactUsModel } from 'src/app/models/contact.us.model';
import { ContactUsService } from 'src/app/services/contactus.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  emailPattern = Constants.emailValidationRegEx;
  model = new ContactUsModel();

  constructor(private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private contactUsService: ContactUsService) { }

  submit(form: NgForm) {
    this.spinner.show();
    this.contactUsService.post(this.model)
      .subscribe(
        () => {
          this.spinner.hide();
          this.toastr.success('Thanks for sending the message');
          form.resetForm();
          this.model = new ContactUsModel();
        },
        error => {
          this.spinner.hide();
          AppUtils.processErrorResponse(this.toastr, error);
        });
  }
}
