import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AlbumService } from 'src/app/services/album.service';
import { SubcategoryAddModel } from 'src/app/admin/models/subcategory.add.model';
import { AlbumModel } from 'src/app/models/album.model';

@Component({
    selector: 'app-subcategory-add',
    templateUrl: './subcategory.add.component.html'
})

export class SubcategoryAddComponent implements OnInit {
    imageCategories = new Array<AlbumModel>();
    model = new SubcategoryAddModel();

    constructor(private router: Router,
        private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private albumService: AlbumService) { }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
        this.loadCategories();
    }

    loadCategories() {
        this.ngxSpinner.show();
        this.albumService.getAlbumList().subscribe(
            (categories) => {
                this.ngxSpinner.hide();
                this.imageCategories = categories;
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    submit() {
        this.ngxSpinner.show();
        this.albumService.addSubCategory(this.model).subscribe(
            (data) => {
                this.ngxSpinner.hide();
                this.router.navigateByUrl(`${PageUrls.Admin.SubcategoryManage}`);
                setTimeout(() => {
                    this.toastr.success('Subcategory has been added successfully');
                });
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}


