import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { Constants } from '../helpers/constants';
import { Injectable } from '@angular/core';
import { PageUrls } from '../helpers/page-urls';

@Injectable()
export class AnonymousGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const token = localStorage.getItem(Constants.authTokenName);
        if (!token) {
            return true;
        }

        const tokenInfo = jwt_decode(token);
        if (tokenInfo.exp <= (new Date().getTime() / 1000)) {
            return true;
        }

        this.router.navigateByUrl(PageUrls.Dashboard);

        return false;
    }
}
