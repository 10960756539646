export class CollectionAddModel {
    public categoryId: number;
    public title: string;
    public description: string;
    public eventIdList: Array<number>;
    public imageIdList: Array<number>;
    public selectedAllImages: boolean;
    public coverImageId: string;
    public createWith: string;

    constructor() {
        this.selectedAllImages = false;
        this.createWith = 'events';
        this.eventIdList = new Array<number>();
        this.imageIdList = new Array<number>();
    }
}
