import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AppUtils } from 'src/app/helpers/app.utils';
import { AlbumService } from 'src/app/services/album.service';
import { SubscriptionPlanEditModel } from 'src/app/admin/models/subscription.plan.edit.model';
import { ImageTypeModel } from 'src/app/models/image.type.model';
import { SubscriptionPlanService } from 'src/app/admin/services/subscription.plan.service';

@Component({
    selector: 'app-subscription-plan-edit',
    templateUrl: './subscription.plan.edit.component.html'
})
export class SubscriptionPlanEditComponent implements OnInit {
    model = new SubscriptionPlanEditModel();
    imageSizes: Array<ImageTypeModel>;

    constructor(private router: Router,
        private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private albumService: AlbumService,
        private subscriptionPlanService: SubscriptionPlanService) {
    }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
        this.model.id = this.route.snapshot.queryParams.subscriptionPlanId;
        this.imageSizes = AppUtils.getImageTypes();
        this.getSubscriptionPlan();
    }

    getSubscriptionPlan() {
        this.ngxSpinner.show();
        this.subscriptionPlanService.get(this.model.id).subscribe(
            data => {
                this.ngxSpinner.hide();
                Object.assign(this.model, data);
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    isPremiumChanged() {
        if (!this.model.isPremium) {
            this.model.totalSpecialSmallImages = 0;
            this.model.totalSpecialMediumImages = 0;
            this.model.totalSpecialLargeImages = 0;
        }
    }

    submit() {
        if (this.model.price === 0) {
            this.toastr.error('Price must be greater that zero.');
            return;
        }
        this.ngxSpinner.show();
        this.subscriptionPlanService.edit(this.model).subscribe(
            () => {
                this.ngxSpinner.hide();
                this.router.navigateByUrl(`${PageUrls.Admin.SubscriptionPlanManage}`);
                setTimeout(() => {
                    this.toastr.success('Subscription plan has been updated successfully');
                });
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}
