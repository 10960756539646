import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BuyerProfileModel } from 'src/app/buyer/models/buyer.profile.model';
import { BuyerProfileService } from 'src/app/buyer/services/profile.service';
import { AppUtils } from 'src/app/helpers/app.utils';
import { Constants } from 'src/app/helpers/constants';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { CountryService } from 'src/app/services/country.service';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    emailPattern = Constants.emailValidationRegEx;

    model = new BuyerProfileModel();

    countries = Array<SelectListItemModel>();

    constructor(private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService, private countryService: CountryService,
        private profileService: BuyerProfileService) { }

    ngOnInit() {
        this.getCountries();
        this.getBuyerProfile();
    }

    getCountries() {
        this.ngxSpinner.show();
        this.countryService.getSelectList()
            .subscribe(response => {
                this.countries = response;
                this.ngxSpinner.hide();
            },
                error => {
                    console.error(error);
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    getBuyerProfile() {
        this.ngxSpinner.show();
        this.profileService.getProfile()
            .subscribe(data => {
                Object.assign(this.model, data);
                this.ngxSpinner.hide();
                if (isNullOrUndefined(this.model.country)) {
                    this.model.country = '';
                }
                this.model.subscriptionStartDate = AppUtils.getFormattedDate(this.model.subscriptionStartDate);
                this.model.subscriptionEndDate = AppUtils.getFormattedDate(this.model.subscriptionEndDate);
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    submit() {
        this.ngxSpinner.show();
        this.profileService.updateProfile(this.model).subscribe(
            () => {
                this.ngxSpinner.hide();
                setTimeout(() => {
                    this.toastr.success('Your profile has been updated successfully');
                });
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}
