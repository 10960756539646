import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PagedSearchedKeywordManageModel } from 'src/app/admin/models/paged.searched.keyword.manage.model';
import { SearchedKeywordService } from 'src/app/admin/services/searched.keyword.service';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { TableModel } from 'src/app/models/table.model';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'app-searched-keyword-manage',
    templateUrl: './searched.keyword.manage.component.html',
    styleUrls: ['./searched.keyword.manage.component.scss']
})
export class SearchedKeywordManageComponent implements OnInit {
    subscriptions = new Array<Subscription>();
    pagedSearchedKeywordDetails = new PagedSearchedKeywordManageModel();
    totalRecords = 0;
    tableModel = new TableModel();
    isModelLoaded = false;

    get urls() {
        return PageUrls;
    }

    constructor(private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private router: Router,
        private searchKeywordService: SearchedKeywordService,
        private route: ActivatedRoute) {

        // filter from query params
        const orderBy = this.route.snapshot.queryParams.orderBy;
        if (!isNullOrUndefined(orderBy)) {
            this.tableModel.orderBy = orderBy;
        }
        const orderByDirection = this.route.snapshot.queryParams.orderByDirection;
        if (!isNullOrUndefined(orderByDirection)) {
            this.tableModel.orderByDirection = orderByDirection;
        }
        const pageSize = this.route.snapshot.queryParams.pageSize;
        if (!isNullOrUndefined(pageSize)) {
            this.tableModel.pageSize = pageSize;
        }
        const filterKey = this.route.snapshot.queryParams.filterKey;
        if (!isNullOrUndefined(filterKey)) {
            this.tableModel.filterKey = filterKey;
        }
        const startDate = this.route.snapshot.queryParams.startDate;
        if (!isNullOrUndefined(startDate)) {
            this.tableModel.startDate = startDate;
        }
        const endDate = this.route.snapshot.queryParams.endDate;
        if (!isNullOrUndefined(endDate)) {
            this.tableModel.endDate = endDate;
        }
        const page = this.route.snapshot.queryParams.page;
        if (!isNullOrUndefined(page)) {
            this.tableModel.page = page;
        }
    }

    ngOnInit() {
        this.initSubscribers();
        this.getSearchKeywords();
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getSearchKeywords());
        this.subscriptions.push(subscription);
    }

    getSearchKeywords = () => {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.searchKeywordService.list(this.tableModel)
            .subscribe(data => {
                Object.assign(this.pagedSearchedKeywordDetails, data);
                this.totalRecords = data.totalCount;
                this.tableModel.totalPages = data.totalPages;
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    openDetail(keyword: string) {
        this.router.navigateByUrl(`${PageUrls.Admin.SearchedKeywordDetail}?keyword=${keyword}&${this.tableModel.toQueryString()}`);
    }
}
