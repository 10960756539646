import { ToastrService } from 'ngx-toastr';
import { ImageTypeModel } from '../models/image.type.model';
import { isNullOrUndefined } from 'util';
import { Guid } from 'guid-typescript';
import { Constants } from './constants';
import * as moment from 'moment';
import { SelectListItemModel } from '../models/select.list.item.model';


export class AppUtils {
    public static processErrorResponse(toastr: ToastrService, response: any, customErrorMessage = '') {
        const error = response.error;
        if (response.status === 400) {
            if (error instanceof Array) {
                error.forEach((item) => {
                    toastr.error(item);
                });
            } else {
                toastr.error(error);
            }
        } else if (response.status === 401) {
            toastr.error('Please login to perform this action.');
        } else if (response.status === 403) {
            toastr.error('You are not allowed to perform this operation.');
        } else if (response.status === 404) {
            toastr.error('Something went wrong. Please try refreshing the page.');
        } else {
            if (customErrorMessage === '') {
                toastr.error('Internal server error.');
            } else {
                toastr.error(customErrorMessage);
            }
        }
    }

    public static getImageTypes() {
        const imageTypes = new Array<ImageTypeModel>();
        imageTypes.push(new ImageTypeModel('1', 'Small'));
        imageTypes.push(new ImageTypeModel('2', 'Medium'));
        imageTypes.push(new ImageTypeModel('3', 'Large'));
        return imageTypes;
    }

    public static getCartId() {
        let cartId = localStorage.getItem(Constants.cartId);

        if (isNullOrUndefined(cartId)) {
            cartId = Guid.create().toString();
            this.setCartId(cartId);
        }

        return cartId;
    }

    public static setCartId(cartId: string) {
        if (!isNullOrUndefined(cartId) && cartId !== Guid.EMPTY) {
            localStorage.setItem(Constants.cartId, cartId);
        }
    }

    public static isValidDate(date: any) {
        return moment(date).isValid();
    }

    public static getMonthName(month: any) {
        return moment(month, 'M').format('MMMM');
    }

    public static getStartDate(month: number, year: number) {
        const startDate = moment([year, month - 1, 1]).format('MM/DD/YYYY');
        return startDate;
    }

    public static getEndDate(month: number, year: number) {
        const startDate = moment([year, month - 1, 1]);
        const endDate = startDate.endOf('month').format('MM/DD/YYYY');
        return endDate;
    }

    public static getCurrentNgDate() {
        const m = moment();
        return { year: m.year(), month: (m.month() + 1), day: m.date() };
    }

    public static getValidityEndDateNgDate() {
        const m = moment().add(30, 'd');
        return { year: m.year(), month: (m.month() + 1), day: m.date() };
    }

    public static getFormattedDate(date: any, format: string = '') {
        if (!date) {
            return date;
        }
        if (!format) {
            format = 'MM/DD/YYYY';
        }
        if (typeof (date) === 'object' && date.month) {
            date.month -= 1;
        }
        return date ? moment(date).format(format) : null;
    }

    public static getNgDatePickerDate(date: string): any {
        const m = date ? moment(date) : moment();
        return { year: m.year(), month: (m.month() + 1), day: m.date() };
    }

    public static convertToCmsFromPixels(pixels: number) {
        return (pixels * 0.0264583333).toFixed(2);
    }

    public static convertToMegaPixelsFromResolution(width: number, height: number) {
        return ((width * height) / 1000000).toFixed(2);
    }

    public static compareNgDates(firstDate: any, secondDate: any) {
        const f = firstDate ? moment(firstDate) : moment();
        const s = secondDate ? moment(secondDate) : moment();
        return f.diff(s);
    }

    public static ScrollToTop(top: number) {
        if (!top) { top = 0; }
        window.scrollTo({
            top: top,
            behavior: 'smooth',
        });
    }

    public static getYears() {
        const startYear = 1990;
        const endYear = moment().year();
        return Array.from(Array(endYear - startYear).keys()).map(i => startYear + i + 1);
    }

    public static getMonths(year: number) {
        if (year == moment().year()) {
            const endMonth = moment().format('MMM');
            console.log(endMonth);
            let endMonthIndex = moment.monthsShort().indexOf(endMonth);
            console.log(endMonthIndex);
            return moment.monthsShort().splice(0,endMonthIndex + 1);
        } else {
            return moment.monthsShort();
        }
    }
}
