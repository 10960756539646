import { ContributorDetailModel } from './contributor.detail.model';

export class PagedContributorDetailModel {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    items: Array<ContributorDetailModel>;

    constructor() {
        this.items = new Array<ContributorDetailModel>();
    }
}
