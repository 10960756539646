import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { UserService } from 'src/app/admin/services/user.service';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { CountryService } from 'src/app/services/country.service';
import { BuyerAddModel } from 'src/app/admin/models/buyer.add.model';
import { Constants } from 'src/app/helpers/constants';
import { SubscriptionPlanService } from 'src/app/admin/services/subscription.plan.service';

@Component({
    selector: 'app-user-add-buyer',
    templateUrl: './user.add.buyer.component.html'
})
export class UserAddBuyerComponent implements OnInit {
    isModelLoaded = false;
    emailPattern: string;
    model: BuyerAddModel = new BuyerAddModel();
    confirmPassword: string;
    countries = Array<SelectListItemModel>();
    subscriptionPlans = Array<SelectListItemModel>();

    minStartDate = { year: 0, month: 0, day: 0 };
    passwordPattern: string;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private ngxSpinner: NgxSpinnerService,
        private countryService: CountryService,
        private subscriptionService: SubscriptionPlanService,
        private toastr: ToastrService,
        private userService: UserService) {
    }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
        this.emailPattern = Constants.emailValidationRegEx;
        this.passwordPattern = Constants.passwordRegEx;
        this.getCountries();
        this.getSubscriptionPlans();
    }

    getCountries() {
        this.ngxSpinner.show();
        this.countryService.getSelectList()
            .subscribe(response => {
                this.countries = response;
                this.ngxSpinner.hide();
            },
                error => {
                    console.error(error);
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    getSubscriptionPlans() {
        this.ngxSpinner.show();
        this.subscriptionService.getSelectList()
            .subscribe(response => {
                this.subscriptionPlans = response;
                this.ngxSpinner.hide();
            },
                error => {
                    console.error(error);
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    setMinEndDate() {
        this.minStartDate.year = this.model.subscriptionStartDateObject.year;
        this.minStartDate.month = this.model.subscriptionStartDateObject.month;
        this.minStartDate.day = this.model.subscriptionStartDateObject.day;
        console.log(AppUtils.compareNgDates(this.model.subscriptionStartDateObject, this.model.subscriptionEndDateObject));
        if (AppUtils.compareNgDates(this.model.subscriptionStartDateObject, this.model.subscriptionEndDateObject) > 0) {
            this.model.subscriptionEndDateObject = null;
        }
    }

    setDefaultStartEndDate() {
        if (isNullOrUndefined(this.model.subscriptionPlanId) || this.model.subscriptionPlanId === '') {
            this.model.subscriptionStartDateObject = null;
            this.model.subscriptionEndDateObject = null;
            this.minStartDate.year = 1;
            this.minStartDate.month = 1;
            this.minStartDate.day = 1;
            return;
        }
        this.model.subscriptionStartDateObject = AppUtils.getCurrentNgDate();
        this.minStartDate.year = this.model.subscriptionStartDateObject.year;
        this.minStartDate.month = this.model.subscriptionStartDateObject.month;
        this.minStartDate.day = this.model.subscriptionStartDateObject.day;
        this.model.subscriptionEndDateObject = AppUtils.getValidityEndDateNgDate();
    }

    submit() {
        this.ngxSpinner.show();
        this.model.subscriptionStartDate = AppUtils.getFormattedDate(this.model.subscriptionStartDateObject);
        this.model.subscriptionEndDate = AppUtils.getFormattedDate(this.model.subscriptionEndDateObject);
        this.userService.addBuyer(this.model).subscribe(
            () => {
                this.ngxSpinner.hide();
                this.router.navigateByUrl(`${PageUrls.Admin.UserBuyer}`);
                setTimeout(() => {
                    this.toastr.success('Buyer has been added successfully');
                });
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}
