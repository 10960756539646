import { ContributorEarningModel } from './contributor.earning.model';

export class PagedContributorEarningDetail {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    items: Array<ContributorEarningModel>;

    constructor() {
        this.items = new Array<ContributorEarningModel>();
    }
}
