import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/admin/services/user.service';
import { BuyerEditModel } from 'src/app/admin/models/buyer.edit.model';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { CountryService } from 'src/app/services/country.service';
import { isNullOrUndefined } from 'util';
import { SubscriptionPlanService } from 'src/app/admin/services/subscription.plan.service';

@Component({
    selector: 'app-user-edit-buyer',
    templateUrl: './user.edit.buyer.component.html'
})
export class UserEditBuyerComponent implements OnInit {
    isModelLoaded = false;
    emailPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
    model: BuyerEditModel = new BuyerEditModel();
    countries = Array<SelectListItemModel>();
    subscriptionPlans = Array<SelectListItemModel>();
    prevSubscriptionPlanId = '';
    prevSubscriptionStartDate = '';
    prevSubscriptionEndDate = '';


    minStartDate = { year: 0, month: 0, day: 0 };

    constructor(private router: Router,
        private route: ActivatedRoute,
        private ngxSpinner: NgxSpinnerService,
        private countryService: CountryService,
        private subscriptionService: SubscriptionPlanService,
        private toastr: ToastrService,
        private userService: UserService) {
    }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
        this.model.id = this.route.snapshot.queryParams.buyerId;
        this.getCountries();
        this.getSubscriptionPlans();
        this.getBuyer();
    }

    getCountries() {
        this.ngxSpinner.show();
        this.countryService.getSelectList()
            .subscribe(response => {
                this.countries = response;
                this.ngxSpinner.hide();
            },
                error => {
                    console.error(error);
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    getSubscriptionPlans() {
        this.ngxSpinner.show();
        this.subscriptionService.getSelectList()
            .subscribe(response => {
                this.subscriptionPlans = response;
                this.ngxSpinner.hide();
            },
                error => {
                    console.error(error);
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    getBuyer() {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.userService.getBuyer(this.model.id)
            .subscribe(data => {
                Object.assign(this.model, data);
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
                if (isNullOrUndefined(this.model.country)) {
                    this.model.country = '';
                }
                if (isNullOrUndefined(this.model.subscriptionPlanId)) {
                    this.model.subscriptionPlanId = '';
                } else {
                    this.prevSubscriptionPlanId = this.model.subscriptionPlanId;
                }
                if (!isNullOrUndefined(this.model.subscriptionEndDate)) {
                    this.model.subscriptionEndDateObject = AppUtils.getNgDatePickerDate(this.model.subscriptionEndDate);
                    this.prevSubscriptionEndDate = this.model.subscriptionEndDate;
                }
                if (!isNullOrUndefined(this.model.subscriptionStartDate)) {
                    this.model.subscriptionStartDateObject = AppUtils.getNgDatePickerDate(this.model.subscriptionStartDate);
                    this.prevSubscriptionStartDate = this.model.subscriptionStartDate;
                    this.setMinEndDate();
                }
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    setMinEndDate() {
        this.minStartDate.year = this.model.subscriptionStartDateObject.year;
        this.minStartDate.month = this.model.subscriptionStartDateObject.month;
        this.minStartDate.day = this.model.subscriptionStartDateObject.day;
        console.log(AppUtils.compareNgDates(this.model.subscriptionStartDateObject, this.model.subscriptionEndDateObject));
        if (AppUtils.compareNgDates(this.model.subscriptionStartDateObject, this.model.subscriptionEndDateObject) > 0) {
            this.model.subscriptionEndDateObject = null;
        }
    }

    setDefaultStartEndDate() {
        if (isNullOrUndefined(this.model.subscriptionPlanId) || this.model.subscriptionPlanId === '') {
            this.model.subscriptionStartDateObject = null;
            this.model.subscriptionEndDateObject = null;
            this.minStartDate.year = 1;
            this.minStartDate.month = 1;
            this.minStartDate.day = 1;
            return;
        }
        if (this.model.subscriptionPlanId === this.prevSubscriptionPlanId) {
            this.model.subscriptionStartDateObject = AppUtils.getNgDatePickerDate(this.prevSubscriptionStartDate);
            this.setMinEndDate();
            console.log(this.prevSubscriptionEndDate);
            this.model.subscriptionEndDateObject = AppUtils.getNgDatePickerDate(this.prevSubscriptionEndDate);
            console.log(this.model.subscriptionEndDate);
            return;
        }
        this.model.subscriptionStartDateObject = AppUtils.getCurrentNgDate();
        this.minStartDate.year = this.model.subscriptionStartDateObject.year;
        this.minStartDate.month = this.model.subscriptionStartDateObject.month;
        this.minStartDate.day = this.model.subscriptionStartDateObject.day;
        this.model.subscriptionEndDateObject = AppUtils.getValidityEndDateNgDate();
    }

    submit() {
        this.ngxSpinner.show();
        this.model.subscriptionStartDate = AppUtils.getFormattedDate(this.model.subscriptionStartDateObject);
        this.model.subscriptionEndDate = AppUtils.getFormattedDate(this.model.subscriptionEndDateObject);
        this.userService.editBuyer(this.model).subscribe(
            () => {
                this.ngxSpinner.hide();
                this.router.navigateByUrl(`${PageUrls.Admin.UserBuyer}`);
                setTimeout(() => {
                    this.toastr.success('Buyer has been updated successfully');
                });
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }
}
