export class ContributorAddModel {
    public firstName: number;
    public lastName: string;
    public email: string;
    public address: string;
    public city: string;
    public country: string;
    public zipCode: string;
    public bankName: string;
    public accountNumber: string;
    public ifsc: string;
    public uniqueNumber: string;
    public password: string;
    public cpassword: string;
    public commission: number;
    constructor() {
        this.country = '';
    }
}
