import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { ImageApproveMultipleModel } from 'src/app/admin/models/image.approve.multiple.model';
import { AdminImageService } from 'src/app/admin/services/admin-image.service';

@Component({
    selector: 'app-image-approve-multiple',
    templateUrl: './image.approve.multiple.component.html'
})

export class ImageApproveMultipleComponent {
    @ViewChild('imagePriceModal', { static: false }) imagePriceModal: any;
    @Input() imageIdList: Array<number>;
    @Output() reloadImages = new EventEmitter();
    modalReference: NgbModalRef;
    model = new ImageApproveMultipleModel();
    approvalErrors = new Array<SelectListItemModel>();
    pbMaxVal = 0;
    pbCurrentVal = 0;
    isApproving = false;

    constructor(private modalService: NgbModal,
        private toastr: ToastrService,
        private adminImageService: AdminImageService) { }

    openImagePriceModal() {
        this.modalReference = this.modalService.open(this.imagePriceModal);
        console.log(this.imageIdList);
    }

    closeImagePriceModal(f: NgForm) {
        f.resetForm();
        this.model = new ImageApproveMultipleModel();
        this.modalReference.close();
        this.reloadImages.emit();
    }

    async approve(f: NgForm) {
        this.approvalErrors = new Array<SelectListItemModel>();
        if (Number(this.model.priceForSmall) === 0
            || Number(this.model.priceForMedium) === 0
            || Number(this.model.priceForLarge === 0)) {
            this.toastr.error('Prices must be greater that zero');
            return;
        }
        if (this.imageIdList.length === 0) {
            this.toastr.error('Please select an image to continue');
            this.closeImagePriceModal(f);
            return;
        }
        let counter = 1;
        let isAnyApprovalFailed = false;
        this.pbMaxVal = this.imageIdList.length;
        this.pbCurrentVal = 0;
        this.isApproving = true;

        for (const imageId of this.imageIdList) {

            this.model.imageId = imageId;

            try {
                await this.approveSingle().then(() => {
                    this.pbCurrentVal += 1;
                }).catch(error => {
                    const approvalErrorModel = new SelectListItemModel();
                    approvalErrorModel.value = error.error;
                    this.approvalErrors.push(approvalErrorModel);

                    isAnyApprovalFailed = true;
                });
            } catch (e) {
                console.log(e);
            }

            counter += 1;
        }

        setTimeout(() => {
            if (counter > 1 && !isAnyApprovalFailed) {
                this.toastr.success('Selected images has been approved successfully');
                this.closeImagePriceModal(f);

            } else if (isAnyApprovalFailed) {
                this.toastr.error('Some images can not be approved. Please try again');
            }
            this.isApproving = false;
        }, 1000);
    }

    async approveSingle(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.adminImageService.approveMultiple(this.model)
                .subscribe(
                    () => {
                        resolve(true);
                    },
                    error => {
                        console.log(error);
                        reject(error);
                    }
                );
        });
    }
}
