import { Injectable } from '@angular/core';
import { TableModel } from 'src/app/models/table.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PagedWishlistDetails } from '../models/pagedWishlistDetails';
import { WishlistItem } from '../models/wishlistItem';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class WishlistService {

  constructor(private http: HttpClient) { }

  isActive = (imageId: number): Observable<WishlistItem> =>
    this.http.get<WishlistItem>(`${environment.apiUrl}/api/user/wishlist/${imageId}`)

  getWishlistImages = (tableModel: TableModel): Observable<PagedWishlistDetails> =>
    this.http.get<PagedWishlistDetails>(
      `${environment.apiUrl}/api/user/wishlist/list?${tableModel.toQueryString()}`)

  getImageIdList = (): Observable<Array<number>> =>
    this.http.get<Array<number>>(`${environment.apiUrl}/api/user/wishlist/list-imageid`)

  toggle = (imageId: number) =>
    this.http.post(`${environment.apiUrl}/api/user/wishlist/toggle/${imageId}`, null)
}
