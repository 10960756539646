import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AuthService } from './services/auth.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { ListenerService } from 'src/app/services/listener.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'atp-images';
  isDashboard: boolean;

  constructor(private authService: AuthService,
    private ngbTooltipConfig: NgbTooltipConfig,
    private router: Router,
    private listnerService: ListenerService) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.router.events.subscribe((e: NavigationStart) => {
      if (e instanceof NavigationStart) {
        this.listnerService.closeSidebarListner.next();
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe((e: NavigationEnd) => {
      if (e instanceof NavigationEnd) {
        this.isDashboard = e.url === '/';
      }
    });
    this.ngbTooltipConfig.placement = 'top';
    this.authService.initUserIfLoggedIn();
  }
}
