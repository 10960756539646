import { Component, HostBinding } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-image-preview',
    templateUrl: './image.preview.component.html'
})
export class ImagePreviewComponent {

    imageUrl: string;

    @HostBinding('class.mx-auto')

    auto = true;

    constructor(public activeModal: NgbActiveModal) { }

    setImage(image: string) {
        this.imageUrl = image;
    }

    close() {
        this.activeModal.dismiss();
    }
}
