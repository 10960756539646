import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PageUrls } from 'src/app/helpers/page-urls';

@Component({
    selector: 'app-premium-photo-gallery-mosaic',
    templateUrl: './premium.photo.gallery.component.html',
    styleUrls: ['./premium.photo.gallery.component.scss']
})

export class AppPremiumPhotoGalleryMosaicComponent {

    constructor(private router: Router, private route: ActivatedRoute) { }

    @Input() photoGalleryMosaicItems: Array<any>;
    @Output() itemSelected = new EventEmitter<any>();

    get perfectHeight() {
        return Number.parseInt((window.innerWidth > 1900 ? 350 : 300).toString(), 10);
    }

    widthGetterFn = (image: any) => image.realWidth;
    heightGetterFn = (image: any) => image.realHeight;
    lowSizePreviewGetter = (image: any) => image.lowSizePreview;

    imageSelected(item: any) {
        if (item.eventCount !== 0) {
            this.itemSelected.emit(item.identifier);
        } else {
            setTimeout(() => {
                this.router.navigateByUrl(`${PageUrls.Buyer.PremiumEventImages}?id=${item.identifier}`);
            });
        }
    }
}
