import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { CartItemModel } from 'src/app/models/cart.item.model';
import { OrderCreateModel } from 'src/app/buyer/models/order.create.model';
import { BuyerProfileModel } from 'src/app/buyer/models/buyer.profile.model';
import { PaymentCreateModel } from 'src/app/models/payment.create.model';
import { ListenerService } from 'src/app/services/listener.service';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { BuyerProfileService } from 'src/app/buyer/services/profile.service';
import { OrderService } from 'src/app/buyer/services/order.service';
import { AppUtils } from 'src/app/helpers/app.utils';
import { Constants } from 'src/app/helpers/constants';
import { PageUrls } from 'src/app/helpers/page-urls';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
    @ViewChild('confirmBuyModal', { static: true }) confirmBuyModal: any;

    cartItems = new Array<CartItemModel>();
    imageTypes = AppUtils.getImageTypes();
    paymentCreateModel = new PaymentCreateModel();
    orderModel = new OrderCreateModel();
    cartTotal = 0;
    paybleAmount = 0;
    isPaymentProcessing = false;
    isModelLoaded = false;
    isUserLoggedIn = false;

    get urls() {
        return PageUrls;
    }

    constructor(private router: Router,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private listenerService: ListenerService,
        private authService: AuthService,
        private cartService: CartService,
        private buyerProfileService: BuyerProfileService,
        private orderService: OrderService) {
        this.isUserLoggedIn = this.authService.isUserLoggedIn();
    }

    ngOnInit() {
        this.getCartItems();
    }

    getCartItems() {
        this.cartTotal = 0;
        this.paybleAmount = 0;

        this.spinner.show();

        this.cartService.getCartItems().subscribe(
            data => {
                this.spinner.hide();
                this.cartItems = new Array<CartItemModel>();
                Object.assign(this.cartItems, data);
                this.cartItems.forEach((item) => {
                    item.imageTypeName = item.imageType === 1 ? 'Small' : item.imageType === 2 ? 'Medium' : 'Large';
                });
                this.isModelLoaded = true;
                setTimeout(() => {
                    this.validateCart();
                });
            },
            error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    deleteCartItem(id: string) {
        this.spinner.show();
        this.cartService.deleteCartItem(id).subscribe(
            () => {
                this.listenerService.refreshCartListner.next();
                this.spinner.hide();
                setTimeout(() => {
                    this.getCartItems();
                });
            },
            error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    initOrder() {
        if (!this.authService.isUserLoggedIn()) {
            this.router.navigate([PageUrls.Login], { queryParams: { url: PageUrls.Cart } });
            return;
        }
        if (!this.authService.isLoggedInUserInRole(Constants.Roles.Buyer)) {
            this.toastr.error('Only buyer type user can buy the images');
            return;
        }
        if (this.paybleAmount === 0) {
            this.creatOrder();
        } else {
            this.initPayment();
        }
    }

    validateCart() {
        this.spinner.show();
        this.cartService.validateCart(AppUtils.getCartId())
            .subscribe(
                (responase) => {
                    this.spinner.hide();

                    this.cartTotal = responase.totalAmount;
                    this.paybleAmount = responase.paybleAmount;
                },
                error => {
                    this.spinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    initPayment() {
        this.spinner.show();
        this.buyerProfileService.getProfile()
            .subscribe(
                (response: BuyerProfileModel) => {
                    this.spinner.hide();
                    this.paymentCreateModel.amount = this.paybleAmount;
                    this.paymentCreateModel.firstName = response.firstName;
                    this.paymentCreateModel.lastName = response.lastName;
                    this.paymentCreateModel.email = response.email;
                    this.paymentCreateModel.address = response.address;
                    this.paymentCreateModel.city = response.city;
                    this.paymentCreateModel.zipCode = response.zipCode;
                    if (response.country) {
                        this.paymentCreateModel.country = response.country;
                    }

                    this.isPaymentProcessing = true;
                },
                error => {
                    this.spinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    paymentCompleted(paymentIntentId: string) {
        this.orderModel.paymentId = paymentIntentId;
        this.creatOrder();
    }

    paymentCancelled() {
        this.isPaymentProcessing = false;
        this.paymentCreateModel = new PaymentCreateModel();
    }

    creatOrder() {
        this.spinner.show();
        this.orderModel.cartId = AppUtils.getCartId();
        this.orderService.createFromCart(this.orderModel).subscribe(
            (orderId: string) => {
                this.listenerService.refreshCartListner.next();
                this.spinner.hide();
                this.router.navigate([PageUrls.Buyer.OrderDetail], { queryParams: { id: orderId } });
                setTimeout(() => {
                    this.toastr.success('Your order has been placed successfully');
                }, 200);
            },
            error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    ngOnDestroy(): void {
        this.spinner.hide();
    }

}
