export class ImageDetail {
    public imageId: number;
    public imageUrl: string;
    public isLandscape: boolean;
    public fileName: string;
    public headline: string;
    public photographerName: string;
    public itemInImageDetails: string;
    public description: string;
    public imageCategoryId: any;
    public priceForSmall: number;
    public priceForMedium: number;
    public priceForLarge: number;
    public keywords: Array<string>;
    public selectedImageType: string;
    public isCartItem: boolean;
    public isAddedToWishList: boolean;
    public uniqueCode: string;

    constructor() {
        this.isCartItem = false;
        this.isAddedToWishList = false;
        this.keywords = new Array<string>();
    }
}
