export class PagedResponseModel<T> {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    items: Array<T>;

    constructor() {
        this.items = new Array<T>();
    }
}
