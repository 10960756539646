import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ImageCategoryService } from 'src/app/services/image-category.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/app/helpers/app.utils';
import { AlbumModel } from 'src/app/models/album.model';
import { ImageService } from 'src/app/services/image-service';
import { EventService } from 'src/app/services/event.service';
import { TableModel } from 'src/app/models/table.model';
import { Subscription } from 'rxjs';
import { ImageDetail } from 'src/app/models/image-detail.model';
import { EventDetailModel } from 'src/app/models/event.detail.model';
import { ParentEventDetailModel } from 'src/app/models/parent.event.detail.model';
import { ImageTypeModel } from 'src/app/models/image.type.model';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {
  totalPages: number;
  selectedCategoryId: number;
  selectedCategoryName: string;
  selectedSubCategoryName: string;
  selectedParentEventId: number;
  photoGalleryMosaicItems: Array<any>;
  indexToSelectCategory = 1;
  subscriptions = new Array<Subscription>();
  categories = new Array<AlbumModel>();
  images = new Array<ImageDetail>();
  events = new Array<EventDetailModel>();
  parentEvents = new Array<ParentEventDetailModel>();
  tableModelForImage = new TableModel();
  tableModelForEvent = new TableModel();
  tableModelForParentEvent = new TableModel();
  imageTypes = new Array<ImageTypeModel>();
  isParentEventsLoaded = false;
  isEventsLoaded = false;
  isImagesLoaded = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private imageCategoryService: ImageCategoryService,
    private imageService: ImageService,
    private eventService: EventService) {
    this.tableModelForParentEvent.pageSize = 15;
    this.tableModelForEvent.pageSize = 15;
    this.tableModelForImage.pageSize = 15;

    if (this.route.snapshot.queryParams.id
      && Number(this.route.snapshot.queryParams.id) > 0) {
      this.selectedCategoryId = Number(this.route.snapshot.queryParams.id);
    }
    if (this.route.snapshot.queryParams.subcategory) {
      this.selectedSubCategoryName = this.route.snapshot.queryParams.subcategory;
    }
    if (this.route.snapshot.queryParams.eid) {
      this.selectedParentEventId = this.route.snapshot.queryParams.eid;
    }
  }

  ngOnInit() {
    this.initSubscribers();
    this.loadCategories();
    this.imageTypes = AppUtils.getImageTypes();
  }

  initSubscribers() {
    const imageSubscription
      = this.tableModelForImage.pageChanged.subscribe(() => this.loadImagesByCategory());
    this.subscriptions.push(imageSubscription);

    const parentEventSubscription
      = this.tableModelForParentEvent.pageChanged.subscribe(() => this.loadParentEventsByCategory());
    this.subscriptions.push(parentEventSubscription);

    const eventSubscription
      = this.tableModelForEvent.pageChanged.subscribe(() => this.loadEventsByCategory(this.selectedParentEventId));
    this.subscriptions.push(eventSubscription);
  }

  loadCategories() {
    this.imageCategoryService.getCategories()
      .subscribe(items => {
        this.categories = items;
        if (!this.selectedCategoryId) {
          this.selectedCategoryId = items[this.indexToSelectCategory].id;
        } else {

          let indexToSelectCategory = this.categories.findIndex(x => x.id === this.selectedCategoryId);
          if (indexToSelectCategory === -1) {
            this.categories.forEach((category) => {
              if (indexToSelectCategory === -1 && category.subCategories && category.subCategories.length > 0) {
                const indexToSelectSubCategory = category.subCategories.findIndex(x => x.id === this.selectedCategoryId);
                if (indexToSelectSubCategory > -1) {
                  indexToSelectCategory = this.categories.indexOf(category);
                }
              }
            });
          }
          this.indexToSelectCategory = indexToSelectCategory;
        }
        this.selectedCategoryName = items[this.indexToSelectCategory].name;
        this.loadParentEventsByCategory();
      }, error => {
        AppUtils.processErrorResponse(this.toastr, error);
      });
  }

  categoryChanged(categoryId: number, categoryName: string, parentName: string) {
    if (categoryId == 0 ) {
      this.router.navigate(["/collection"]);
    }
    let queryParams: Params;

    if (!categoryName) {
      this.selectedSubCategoryName = null;
      const selectedCategory = this.categories.find(x => x.id === categoryId);
      queryParams = { id: categoryId, category: selectedCategory.name };
    } else {
      this.selectedSubCategoryName = categoryName;
      queryParams = { id: categoryId, category: parentName, subcategory: categoryName };
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams
      });
  }

  loadParentEventsByCategory() {
    this.isEventsLoaded = false;
    this.isImagesLoaded = false;
    this.isParentEventsLoaded = false;
    this.spinner.show();
    this.eventService.getParentEventByCategory(this.selectedCategoryId, this.tableModelForParentEvent)
      .subscribe(pagedList => {
        this.parentEvents.push(...pagedList.items);
        this.totalPages = pagedList.totalPages;

        this.isParentEventsLoaded = true;

        if (this.selectedParentEventId) {
          this.loadEventsByCategory(this.selectedParentEventId);
        } else {
          this.updateEventPhotoGallery(true);
          this.spinner.hide();
        }


        if (this.parentEvents.length === 0) {
          this.tableModelForImage.page = 1;
          this.loadImagesByCategory();
          return;
        }
        this.parentEvents.forEach((parentEvent) => {
          parentEvent.date = AppUtils.getFormattedDate(parentEvent.date, null);
        });


      }, error => {
        this.spinner.hide();
        this.isParentEventsLoaded = true;
        AppUtils.processErrorResponse(this.toastr, error);
      });
  }

  loadEventsByCategory(parentEventId: number) {
    this.isParentEventsLoaded = false;
    this.isEventsLoaded = false;
    this.selectedParentEventId = parentEventId;
    this.spinner.show();
    this.eventService.getByCategory(parentEventId, this.selectedCategoryId, this.tableModelForEvent)
      .subscribe(pagedList => {
        this.events.push(...pagedList.items);
        this.totalPages = pagedList.totalPages;
        this.updateEventPhotoGallery(false);
        this.spinner.hide();

        let queryParams: Params;
        const selectedCategory = this.categories.find(x => x.id === this.selectedCategoryId);
        const selectedEvent = this.parentEvents.find(x => x.id == parentEventId);
        queryParams = { id: selectedCategory.id, category: selectedCategory.name, eid: selectedEvent.id, ename: selectedEvent.title };

        this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams: queryParams
          });

        this.isEventsLoaded = true;
        if (this.events.length === 0) {
          this.tableModelForImage.page = 1;
          return;
        }
        this.events.forEach((event) => {
          event.date = AppUtils.getFormattedDate(event.date, null);
        });
      }, error => {
        this.spinner.hide();
        this.isEventsLoaded = true;
        AppUtils.processErrorResponse(this.toastr, error);
      });
  }

  loadImagesByCategory() {
    this.isImagesLoaded = false;
    setTimeout(() => {
      this.spinner.show();
    });
    this.imageService.getImagesByCategory(this.selectedCategoryId, this.tableModelForImage)
      .subscribe(pagedList => {
        this.images.push(...pagedList.items);
        this.totalPages = pagedList.totalPages;
        this.images.forEach(image => {
          image.selectedImageType = '';
        });
        this.spinner.hide();
        this.isImagesLoaded = true;
      }, error => {
        this.spinner.hide();
        this.isImagesLoaded = true;
        AppUtils.processErrorResponse(this.toastr, error);
      });
  }

  loadMoreParentEvents() {
    this.tableModelForParentEvent.page += 1;
  }

  loadMoreEvents() {
    this.tableModelForEvent.page += 1;
  }

  loadMoreImages() {
    this.tableModelForImage.page += 1;
  }

  updateEventPhotoGallery(isParentEvent: boolean) {

    const imageHeight = window.innerWidth > 1900 ? 350 : 300;
    const tempArray = new Array<any>();

    if (isParentEvent) {

      this.parentEvents.forEach((item) => {

        const imageWidth = Math.round(imageHeight * item.coverImageWidth / item.coverImageHeight);

        tempArray.push(
          {
            identifier: item.id,
            fullSizePreview: null,
            lowSizePreview: item.coverImageUrl,
            realWidth: imageWidth,
            realHeight: imageHeight,
            eventTitle: item.title,
            eventDate: item.date,
            eventCount: item.eventCount,
            imageCount: item.imageCount,
            itemCount: item.eventCount > 0 ? item.eventCount : item.imageCount,
            isParentEvent: true
          });
      });
    } else {
      this.events.forEach((item) => {

        const imageWidth = Math.round(imageHeight * item.coverImageWidth / item.coverImageHeight);

        tempArray.push(
          {
            identifier: item.id,
            fullSizePreview: null,
            lowSizePreview: item.coverImageUrl,
            realWidth: imageWidth,
            realHeight: imageHeight,
            eventTitle: item.title,
            eventDate: item.date,
            eventCount: 0,
            imageCount: item.imageCount,
            itemCount: item.imageCount,
            isParentEvent: false
          });
      });
    }

    this.photoGalleryMosaicItems = tempArray;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

}

