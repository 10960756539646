import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PageUrls } from 'src/app/helpers/page-urls';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TableModel } from 'src/app/models/table.model';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
    @Input() tableModel: TableModel;
    @Input() isDateRangeEnable: boolean;
    @Output() doFilter = new EventEmitter();

    hoveredDate: NgbDate;
    fromDate: NgbDate;
    toDate: NgbDate;
    minDate: any;
    maxDate: any;

    get urls() {
        return PageUrls;
    }

    constructor(private calendar: NgbCalendar,
        public formatter: NgbDateParserFormatter) {
        const currentDate = new Date();
        this.minDate = { year: 1800, month: 1, day: 1 };
        this.maxDate = { year: currentDate.getFullYear(), month: (currentDate.getMonth() + 1), day: currentDate.getDate() };
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
            this.tableModel.startDate = this.formatter.format(date);
        } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
            this.toDate = date;
            this.tableModel.endDate = this.formatter.format(date);
        } else {
            this.toDate = null;
            this.tableModel.endDate = '';
            this.fromDate = date;
            this.tableModel.startDate = this.formatter.format(date);
        }
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string, isFrom: boolean): any {
        const parsed = this.formatter.parse(input);
        const date = parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
        if (isFrom) {
            this.fromDate = date;
            this.tableModel.startDate = this.formatter.format(date);
        } else {
            this.toDate = date;
            this.tableModel.endDate = this.formatter.format(date);
        }
        if (NgbDate.from(parsed)) {
            this.onDateSelection(date);
        }
    }

    reset() {
        this.fromDate = null;
        this.toDate = null;
        this.tableModel.filterKey = '';
        this.tableModel.startDate = '';
        this.tableModel.endDate = '';
        this.doFilter.next();
    }

    filter() {
        this.doFilter.next();
    }
}
