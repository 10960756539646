import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Constants } from '../helpers/constants';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
@Injectable()
export class BuyerAuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.isLoggedInUserInRole(Constants.Roles.Buyer)) {
            return true;
        }
        this.router.navigateByUrl('/');
        return false;
    }
}
