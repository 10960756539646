import { PendingGroupedDetailModel } from './pending.grouped.detail.model';

export class PagedPendingGroupedModel {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    items: Array<PendingGroupedDetailModel>;

    constructor() {
        this.items = Array<PendingGroupedDetailModel>();
    }
}
