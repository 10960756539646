import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppUtils } from 'src/app/helpers/app.utils';
import { TableModel } from 'src/app/models/table.model';
import { PageUrls } from 'src/app/helpers/page-urls';
import { Subscription } from 'rxjs';
import { ImageTypeModel } from 'src/app/models/image.type.model';
import { PagedSubcategoryDetailModel } from 'src/app/admin/models/paged.subcategory.detail';
import { AlbumService } from 'src/app/services/album.service';

@Component({
    selector: 'app-subcategory-manage',
    templateUrl: './subcategory.manage.component.html',
    styleUrls: ['./subcategory.manage.component.scss']
})
export class SubcategoryManageComponent implements OnInit {

    subscriptions = new Array<Subscription>();
    imageSizes = new Array<ImageTypeModel>();
    pagedSubcategoryDetails = new PagedSubcategoryDetailModel();
    totalRecords = 1;
    tableModel = new TableModel();
    isModelLoaded = false;

    get urls() {
        return PageUrls;
    }

    constructor(private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private albumService: AlbumService,
        private router: Router) {
        this.tableModel.orderBy = 'Name';
        this.tableModel.orderByDirection = 'asc';
    }


    ngOnInit() {
        this.imageSizes = AppUtils.getImageTypes();
        this.initSubscribers();
        this.getSubcategories();
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getSubcategories());
        this.subscriptions.push(subscription);
    }

    getSubcategories = () => {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.albumService.listSubcategory(this.tableModel)
            .subscribe(data => {
                Object.assign(this.pagedSubcategoryDetails, data);
                this.totalRecords = data.totalCount;
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    toggleSubcategory(id: number, isActive: boolean) {
        this.ngxSpinner.show();
        this.albumService.toggleSubcategory(id)
            .subscribe(() => {
                this.toastr.success('Subcategory status has been changed successfully');
                this.ngxSpinner.hide();
                this.getSubcategories();
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    deleteSubcategory(id: number) {
        if (!confirm('Are you sure you want to delete this subcategory?')) {
            return;
        }
        this.ngxSpinner.show();
        this.albumService.deleteSubcategory(id)
            .subscribe(() => {
                this.toastr.success('Subcategory has been deleted successfully');
                this.ngxSpinner.hide();
                this.getSubcategories();
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    openEditSubcategory(id: number) {
        this.router.navigateByUrl(`${PageUrls.Admin.SubcategoryEdit}?subcategoryId=${id}`);
    }
}
