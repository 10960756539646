import { SafeUrl } from '@angular/platform-browser';

export class ImageDetailModel {
    imageId: number;
    eventId: string;
    eventDate: any;
    fileName: string;
    imageUrl: SafeUrl;
    photographerName: string;
    headline: string;
    itemInImageDetails: string;
    description: string;
    keywords: string[];
    priceForSmall: number;
    priceForMedium: number;
    priceForLarge: number;
    isLandscape: boolean;
    isSelected: boolean;
    status: number;
    approvedRejectedOn: any;

    constructor() {
        this.isSelected = false;
    }
}
