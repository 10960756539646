import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AppUtils } from 'src/app/helpers/app.utils';
import { TableModel } from 'src/app/models/table.model';
import { ImageDetailModel } from 'src/app/admin/models/image.detail.model';
import { AdminImageService } from 'src/app/admin/services/admin-image.service';

@Component({
    selector: 'app-collection-select-image',
    templateUrl: './collection.select.image.component.html',
    styleUrls: ['./collection.select.image.component.scss']
})

export class CollectionSelectImageComponent implements OnInit, OnDestroy {
    @Input() model: any;
    @Output() cancelled = new EventEmitter();
    @Output() completed = new EventEmitter();
    subscriptions = new Array<Subscription>();
    isSelectAll = false;
    isImagesLoaded = false;
    collectionImageCount = 0;
    totalRecords = 0;
    totalPages = 0;
    tableModel = new TableModel();
    images = new Array<ImageDetailModel>();
    selectedImageIds = new Array<number>();

    constructor(private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private imageService: AdminImageService) {
        this.tableModel.pageSize = 20;
        this.tableModel.page = 1;
        this.tableModel.orderBy = 'ApprovedRejectedOn';
        this.tableModel.orderByDirection = 'desc';

        const subscription = this.tableModel.pageChanged.subscribe(() => this.loadImages());
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        if (this.model.imageIdList.length > 0) {
            this.model.imageIdList.forEach((imageId: number) => {
                this.selectedImageIds.push(imageId);
            });
        }

        if (this.model.id
            && this.model.eventSelectListItems
            && this.model.eventSelectListItems.length === 0
            && this.model.imageIdList
            && this.model.imageIdList.length === 0) {
            this.model.selectedAllImages = true;
        }

        this.loadImages();
    }

    loadImages() {
        if (this.model.eventIdList.length > 0) {
            this.loadImagesByEvents();
        } else {
            this.loadImagesByCategory();
        }
    }

    loadImagesByEvents() {
        this.isImagesLoaded = false;
        setTimeout(() => {
            this.spinner.show();
        });
        this.imageService.getImagesByEvents(this.model.eventIdList.join(','), this.tableModel)
            .subscribe(pagedList => {
                this.totalRecords = pagedList.totalCount;
                this.totalPages = pagedList.totalPages;
                if (this.selectedImageIds.length > 0) {
                    this.selectedImageIds.forEach(imageId => {
                        const image = pagedList.items.find(x => x.imageId === imageId);
                        if (image) {
                            image.isSelected = true;
                        }
                    });
                }
                this.images = pagedList.items;
                this.spinner.hide();
                this.isImagesLoaded = true;
            }, error => {
                this.spinner.hide();
                this.isImagesLoaded = true;
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    loadImagesByCategory() {
        this.isImagesLoaded = false;
        setTimeout(() => {
            this.spinner.show();
        });
        this.imageService.getImagesByCategory(this.model.categoryId, this.tableModel)
            .subscribe(pagedList => {
                this.totalRecords = pagedList.totalCount;
                this.totalPages = pagedList.totalPages;
                if (this.selectedImageIds.length > 0) {
                    this.selectedImageIds.forEach(imageId => {
                        const image = pagedList.items.find(x => x.imageId === imageId);
                        if (image) {
                            image.isSelected = true;
                        }
                    });
                }
                this.images = pagedList.items;
                this.spinner.hide();
                this.isImagesLoaded = true;
                if (this.model.selectedAllImages) {
                    this.collectionImageCount = this.totalRecords;
                    this.onSelectAll();
                } else {
                    this.collectionImageCount = this.model.imageIdList.length;
                }
            }, error => {
                this.spinner.hide();
                this.isImagesLoaded = true;
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    search() {
        if (!this.model.id) {
            this.onUnselectAll();
        }
        this.tableModel.page = 1;
    }

    onSelectAll() {
        this.selectedImageIds = new Array<number>();
        this.model.selectedAllImages = true;
        this.images.forEach(image => {
            image.isSelected = true;
        });

        if (!this.tableModel.filterKey) {
            return;
        }

        this.spinner.show();
        this.imageService.getImageIdList(this.model.categoryId, this.tableModel)
            .subscribe(
                (data: Array<number>) => {
                    this.spinner.hide();
                    data.forEach(imageId => {
                        this.selectedImageIds.push(imageId);
                    });
                },
                error => {
                    this.spinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });

    }

    onUnselectAll() {
        this.selectedImageIds = new Array<number>();
        this.model.selectedAllImages = false;
        this.images.forEach(image => {
            image.isSelected = false;
        });
    }

    onSelectChange(image: ImageDetailModel) {
        const index = this.selectedImageIds.indexOf(image.imageId);

        if (image.isSelected && index === -1) {
            this.selectedImageIds.push(image.imageId);
            return;
        }

        if (!image.isSelected && index !== -1) {
            this.selectedImageIds.splice(index, 1);
        }
    }

    setAsCoverImage() {
        if (this.selectedImageIds.length === 1) {
            this.model.coverImageId = this.selectedImageIds[0];
            this.toastr.success('Selected image as been configured as cover image');
        } else if (this.selectedImageIds.length === 0) {
            this.toastr.error('Please select an image to continue');
        } else if (this.selectedImageIds.length > 1) {
            this.toastr.error('Only one image can be set as cover image');
        }
    }

    addToCollection() {
        if (this.model.selectedAllImages === false && this.selectedImageIds.length === 0) {
            this.toastr.error('Please select image(s) to continue');
            return;
        }

        if ((!this.model.selectedAllImages || this.tableModel.filterKey) && this.selectedImageIds.length > 0) {
            this.selectedImageIds.forEach(imageId => {
                if (this.model.imageIdList.indexOf(imageId) === -1) {
                    this.model.imageIdList.push(imageId);
                }
            });
        }

        if (this.model.selectedAllImages) {
            this.collectionImageCount = this.totalRecords;
        } else {
            this.collectionImageCount = this.model.imageIdList.length;
        }

        this.toastr.success('Selected images has been added to the collection');
    }

    resetCollection() {
        this.model.imageIdList = new Array<number>();
        this.collectionImageCount = 0;
        this.toastr.success('Images has been removed from the collection');
    }

    onCancelled() {
        this.model.selectedAllImages = false;
        this.model.imageIdList = new Array<number>();
        this.cancelled.emit();
    }

    submit() {
        if (!this.model.coverImageId) {
            this.toastr.error('Please select cover image to continue');
            return;
        }

        if (this.model.createWith === 'events') {
            this.model.imageIdList = null;
            this.completed.emit();
            return;
        }

        if (this.collectionImageCount === 0) {
            this.toastr.error('Please select image(s) to continue');
            return;
        }

        this.model.eventIdList = null;

        if (this.model.selectedAllImages === true && !this.tableModel.filterKey) {
            this.model.imageIdList = null;
        } else if (this.model.imageIdList.length > 0
            && this.model.imageIdList.indexOf(this.model.coverImageId) === -1) {
            this.model.imageIdList.push(this.model.coverImageId);
        }

        this.completed.emit();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

}

