import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AppUtils } from 'src/app/helpers/app.utils';
import { UserService } from 'src/app/admin/services/user.service';
import { TableModel } from 'src/app/models/table.model';
import { PagedContributorDetailModel } from 'src/app/admin/models/paged.contributor.detail.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-contributor',
    templateUrl: './user.contributor.component.html',
    styleUrls: ['./user.contributor.component.scss']
})
export class UserContributorComponent implements OnInit, OnDestroy {

    tableModel = new TableModel();
    totalRecords = 1;
    subscriptions = new Array<Subscription>();
    pagedContributorDetail = new PagedContributorDetailModel();
    isModelLoaded = false;

    constructor(private router: Router, private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private userService: UserService) {
        this.tableModel.orderBy = 'User.FirstName';
        this.tableModel.orderByDirection = 'asc';
    }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
        this.initSubscribers();
        this.getPhotographers();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getPhotographers());
        this.subscriptions.push(subscription);
    }

    getPhotographers() {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.userService.listContributor(this.tableModel)
            .subscribe(data => {
                this.pagedContributorDetail = data;
                this.totalRecords = data.totalCount;
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    deleteContributor(id: number) {
        if (!confirm('Are you sure you want to delete this photographer?')) {
            return;
        }
        this.ngxSpinner.show();
        this.userService.deleteContributor(id)
            .subscribe(() => {
                this.toastr.success('Photographer has been deleted successfully');
                this.ngxSpinner.hide();
                this.getPhotographers();
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    openEditContributor(id: number) {
        this.router.navigateByUrl(`${PageUrls.Admin.UserContributorEdit}?contributorId=${id}`);
    }

    resetTableModel() {
        this.tableModel.page = 1;
    }
}
