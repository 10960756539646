import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule, NgbModalModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';

import { AppMustMatchDirective } from './validators/must-match.validator';
import { SecurePipe } from './Pipes/secure.pipe';
import { SortableHeader } from './directives/sortable-header';

import {
  DisableManualInputDirective, AlphabatesOnlyDirective, AlphabatesWithSpaceOnlyDirective, AlphabatesALevelOneDirective,
  AlphabatesALevelTwoDirective, NumbersOnlyDirective, AlphaNumericsOnlyDirective, AlphaNumericsLevelOneDirective,
  AlphaNumericsLevelTwoDirective, AlphaNumericsLevelThreeDirective, AlphaNumericsLevelFourDirective, AnythingButWhiteSpaceDirective,
  EmailAddressOnlyDirective, EmailAddressWithSemiColonOnlyDirective, PhoneNumberOnlyDirective, WebUrlOnlyDirective,
  ZipCodeOnlyDirective, AlphabatesWithNumberAndSpaceOnlyDirective
} from 'src/app/shared/directives/app.directives';

@NgModule({
  declarations: [
    AppMustMatchDirective,
    SecurePipe,
    SortableHeader,
    DisableManualInputDirective,
    AlphabatesOnlyDirective,
    AlphabatesWithSpaceOnlyDirective,
    AlphabatesALevelOneDirective,
    AlphabatesALevelTwoDirective,
    AlphabatesWithNumberAndSpaceOnlyDirective,
    NumbersOnlyDirective,
    AlphaNumericsOnlyDirective,
    AlphaNumericsLevelOneDirective,
    AlphaNumericsLevelTwoDirective,
    AlphaNumericsLevelThreeDirective,
    AlphaNumericsLevelFourDirective,
    AnythingButWhiteSpaceDirective,
    EmailAddressOnlyDirective,
    EmailAddressWithSemiColonOnlyDirective,
    PhoneNumberOnlyDirective,
    WebUrlOnlyDirective,
    ZipCodeOnlyDirective
  ],
  imports: [
    NgxMaskModule.forRoot()
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AppMustMatchDirective,
    NgbTooltipModule,
    NgbModalModule,
    NgbPaginationModule,
    NgxMaskModule,
    SecurePipe,
    SortableHeader,
    DisableManualInputDirective,
    AlphabatesOnlyDirective,
    AlphabatesWithSpaceOnlyDirective,
    AlphabatesALevelOneDirective,
    AlphabatesALevelTwoDirective,
    AlphabatesWithNumberAndSpaceOnlyDirective,
    NumbersOnlyDirective,
    AlphaNumericsOnlyDirective,
    AlphaNumericsLevelOneDirective,
    AlphaNumericsLevelTwoDirective,
    AlphaNumericsLevelThreeDirective,
    AlphaNumericsLevelFourDirective,
    AnythingButWhiteSpaceDirective,
    EmailAddressOnlyDirective,
    EmailAddressWithSemiColonOnlyDirective,
    PhoneNumberOnlyDirective,
    WebUrlOnlyDirective,
    ZipCodeOnlyDirective
  ]
})
export class SharedModule { }
