import { Component, OnInit } from '@angular/core';
import { AppUtils } from 'src/app/helpers/app.utils';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderService } from 'src/app/buyer/services/order.service';
import { TableModel } from 'src/app/models/table.model';
import { PagedOrderDetails } from 'src/app/buyer/models/paged.order.detail';

import { PageUrls } from 'src/app/helpers/page-urls';

@Component({
    selector: 'app-order-list',
    templateUrl: './order.list.component.html'
})
export class OrderListComponent implements OnInit {
    fileUrl: any;
    pagedOrderDetails = new PagedOrderDetails();
    totalRecords: number;
    tableModel = new TableModel();
    isModelLoaded = false;

    get urls() {
        return PageUrls;
    }

    constructor(private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private orderService: OrderService) {
        this.tableModel.orderBy = 'CreatedOn';
        this.tableModel.orderByDirection = 'desc';
    }

    ngOnInit() {
        this.getOrders();
    }

    getOrders() {
        this.spinner.show();
        this.orderService.list(this.tableModel).subscribe(
            (data: any) => {
                Object.assign(this.pagedOrderDetails, data);
                this.totalRecords = data.totalCount;
                this.pagedOrderDetails.items.forEach(order => {
                    order.createdOn = AppUtils.getFormattedDate(order.createdOn);
                });
                this.spinner.hide();
                this.isModelLoaded = true;
            },
            error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    downloadFiles(orderId: string) {
        this.spinner.show();
        this.orderService.downloadFiles(orderId).subscribe(fileBytes => {
            this.spinner.hide();
            const blob: any = new Blob([fileBytes], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const element = document.createElement('a');
            element.href = url;
            element.download = `${orderId}.zip`;
            document.body.appendChild(element);
            element.click();
        }, error => {
            this.spinner.hide();
            AppUtils.processErrorResponse(this.toastr, error);
        });
    }
}
