import { Component } from '@angular/core';
import { PageUrls } from 'src/app/helpers/page-urls';

@Component({
    selector: 'app-finance-navigation',
    templateUrl: './finance-navigation.component.html',
    styleUrls: ['./finance-navigation.component.scss']
})
export class FinanceNavigationComponent {

    get urls() {
        return PageUrls;
    }
}
