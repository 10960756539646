import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlbumModel } from 'src/app/models/album.model';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { AlbumService } from 'src/app/services/album.service';
import { AdminImageService } from '../../services/admin-image.service';
import { PagedImageDetails } from '../../models/PagedImageDetails';
import { TableModel } from 'src/app/models/table.model';
import { CategoriesModel } from 'src/app/models/categories.model';
import { ImageFilterModel } from 'src/app/admin/models/image.filter.model';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, OnDestroy {

  albumList: AlbumModel[];
  tableModel: TableModel;
  totalRecords: number;
  categoriesModel: CategoriesModel;
  subscriptions: Subscription[];
  selectedCategory: AlbumModel;
  isModelLoaded = false;
  selectedSubCategory: AlbumModel = null;
  pagedImageDetails = new PagedImageDetails();
  imageFilterModel = new ImageFilterModel();

  constructor(private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private albumService: AlbumService,
    private toastr: ToastrService,
    private adminImageService: AdminImageService) {
  }

  ngOnInit() {
    this.albumList = [];
    this.tableModel = new TableModel();
    this.categoriesModel = new CategoriesModel();
    this.totalRecords = 0;
    this.subscriptions = [];
    this.initAlbumList();
    this.initSubscribers();
    this.getImages();

    this.selectedCategory = this.getDefaultCategory();
  }

  getUrls() {
    return PageUrls.Admin.ImageManage;
  }

  getDefaultCategory() {
    const defaultCategory = new AlbumModel();
    defaultCategory.name = 'All';
    defaultCategory.subCategories = [];
    defaultCategory.id = null;
    return defaultCategory;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  initSubscribers() {
    const subscription = this.tableModel.pageChanged.subscribe(() => this.getImages());
    this.subscriptions.push(subscription);
  }

  initAlbumList = () => {
    this.albumService.getAlbumList()
      .subscribe(response => {
        this.albumList = response;
        this.albumList.forEach(x =>
          x.subCategories = x.subCategories || []);
      }, error => {
        AppUtils.processErrorResponse(this.toastr, error);
      });
  }

  setCategory = (category: AlbumModel) => {
    this.selectedCategory = category || this.getDefaultCategory();
    this.selectedSubCategory = null;
  }

  setSubCategory = (subCategory: AlbumModel) => {
    this.selectedSubCategory = subCategory;
  }

  filterImages = () => {
    this.getImages();
  }

  getSubCategoryImageUrls(imageId: number) {
    return this.albumService.getImageUrl(imageId);
  }

  getImages = () => {
    this.isModelLoaded = false;
    this.imageFilterModel = new ImageFilterModel();
    // get thumbnail type images
    this.imageFilterModel.imageSize = 4;
    // get approved only
    this.imageFilterModel.status = 2;

    this.imageFilterModel.categoryId = !isNullOrUndefined(this.selectedSubCategory)
      ? this.selectedSubCategory.id
      : !isNullOrUndefined(this.selectedCategory)
        ? this.selectedCategory.id
        : null;

    this.ngxSpinnerService.show();
    this.adminImageService.list(this.imageFilterModel, this.tableModel)
      .subscribe(data => {
        this.pagedImageDetails = data;
        this.totalRecords = data.totalCount;
        this.ngxSpinnerService.hide();
        this.isModelLoaded = true;
        this.pagedImageDetails.items.forEach((item) => {
          item.approvedRejectedOn = AppUtils.getFormattedDate(item.approvedRejectedOn, null);
        });
      }, error => {
        this.ngxSpinnerService.hide();
        AppUtils.processErrorResponse(this.toastr, error);
      });
  }

  openImageDetail(imageId: number) {
    this.router.navigate([PageUrls.Admin.UpdateImage], { queryParams: { imageId: imageId, url: PageUrls.Admin.Categories } });
  }
}
