import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AdminImageService } from 'src/app/admin/services/admin-image.service';
import { EventService } from 'src/app/admin/services/event.service';
import { AppUtils } from 'src/app/helpers/app.utils';
import { TableModel } from 'src/app/models/table.model';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { ImageFilterModel } from 'src/app/admin/models/image.filter.model';
import { ImageRejectMultipleModel } from 'src/app/admin/models/image.reject.multiple.model';
import { EventAddImageMultiple } from 'src/app/admin/models/event.add.image.multiple.model';
import { ImageDeleteMultipleModel } from 'src/app/admin/models/image.delete.multiple.model';
import { ImagePhotographerViewComponent } from '../photographer-view/image.photographer.view.component';
import { ImageFolderViewComponent } from '../folder-view/image.folder.view.component';
import { ImageViewComponent } from '../image-view/image.view.component';
import { isNullOrUndefined } from 'util';
import { NgModel } from '@angular/forms';

@Component({
    selector: 'app-image-manage',
    templateUrl: './image.manage.component.html',
    styleUrls: ['./image.manage.component.scss']
})
export class ImageManageComponent implements OnInit {
    @ViewChild('imageApproveMultiple', { static: false }) imageApproveMultipleModal: any;
    @ViewChild('photographerView', { static: false }) photographerView: ImagePhotographerViewComponent;
    @ViewChild('folderView', { static: false }) folderView: ImageFolderViewComponent;
    @ViewChild('imageView', { static: false }) imageView: ImageViewComponent;
    imageStatusList = new Array<SelectListItemModel>();
    events = new Array<SelectListItemModel>();
    tableModel = new TableModel();
    eventAddImageMultiple = new EventAddImageMultiple();
    imageIdListForApprove = new Array<number>();
    imageFilterModel = new ImageFilterModel();
    eventId = '';
    imageStatusId = '';
    selectedAll = false;
    selectedView: number;


    constructor(private route: ActivatedRoute,
        private location: Location,
        private ngxSpinnerService: NgxSpinnerService,
        private eventService: EventService,
        private toastr: ToastrService,
        private adminImageService: AdminImageService) {
        if (this.route.snapshot.queryParams.status
            && Number(route.snapshot.queryParams.status) !== NaN) {
            this.imageFilterModel.status = Number(route.snapshot.queryParams.status);
        }
        if (route.snapshot.queryParams.photographer) {
            this.imageFilterModel.photographer = route.snapshot.queryParams.photographer;
            if (this.imageFilterModel.photographer) {
                this.selectedView = 2;
            }
        }
        if (route.snapshot.queryParams.folderName) {
            this.imageFilterModel.folderName = route.snapshot.queryParams.folderName;
            if (this.imageFilterModel.folderName) {
                this.selectedView = 3;
            }
        }
        this.location.replaceState(this.location.path().split('?')[0], '');
    }

    ngOnInit() {
        if (isNullOrUndefined(this.selectedView)) {
            this.selectedView = 1;
        }
        this.loadEvents();
        this.loadImageStatuses();
    }

    loadImageStatuses() {
        this.imageStatusId = '';
        this.imageStatusList = new Array<SelectListItemModel>();

        if (this.imageFilterModel.status === 1
            || this.imageFilterModel.status === 4
            || this.imageFilterModel.status === 5) {
            const imageStatus = new SelectListItemModel();
            imageStatus.key = 2;
            imageStatus.value = 'Approve';
            this.imageStatusList.push(imageStatus);
        }

        if (this.imageFilterModel.status === 1
            || this.imageFilterModel.status === 2) {
            const imageStatus = new SelectListItemModel();
            imageStatus.key = 5;
            imageStatus.value = 'Reject';
            this.imageStatusList.push(imageStatus);
        }

        if (this.imageFilterModel.status !== 4) {
            const imageStatus = new SelectListItemModel();
            imageStatus.key = 4;
            imageStatus.value = 'Delete';
            this.imageStatusList.push(imageStatus);
        }

        const defaultStatus = new SelectListItemModel();
        defaultStatus.key = 6;
        defaultStatus.value = 'Delete Permanently';
        this.imageStatusList.push(defaultStatus);
    }

    loadEvents() {
        this.eventService.getSelectItems().subscribe(
            (events: Array<SelectListItemModel>) => {
                this.events = events;
            },
            error => {
                console.log(error);
            });
    }

    imageStatusChanged() {
        setTimeout(() => {
            this.imageStatusId = '';
        });
        let selectedImageIdList = new Array<number>();

        switch (this.selectedView) {
            case 1: selectedImageIdList = this.photographerView.getSelectedImageIdList(); break;
            case 2: selectedImageIdList = this.folderView.getSelectedImageIdList(); break;
            case 3: selectedImageIdList = this.imageView.getSelectedImageIdList(); break;
        }

        if (isNullOrUndefined(selectedImageIdList)) {
            this.imageStatusId = '';
            return;
        }

        if (this.imageStatusId === '2') {
            this.approveMultiple();
        } else if (this.imageStatusId === '5') {
            this.rejectMultiple();
        } else if (this.imageStatusId === '4') {
            this.deleteMultiple();
        } else if (this.imageStatusId === '6') {
            this.hardDeleteMultiple();
        }
    }

    eventChanged(ngModel: NgModel) {
        if (isNullOrUndefined(this.eventId)) {
            return;
        }
        let selectedImageIdList = new Array<number>();

        switch (this.selectedView) {
            case 1: selectedImageIdList = this.photographerView.getSelectedImageIdList(); break;
            case 2: selectedImageIdList = this.folderView.getSelectedImageIdList(); break;
            case 3: selectedImageIdList = this.imageView.getSelectedImageIdList(); break;
        }

        if (isNullOrUndefined(selectedImageIdList)) {
            this.eventId = '';
            return;
        }

        this.eventAddImageMultiple = new EventAddImageMultiple();
        this.eventAddImageMultiple.eventId = Number(this.eventId);
        this.eventAddImageMultiple.imageIdList = selectedImageIdList;
        this.ngxSpinnerService.show();
        this.eventService.addImageMultiple(this.eventAddImageMultiple)
            .subscribe(
                () => {
                    this.ngxSpinnerService.hide();

                    switch (this.selectedView) {
                        case 1:
                            this.toastr.success(`Selected photographer's images has been added to events successfully`);
                            this.photographerView.getContributors();
                            break;
                        case 2:
                            this.toastr.success('Selected folder has been added to events successfully');
                            this.folderView.getFolders();
                            break;
                        case 3:
                            this.toastr.success('Selected images has been added to events successfully');
                            this.imageView.getImages();
                            break;
                    }
                },
                error => {
                    this.ngxSpinnerService.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    doFilter() {
        this.loadImageStatuses();
        switch (this.selectedView) {
            case 1:
                this.photographerView.getContributors();
                break;
            case 2:
                this.folderView.getFolders();
                break;
            case 3:
                this.imageView.getImages();
                break;
        }

    }

    approveMultiple() {
        this.imageIdListForApprove = new Array<number>();

        switch (this.selectedView) {
            case 1:
                this.imageIdListForApprove = this.photographerView.getSelectedImageIdList();
                break;
            case 2:
                this.imageIdListForApprove = this.folderView.getSelectedImageIdList();
                break;
            case 3:
                this.imageIdListForApprove = this.imageView.getSelectedImageIdList();
                break;
        }

        setTimeout(() => {
            this.imageApproveMultipleModal.openImagePriceModal();
        }, 100);
    }

    rejectMultiple() {
        if (!confirm('Are you sure you want to reject selected images?')) {
            return;
        }

        const rejectMultipleModel = new ImageRejectMultipleModel();
        switch (this.selectedView) {
            case 1: rejectMultipleModel.imageIdList = this.photographerView.getSelectedImageIdList(); break;
            case 2: rejectMultipleModel.imageIdList = this.folderView.getSelectedImageIdList(); break;
            case 3: rejectMultipleModel.imageIdList = this.imageView.getSelectedImageIdList(); break;
        }

        if (rejectMultipleModel.imageIdList.length === 0) {
            return;
        }

        this.ngxSpinnerService.show();
        this.adminImageService.rejectMultiple(rejectMultipleModel)
            .subscribe(
                () => {
                    this.ngxSpinnerService.hide();
                    setTimeout(() => {
                        switch (this.selectedView) {
                            case 1:
                                this.photographerView.getContributors();
                                break;
                            case 2:
                                this.folderView.getFolders();
                                break;
                            case 3:
                                this.imageView.getImages();
                                break;
                        }
                    });
                },
                error => {
                    this.ngxSpinnerService.hide();
                    this.imageStatusId = '';
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    deleteMultiple() {
        if (!confirm('Are you sure you want to delete selected images?')) {
            return;
        }

        const deleteMultipleModel = new ImageDeleteMultipleModel();

        switch (this.selectedView) {
            case 1: deleteMultipleModel.imageIdList = this.photographerView.getSelectedImageIdList(); break;
            case 2: deleteMultipleModel.imageIdList = this.folderView.getSelectedImageIdList(); break;
            case 3: deleteMultipleModel.imageIdList = this.imageView.getSelectedImageIdList(); break;
        }

        if (deleteMultipleModel.imageIdList.length === 0) {
            return;
        }

        this.ngxSpinnerService.show();
        this.adminImageService.deleteMultiple(deleteMultipleModel).subscribe(
            () => {
                this.ngxSpinnerService.hide();
                setTimeout(() => {
                    switch (this.selectedView) {
                        case 1:
                            this.toastr.success(`Photographer's images has been deleted successfully.`);
                            this.photographerView.getContributors();
                            break;
                        case 2:
                            this.toastr.success(`Folder's images has been deleted successfully.`);
                            this.folderView.getFolders();
                            break;
                        case 3:
                            this.toastr.success(`Images has been deleted successfully.`);
                            this.imageView.getImages();
                            break;
                    }
                });
            }, error => {
                this.ngxSpinnerService.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    hardDeleteMultiple() {
        if (!confirm('Are you sure you want to delete selected images permanently?')) {
            return;
        }

        const deleteMultipleModel = new ImageDeleteMultipleModel();

        switch (this.selectedView) {
            case 1: deleteMultipleModel.imageIdList = this.photographerView.getSelectedImageIdList(); break;
            case 2: deleteMultipleModel.imageIdList = this.folderView.getSelectedImageIdList(); break;
            case 3: deleteMultipleModel.imageIdList = this.imageView.getSelectedImageIdList(); break;
        }

        if (deleteMultipleModel.imageIdList.length === 0) {
            return;
        }

        this.ngxSpinnerService.show();
        this.adminImageService.hardDeleteMultiple(deleteMultipleModel).subscribe(
            () => {
                this.ngxSpinnerService.hide();
                setTimeout(() => {
                    switch (this.selectedView) {
                        case 1:
                            this.toastr.success(`Photographer's images has been deleted successfully.`);
                            this.photographerView.getContributors();
                            break;
                        case 2:
                            this.toastr.success(`Folder's images has been deleted successfully.`);
                            this.folderView.getFolders();
                            break;
                        case 3:
                            this.toastr.success(`Images has been deleted successfully.`);
                            this.imageView.getImages();
                            break;
                    }
                });
            }, error => {
                this.ngxSpinnerService.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }


    openFolderView(photographerId: string) {
        this.selectedView = 2;
        this.imageFilterModel.photographer = photographerId;
    }

    openImageView(photographerId: string) {
        this.selectedView = 3;
        this.imageFilterModel.photographer = photographerId;
    }

    switchView(view: number) {
        this.tableModel.page = 1;
        if (view === 1 && this.imageFilterModel.resetPhotographer) {
            this.imageFilterModel.photographer = '';
        }
        if (view === 2) {
            this.imageFilterModel.folderName = '';
        }
        this.selectedView = view;
    }
}
