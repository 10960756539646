import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PhotographerRoutingModule } from './photographer-routing.module';
import { UploadImagesForApprovalComponent } from './components/upload-images-for-approval/upload-images-for-approval.component';
import { SharedModule } from '../shared/shared.module';
import { FileUploadModule } from 'ng2-file-upload';
import { DashboardComponent } from './dashboard.component';
import { ImagesHistoryComponent } from './components/images-history/images-history.component';
import { PhotographerImageService } from './services/photographer-image.service';
import { PhotographerProfileService } from './services/profile.service';
import { PhotographerProfileComponent } from '../photographer/components/profile/profile.component';

@NgModule({
  declarations: [
    DashboardComponent,
    UploadImagesForApprovalComponent,
    ImagesHistoryComponent,
    PhotographerProfileComponent
  ],
  imports: [
    CommonModule,
    PhotographerRoutingModule,
    FileUploadModule,
    SharedModule
  ],
  providers: [
    PhotographerImageService,
    PhotographerProfileService
  ]
})
export class PhotographerModule { }
