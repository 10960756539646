import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PageUrls } from 'src/app/helpers/page-urls';
import { EventService } from 'src/app/services/event.service';
import { TableModel } from 'src/app/models/table.model';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PagedEventImageDetails } from 'src/app/models/paged.event.image.detail';
import { EventRemoveImageMultiple } from 'src/app/models/event.remove.image.multiple.model';
import { EventSetCoverImageModel } from 'src/app/models/event.set.cover.image.model';
import { ImageDeleteMultipleModel } from 'src/app/models/image.delete.multiple.model';
import { EventImageDetailModel } from 'src/app/models/event.image.detail.model';
import { AddCartItemModel } from 'src/app/models/add.cart.item.model';
import { ListenerService } from 'src/app/services/listener.service';
import { CartService } from 'src/app/services/cart.service';
import { PremiumEventImagePreviewComponent } from 'src/app/buyer/components/premium-event/preview/premium.event.image.preview.component';

@Component({
    selector: 'app-premium-event-detail',
    templateUrl: './premium.event.detail.component.html',
    styleUrls: ['./premium.event.detail.component.scss']
})
export class PremiumEventDetailComponent implements OnInit, OnDestroy {
    tableModel = new TableModel();
    eventId: number;
    totalRecords: number;
    closeResult: string;
    imageSize: string;
    subscriptions: Subscription[];
    isModelLoaded = false;
    pagedEventImageDetails = new PagedEventImageDetails();
    eventRemoveImageMultiple = new EventRemoveImageMultiple();
    eventSetCoverImage = new EventSetCoverImageModel();
    imageDeleteMultiple = new ImageDeleteMultipleModel();
    selectedEventImage = new Array<EventImageDetailModel>();
    selectedImagesId = new Array<number>();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private listenerService: ListenerService,
        private eventService: EventService,
        private cartService: CartService) {

        this.route.queryParams.subscribe(params => {
            this.eventId = params['id'];
            this.eventRemoveImageMultiple.eventId = this.eventId;
            this.eventSetCoverImage.eventId = this.eventId;
        });
    }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
        this.tableModel = new TableModel();
        this.totalRecords = 0;
        this.subscriptions = [];
        this.initSubscribers();
        this.getEventImages();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getEventImages());
        this.subscriptions.push(subscription);
    }

    selectImage() {
        this.selectedEventImage = [];
        this.pagedEventImageDetails.items.forEach(element => {
            if (element.isSelected) {
                this.selectedEventImage.push(element);
            }
        });
    }

    getEventImages = () => {
        this.ngxSpinner.show();
        this.eventService.getPremiumEventImageList(this.eventId, this.tableModel)
            .subscribe(data => {
                Object.assign(this.pagedEventImageDetails, data);
                this.totalRecords = this.pagedEventImageDetails.totalCount;
                this.pagedEventImageDetails.items.forEach((item) => {
                    item.approvedRejectedOn = AppUtils.getFormattedDate(item.approvedRejectedOn, null);
                });
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    openSizeSelectionModal(content: any) {
        this.modalService.open(content);
    }

    buyImage(imageId: number) {
        const cartItemModel = new AddCartItemModel();
        cartItemModel.cartId = AppUtils.getCartId();
        cartItemModel.imageId = imageId;
        cartItemModel.imageSize = 3;

        this.ngxSpinner.show();
        this.cartService.addToCart(cartItemModel).subscribe(
            () => {
                this.ngxSpinner.hide();
                this.listenerService.refreshCartListner.next();
                this.router.navigate([PageUrls.Cart]);
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    buyMultiple() {
        const cartItemModel = new AddCartItemModel();
        cartItemModel.cartId = AppUtils.getCartId();
        cartItemModel.imageSize = Number(this.imageSize);
        this.selectedEventImage.forEach(element => {
            cartItemModel.imageIds.push(element.imageId);
        });
        this.ngxSpinner.show();
        this.cartService.addToCartMultiple(cartItemModel).subscribe(
            () => {
                this.ngxSpinner.hide();
                this.listenerService.refreshCartListner.next();
                this.router.navigate([PageUrls.Cart]);
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
        this.modalService.dismissAll();
    }

    buyAll() {
        const cartItemModel = new AddCartItemModel();
        cartItemModel.cartId = AppUtils.getCartId();
        cartItemModel.imageSize = Number(this.imageSize);
        cartItemModel.eventId = this.eventId;

        this.ngxSpinner.show();
        this.cartService.addToCartByEvent(cartItemModel).subscribe(
            () => {
                this.ngxSpinner.hide();
                this.listenerService.refreshCartListner.next();
                this.router.navigate([PageUrls.Cart]);
            },
            error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
        this.modalService.dismissAll();
    }

    openImage(eventImage: EventImageDetailModel) {
        const modal = this.modalService.open(PremiumEventImagePreviewComponent, { size: 'full-screen', centered: true });
        modal.componentInstance.setImage(eventImage.originalImageUrl);
        this.eventService.logImageViewActivity(this.eventId, eventImage.imageId).subscribe(
            () => {
                eventImage.activityType = 1;
            },
            error => {
                console.log(error);
            });
    }
}
