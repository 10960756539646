export const PageUrlsParts = {
    WorkWithUs: 'work-with-us',
    AboutUs: 'about-us',
    ContactUs: 'contact-us',
    PrivacyPolicy: 'privacy-policy',
    TermsAndConditions: 'terms-and-conditions',
    Login: 'login',
    Registration: 'sign-up',
    Dashboard: 'dashboard',
    VerifyEmail: 'verify-email',
    ResetPassword: 'reset-password',
    ForgotPassword: 'forgot-password',
    SetPassword: 'set-password',
    PhotographerSignUp: 'photographer/sign-up',
    ImageDetail: 'image-detail',
    Collections: 'collection',
    Cart: 'cart',
    SubscriptionPlans: 'subscription/plans',

    Photographer: {
        Self: 'photographer',
        Login: 'photographer/login',
        UploadImages: 'upload-images',
        ImagesHistory: 'images-history',
        Profile: 'profile'
    },
    Admin: {
        Self: 'admin',
        Login: 'admin/login',
        Dashboard: 'dashboard',
        Categories: 'categories',
        OrderHistory: 'order-history',
        ImageManage: 'image/manage',
        ImageHistory: 'image/history',
        UpdateImage: 'image/update',
        EventManage: 'event/manage',
        PremiumEvent: 'event/premium',
        PremiumEventDetail: 'event/premium/image-view',
        PremiumEventHistory: 'event/premium/image-history',
        EventAdd: 'event/add',
        EventEdit: 'event/edit',
        EventManageImage: 'event/manage-image',
        CollectionManage: 'collection/manage',
        CollectionAdd: 'collection/add',
        CollectionEdit: 'collection/edit',
        UserManage: 'user/manage',
        UserBuyerAdd: 'user/buyer/add',
        UserBuyerEdit: 'user/buyer/edit',
        SubscriberList: 'subscriber/list',
        UserNavigation: 'user',
        UserBuyer: 'user/buyer',
        UserContributor: 'user/contributor',
        UserPendingContributor: 'user/pending-contrilbutor',
        UserContributorAdd: 'user/contributor/add',
        UserContributorEdit: 'user/contributor/edit',
        SubscriptionPlanManage: 'subscription-plan/manage',
        SubscriptionPlanAdd: 'subscription-plan/add',
        SubscriptionPlanEdit: 'subscription-plan/edit',
        Order: 'order/list',
        OrderDetail: 'order/detail',
        ContributorEarning: 'contributor/earning',
        PaymentHistory: 'payment/history',
        PendingGrouped: 'image/pending-grouped',
        FinanceNavigation: 'finance',
        SubcategoryManage: 'subcategory/manage',
        SubcategoryAdd: 'subcategory/add',
        SubcategoryEdit: 'subcategory/edit',
        SearchedKeywordManage: 'searched-keyword/manage',
        SearchedKeywordDetail: 'searched-keyword/detail'
    },
    Buyer: {
        Self: 'user',
        Dashboard: 'dashboard',
        Wishlist: 'wish-list',
        Profile: 'profile',
        Order: 'order',
        OrderDetail: 'order/detail',
        Premium: 'premium-event',
        PremiumEventImages: 'premium-event/image',
    }
};

export const PageUrls = {

    WorkWithUs: `/${PageUrlsParts.WorkWithUs}`,
    AboutUs: `/${PageUrlsParts.AboutUs}`,
    ContactUs: `/${PageUrlsParts.ContactUs}`,
    PrivacyPolicy: `/${PageUrlsParts.PrivacyPolicy}`,
    TermsAndConditions: `/${PageUrlsParts.TermsAndConditions}`,
    Login: `/${PageUrlsParts.Login}`,
    Registration: `/${PageUrlsParts.Registration}`,
    PhotographerSignUp: `/${PageUrlsParts.PhotographerSignUp}`,
    Dashboard: `/${PageUrlsParts.Dashboard}`,
    VerifyEmail: `/${PageUrlsParts.VerifyEmail}`,
    ResetPassword: `/${PageUrlsParts.ResetPassword}`,
    ForgotPassword: `/${PageUrlsParts.ForgotPassword}`,
    SetPassword: `/${PageUrlsParts.SetPassword}`,
    ImageDetail: `/${PageUrlsParts.ImageDetail}`,
    Collections: `/${PageUrlsParts.Collections}`,
    Cart: `/${PageUrlsParts.Cart}`,
    SubscriptionPlans: `/${PageUrlsParts.SubscriptionPlans}`,
    Photographer: {
        Login: `/${PageUrlsParts.Photographer.Self}/${PageUrlsParts.Login}`,
        UploadImages: `/${PageUrlsParts.Photographer.Self}/${PageUrlsParts.Photographer.UploadImages}`,
        ImagesHistory: `/${PageUrlsParts.Photographer.Self}/${PageUrlsParts.Photographer.ImagesHistory}`,
        Profile: `/${PageUrlsParts.Photographer.Self}/${PageUrlsParts.Buyer.Profile}`
    },
    Admin: {
        Login: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Login}`,
        Dashboard: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.Dashboard}`,
        UpdateImage: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.UpdateImage}`,
        Categories: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.Categories}`,
        OrderHistory: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.OrderHistory}`,
        ImageManage: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.ImageManage}`,
        ImageHistory: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.ImageHistory}`,
        EventManage: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.EventManage}`,
        PremiumEvent: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.PremiumEvent}`,
        PremiumEventDetail: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.PremiumEventDetail}`,
        PremiumEventHistory: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.PremiumEventHistory}`,
        EventAdd: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.EventAdd}`,
        EventEdit: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.EventEdit}`,
        EventManageImage: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.EventManageImage}`,
        CollectionManage: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.CollectionManage}`,
        CollectionAdd: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.CollectionAdd}`,
        CollectionEdit: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.CollectionEdit}`,
        UserNavigation: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.UserNavigation}`,
        UserBuyer: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.UserBuyer}`,
        UserContributor: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.UserContributor}`,
        UserPendingContributor: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.UserPendingContributor}`,
        UserBuyerAdd: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.UserBuyerAdd}`,
        UserContributorAdd: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.UserContributorAdd}`,
        UserBuyerEdit: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.UserBuyerEdit}`,
        UserContributorEdit: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.UserContributorEdit}`,
        SubscriberList: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.SubscriberList}`,
        SubscriptionPlanAdd: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.SubscriptionPlanAdd}`,
        SubscriptionPlanEdit: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.SubscriptionPlanEdit}`,
        SubscriptionPlanManage: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.SubscriptionPlanManage}`,
        Order: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.Order}`,
        OrderDetail: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.OrderDetail}`,
        ContributorEarning: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.ContributorEarning}`,
        PaymentHistory: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.PaymentHistory}`,
        PendingGrouped: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.PendingGrouped}`,
        FinanceNavigation: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.FinanceNavigation}`,
        SubcategoryManage: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.SubcategoryManage}`,
        SubcategoryAdd: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.SubcategoryAdd}`,
        SubcategoryEdit: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.SubcategoryEdit}`,
        SearchedKeywordManage: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.SearchedKeywordManage}`,
        SearchedKeywordDetail: `/${PageUrlsParts.Admin.Self}/${PageUrlsParts.Admin.SearchedKeywordDetail}`
    },
    Buyer: {
        Dashboard: `/${PageUrlsParts.Buyer.Self}/${PageUrlsParts.Buyer.Dashboard}`,
        Wishlist: `/${PageUrlsParts.Buyer.Self}/${PageUrlsParts.Buyer.Wishlist}`,
        Profile: `/${PageUrlsParts.Buyer.Self}/${PageUrlsParts.Buyer.Profile}`,
        Order: `/${PageUrlsParts.Buyer.Self}/${PageUrlsParts.Buyer.Order}`,
        OrderDetail: `/${PageUrlsParts.Buyer.Self}/${PageUrlsParts.Buyer.OrderDetail}`,
        Premium: `/${PageUrlsParts.Buyer.Self}/${PageUrlsParts.Buyer.Premium}`,
        PremiumEventImages: `/${PageUrlsParts.Buyer.Self}/${PageUrlsParts.Buyer.PremiumEventImages}`
    }
};
