import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/app/helpers/app.utils';
import { TableModel } from 'src/app/models/table.model';
import { PageUrls } from 'src/app/helpers/page-urls';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/admin/services/event.service';
import { PagedEventCalenderViewDetails } from 'src/app/admin/models/event.calender.view.detail.model';
import { EventCalenderViewModel } from 'src/app/admin/models/event.calender.view.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-event-calender-view',
    templateUrl: './event.calender.view.component.html',
    styleUrls: ['./event.calender.view.component.scss']
})
export class EventCalenderViewComponent implements OnInit {
    @Output() monthViewSelected = new EventEmitter();
    subscriptions = new Array<Subscription>();
    pagedYearDetails = new PagedEventCalenderViewDetails();
    months = new Array<EventCalenderViewModel>();
    totalRecords = 0;
    tableModel = new TableModel();
    isModelLoaded = false;
    isMonthView: boolean;
    selectedYear: number;

    get urls() {
        return PageUrls;
    }

    constructor(private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private eventService: EventService,
        private router: Router) {
        this.initSubscribers();
        this.isMonthView = false;
    }

    ngOnInit() {
        this.getYears();
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getYears());
        this.subscriptions.push(subscription);
    }

    getYears = () => {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.eventService.yearList(this.tableModel)
            .subscribe(data => {
                Object.assign(this.pagedYearDetails, data);
                this.totalRecords = data.totalCount;
                this.tableModel.totalPages = data.totalPages;
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    getMonths = (year: number) => {
        this.isModelLoaded = false;
        this.selectedYear = year;
        this.ngxSpinner.show();
        this.eventService.monthList(year)
            .subscribe(data => {
                Object.assign(this.months, data);
                this.months.forEach(month => {
                    month.monthInNumber = month.name;
                    month.name = AppUtils.getMonthName(month.name);
                });
                this.ngxSpinner.hide();
                this.isMonthView = true;
                this.monthViewSelected.next();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    openTableView(month: number) {
        console.log(month, this.selectedYear);
        const tableModel = new TableModel();
        tableModel.startDate = AppUtils.getStartDate(month, this.selectedYear);
        tableModel.endDate = AppUtils.getEndDate(month, this.selectedYear);

        this.router.navigateByUrl(`${PageUrls.Admin.EventManage}?isTableView=true&${tableModel.toQueryString()}`);
    }

    yearViewSelected() {
        this.isMonthView = false;
    }
}
