import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/app/helpers/app.utils';
import { PageUrls } from 'src/app/helpers/page-urls';
import { SubscriptionPlanService } from 'src/app/services/subscription.plan.service';
import { AuthService } from 'src/app/services/auth.service';
import { BuyerSubscriptionService } from 'src/app/buyer/services/subscription.service';
import { BuyerProfileService } from 'src/app/buyer/services/profile.service';
import { SubscriptionPlanDetailModel } from 'src/app/models/subscription.plan.detail.model';
import { BuyerSubscriptionAddModel } from 'src/app/buyer/models/subscription.add.model';
import { BuyerProfileModel } from 'src/app/buyer/models/buyer.profile.model';
import { PaymentCreateModel } from 'src/app/models/payment.create.model';
import { UserSubscriptionDetailModel } from 'src/app/models/user.subscription.detail.model';

@Component({
    selector: 'app-subscription-plan-list',
    templateUrl: './subscription.plan.list.component.html',
    styleUrls: ['./subscription.plan.list.component.scss']
})

export class SubscriptionPlanListComponent implements OnInit {
    isModelLoaded = false;
    isUserLoggedIn = false;
    isPaymentProcessing = false;
    subscriptionPlans = new Array<SubscriptionPlanDetailModel>();
    userSubscription = new UserSubscriptionDetailModel();
    paymentCreateModel = new PaymentCreateModel();

    constructor(private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private authService: AuthService,
        private buyerSubscriptionService: BuyerSubscriptionService,
        private subscriptionPlanService: SubscriptionPlanService,
        private buyerProfileService: BuyerProfileService) { }

    get urls() {
        return PageUrls;
    }

    ngOnInit() {
        this.getSubscriptionPlans();
        this.isUserLoggedIn = this.authService.isUserLoggedIn();
        if (this.isUserLoggedIn) {
            this.getUserSubscription();
        }
    }

    getUserSubscription() {
        this.spinner.show();
        this.buyerSubscriptionService.getUserSubscription()
            .subscribe(data => {
                this.spinner.hide();
                Object.assign(this.userSubscription, data);
            }, error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    getSubscriptionPlans() {
        this.spinner.show();
        this.subscriptionPlanService.list()
            .subscribe(data => {
                this.spinner.hide();
                Object.assign(this.subscriptionPlans, data);
                this.isModelLoaded = true;
            }, error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    loadBuyerProfile() {
        this.spinner.show();
        this.buyerProfileService.getProfile()
            .subscribe(
                (response: BuyerProfileModel) => {
                    this.spinner.hide();
                    this.paymentCreateModel.firstName = response.firstName;
                    this.paymentCreateModel.lastName = response.lastName;
                    this.paymentCreateModel.email = response.email;
                    this.paymentCreateModel.address = response.address;
                    this.paymentCreateModel.city = response.city;
                    this.paymentCreateModel.zipCode = response.zipCode;
                    if (response.country) {
                        this.paymentCreateModel.country = response.country;
                    }

                    this.initPaymentProcessing();
                },
                error => {
                    this.spinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });
    }

    buySubscription(selectedSubscriptionPlan: SubscriptionPlanDetailModel) {
        if (selectedSubscriptionPlan.price <= 0) {
            this.toastr.error('Price can not be zero');
            return;
        }

        this.paymentCreateModel = new PaymentCreateModel();
        this.paymentCreateModel.entityId = selectedSubscriptionPlan.id;
        this.paymentCreateModel.amount = selectedSubscriptionPlan.price;
        this.loadBuyerProfile();

    }

    initPaymentProcessing() {
        this.isPaymentProcessing = true;
    }

    paymentCompleted(paymentIntentId: string) {
        const subscriptionAddModel = new BuyerSubscriptionAddModel();
        subscriptionAddModel.paymentIntentId = paymentIntentId;
        subscriptionAddModel.subscriptionPlanId = this.paymentCreateModel.entityId;
        this.spinner.show();
        this.buyerSubscriptionService.add(subscriptionAddModel).subscribe(
            () => {
                this.spinner.hide();
                this.isPaymentProcessing = false;
                this.toastr.success('Subscription has been created successfully');
                this.getUserSubscription();
            },
            error => {
                this.spinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    paymentCancelled() {
        this.isPaymentProcessing = false;
        this.paymentCreateModel = new PaymentCreateModel();
    }
}

