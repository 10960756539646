export class PaymentCreateModel {
    public entityId: any;
    public userId: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: string;
    public address: string;
    public city: string;
    public country: string;
    public zipCode: string;
    public amount: number;
    public paymentMethodId: string;

    constructor() {
        this.country = '';
    }
}
