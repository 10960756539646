export class BuyerProfileModel {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    city: string;
    country: string;
    zipCode: string;
    subscriptionPlanId: string;
    subscriptionPlanName: string;
    subscriptionStartDate: string;
    subscriptionEndDate: string;
}
