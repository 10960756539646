import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PageUrls } from 'src/app/helpers/page-urls';
import { EventService } from 'src/app/admin/services/event.service';
import { AppUtils } from 'src/app/helpers/app.utils';

@Component({
    selector: 'app-premium-event-shared-with',
    templateUrl: './premium.event.shared.with.component.html'
})

export class PremiumEventSelectedWithComponent {
    @ViewChild('eventSelectedWithModal', null) modalContent: any;
    @Output() reloadEvents = new EventEmitter();
    eventId: number;
    modalRef: NgbModalRef;
    buyers = new Array<any>();

    constructor(private router: Router,
        private modalService: NgbModal,
        private spinnerService: NgxSpinnerService,
        private toastr: ToastrService,
        private eventService: EventService) { }

    openModal(eventId: number) {
        this.modalRef = this.modalService.open(this.modalContent);
        this.eventId = eventId;
        this.loadBuyers();
    }

    loadBuyers() {
        this.spinnerService.show();
        this.eventService.getListEventSharedWith(this.eventId).subscribe(
            (data: Array<any>) => {
                this.buyers = data;
                this.spinnerService.hide();
            },
            error => {
                this.spinnerService.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    deleteSharedEvent(buyerId: string, index: number) {
        this.spinnerService.show();
        this.eventService.deleteSharedEvent(this.eventId, buyerId).subscribe(
            () => {
                this.spinnerService.hide();
                this.buyers.splice(index, 1);
                if (this.buyers.length === 0) {
                    this.modalRef.close();
                }
                this.reloadEvents.emit();
                this.toastr.success('Sharing has been removed successfully');
            }, error => {
                this.spinnerService.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    openUserImages(id: string) {
        this.router.navigateByUrl(`${PageUrls.Admin.PremiumEventDetail}?id=${id}&eventId=${this.eventId}`);
        this.modalService.dismissAll();
    }

    closeModal() {
        this.modalRef.close();
        this.reloadEvents.emit();
    }

}
