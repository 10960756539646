import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChangePasswordModel } from 'src/app/models/changepassword.model';
import { AppUtils } from 'src/app/helpers/app.utils';
import { Constants } from 'src/app/helpers/constants';
import { PageUrls } from 'src/app/helpers/page-urls';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  model: ChangePasswordModel;
  user = { currentPassword: '', password: '', confirmPassword: '' };
  userForm: FormGroup;
  isPasswordMatch: boolean;

  get currentPassword() { return this.userForm.get('currentPassword'); }
  get password() { return this.userForm.get('password'); }
  get confirmPassword() { return this.userForm.get('confirmPassword'); }

  constructor(private ngxSpinnerService: NgxSpinnerService, private authService: AuthService,
              private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.model = new ChangePasswordModel();
    this.userForm = new FormGroup({
      currentPassword: new FormControl(this.user.currentPassword, [
        Validators.required
      ]),
      password: new FormControl(this.user.password, [
        Validators.required,
        Validators.pattern(Constants.passwordRegEx)
      ]),
      confirmPassword: new FormControl(this.user.confirmPassword, [
        Validators.required
      ]),
    });
  }

  updateValue() {
    if (this.userForm.value.password && this.userForm.value.confirmPassword) {
      this.isPasswordMatch = this.userForm.value.password === this.userForm.value.confirmPassword;
    }
  }

  submit() {
    this.model.newPassword = this.userForm.value.password;
    this.model.currentPassword = this.userForm.value.currentPassword;
    this.ngxSpinnerService.show();
    this.authService.changepassword(this.model)
      .subscribe(response => {
        console.log(response);
        this.ngxSpinnerService.hide();
        this.toastr.success('Your password has been reset successfully.', '', {
          timeOut: 10000
        });
        this.authService.logout();
        this.router.navigateByUrl(`/${PageUrls.Login}`);
      },
      error => {
        console.error(error);
        this.ngxSpinnerService.hide();
        AppUtils.processErrorResponse(this.toastr, error);
      });
  }
}
