import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TableModel } from 'src/app/models/table.model';
import { PagedCollectionModel } from 'src/app/admin/models/paged.collection.model';
import { CollectionAddModel } from 'src/app/admin/models/collection.add.model';
import { CollectionEditModel } from 'src/app/admin/models/collection.edit.model';
import { CollectionSetAsThumbnailModel } from 'src/app/admin/models/collection.set.as.thumbnail.model';
import { CollectionRemoveThumbnailModel } from '../models/collection.remove.thumbnail.model';

@Injectable()
export class CollectionService {

    constructor(private http: HttpClient) { }

    add = (model: CollectionAddModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/collection/add`, model)

    edit = (model: CollectionEditModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/collection/edit`, model)


    list(tableModel: TableModel): Observable<PagedCollectionModel> {
        let url = `${environment.apiUrl}/api/admin/collection/list`;
        const queryParams = [];
        if (tableModel) {
            queryParams.push(tableModel.toQueryString());
        }
        if (queryParams.length) {
            url += '?' + queryParams.join('&');
        }
        return this.http.get<PagedCollectionModel>(url);
    }

    get = (id: number) =>
        this.http.get(`${environment.apiUrl}/api/admin/collection/${id}`)

    delete = (id: number) =>
        this.http.post(`${environment.apiUrl}/api/admin/collection/delete/${id}`, {})

    setAsThumbail = (model: CollectionSetAsThumbnailModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/collection/set-as-thumbnail`, model)

    removeThumbail = (model: CollectionRemoveThumbnailModel) =>
        this.http.post(`${environment.apiUrl}/api/admin/collection/remove-thumbnail`, model)

    isExists = (id: number, title: string) =>
        this.http.get(`${environment.apiUrl}/api/admin/collection/is-exists?id=${(id ? id : '')}&title=${title}`)
}
