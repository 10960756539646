import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { PagedSearchedKeywordDetailModel } from 'src/app/admin/models/paged.searched.keyword.detail.model';
import { SearchedKeywordService } from 'src/app/admin/services/searched.keyword.service';
import { AppUtils } from 'src/app/helpers/app.utils';
import { TableModel } from 'src/app/models/table.model';
import { PageUrls } from 'src/app/helpers/page-urls';

@Component({
    selector: 'app-searched-keyword-detail',
    templateUrl: './searched.keyword.detail.component.html',
    styleUrls: ['./searched.keyword.detail.component.scss']
})
export class SearchedKeywordDetailComponent implements OnInit {
    subscriptions = new Array<Subscription>();
    pagedSearchedKeywordDetails = new PagedSearchedKeywordDetailModel();
    totalRecords = 0;
    tableModel = new TableModel();
    isModelLoaded = false;
    keyword: string;

    manageTableModel = new TableModel();

    get urls() {
        return PageUrls;
    }

    constructor(private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private searchedKeywordService: SearchedKeywordService,
        location: PlatformLocation) {
        this.manageTableModel.orderBy = this.route.snapshot.queryParams.orderBy;
        this.manageTableModel.orderByDirection = this.route.snapshot.queryParams.orderBy;
        this.manageTableModel.page = this.route.snapshot.queryParams.page;
        this.manageTableModel.pageSize = this.route.snapshot.queryParams.pageSize;
        this.manageTableModel.filterKey = this.route.snapshot.queryParams.filterKey;
        this.manageTableModel.startDate = this.route.snapshot.queryParams.startDate;
        this.manageTableModel.endDate = this.route.snapshot.queryParams.endDate;
        if (!isNullOrUndefined(this.manageTableModel.startDate)) {
            this.tableModel.startDate = this.manageTableModel.startDate;
        }
        if (!isNullOrUndefined(this.manageTableModel.endDate)) {
            this.tableModel.endDate = this.manageTableModel.endDate;
        }

        location.onPopState(() => {
            setTimeout(() => {
                this.backToMainView();
            });
        });
    }

    ngOnInit() {
        this.keyword = this.route.snapshot.queryParams.keyword;
        this.initSubscribers();
        this.getSearchKeywords();
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getSearchKeywords());
        this.subscriptions.push(subscription);
    }

    getSearchKeywords = () => {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.searchedKeywordService.listForKeyword(this.keyword, this.tableModel)
            .subscribe(data => {
                Object.assign(this.pagedSearchedKeywordDetails, data);
                this.totalRecords = data.totalCount;
                this.pagedSearchedKeywordDetails.items.forEach(searchKeyword => {
                    searchKeyword.createdOn = AppUtils.getFormattedDate(searchKeyword.createdOn, 'MM/DD/YYYY hh:mm:ss A');
                });
                this.tableModel.totalPages = data.totalPages;
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    backToMainView() {
        this.router.navigateByUrl(`${PageUrls.Admin.SearchedKeywordManage}?${this.manageTableModel.toQueryString()}`);
    }
}
