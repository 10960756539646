import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AppUtils } from 'src/app/helpers/app.utils';
import { TableModel } from 'src/app/models/table.model';
import { PageUrls } from 'src/app/helpers/page-urls';
import { CollectionService } from 'src/app/admin/services/collection.service';
import { PagedCollectionModel } from 'src/app/admin/models/paged.collection.model';
import { CollectionSetAsThumbnailModel } from 'src/app/admin/models/collection.set.as.thumbnail.model';
import { CollectionRemoveThumbnailModel } from 'src/app/admin/models/collection.remove.thumbnail.model';

@Component({
    selector: 'app-collection-manage',
    templateUrl: './collection.manage.component.html'
})

export class CollectionManageComponent implements OnInit {

    subscriptions = new Array<Subscription>();
    selectedAll = false;
    totalRecords = 0;
    tableModel = new TableModel();
    pagedCollectionModel = new PagedCollectionModel();
    isModelLoaded = false;

    get urls() {
        return PageUrls;
    }

    constructor(private router: Router,
        private ngxSpinner: NgxSpinnerService,
        private toastr: ToastrService,
        private collectionService: CollectionService) {
        this.tableModel.orderBy = 'Date';
        this.tableModel.orderByDirection = 'desc';
    }

    ngOnInit() {
        this.initSubscribers();
        this.getCollections();
    }

    initSubscribers() {
        const subscription = this.tableModel.pageChanged.subscribe(() => this.getCollections());
        this.subscriptions.push(subscription);
    }

    getCollections() {
        this.isModelLoaded = false;
        this.ngxSpinner.show();
        this.collectionService.list(this.tableModel)
            .subscribe(data => {
                Object.assign(this.pagedCollectionModel, data);
                this.totalRecords = data.totalCount;
                this.pagedCollectionModel.items.forEach(item => {
                    item.createdOn = AppUtils.getFormattedDate(item.createdOn);
                });
                this.ngxSpinner.hide();
                this.isModelLoaded = true;
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    delete(id: number) {
        if (!confirm('Are you sure you want to delete this collection?')) {
            return;
        }
        this.ngxSpinner.show();
        this.collectionService.delete(id)
            .subscribe(() => {
                this.toastr.success('Collection has been deleted successfully');
                this.ngxSpinner.hide();
                this.getCollections();
            }, error => {
                this.ngxSpinner.hide();
                AppUtils.processErrorResponse(this.toastr, error);
            });
    }

    edit(id: number) {
        this.router.navigate([this.urls.Admin.CollectionEdit], { queryParams: { id } });
    }

    selectAll() {
        this.pagedCollectionModel.items.forEach(image => {
            image.isSelected = this.selectedAll;
        });
    }

    updateSelectAll() {
        const selectedImages = this.pagedCollectionModel.items.filter(x => x.isSelected);
        if (selectedImages.length !== this.pagedCollectionModel.items.length) {
            this.selectedAll = false;
        } else {
            this.selectedAll = true;
        }
    }

    setAsThumbnail() {
        const selectedCollections = new Array<number>();
        this.pagedCollectionModel.items.forEach(item => {
            if (item.isSelected) {
                selectedCollections.push(item.id);
            }
        });

        if (selectedCollections.length === 0) {
            this.toastr.error('Please select collection to continue');
            return;
        }

        if (selectedCollections.length > 5) {
            this.toastr.error('Max 5 collections can be marked as thumbnail');
            return;
        }

        const model = new CollectionSetAsThumbnailModel();
        model.collectionIdList = selectedCollections;

        this.ngxSpinner.show();
        this.collectionService.setAsThumbail(model)
            .subscribe(
                () => {
                    this.ngxSpinner.hide();
                    this.toastr.success('Selected collections has been marked as thumbnail');
                    this.selectedAll = false;
                    this.getCollections();
                },
                error => {
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });

    }

    removeThumbnail() {
        const selectedCollections = new Array<number>();
        this.pagedCollectionModel.items.forEach(item => {
            if (item.isSelected) {
                selectedCollections.push(item.id);
            }
        });

        if (selectedCollections.length === 0) {
            this.toastr.error('Please select collection to continue');
            return;
        }

        const model = new CollectionRemoveThumbnailModel();
        model.collectionIdList = selectedCollections;
        
        this.ngxSpinner.show();
        this.collectionService.removeThumbail(model)
            .subscribe(
                () => {
                    this.ngxSpinner.hide();
                    this.toastr.success('Selected collections has been removed as thumbnail');
                    this.selectedAll = false;
                    this.getCollections();
                },
                error => {
                    this.ngxSpinner.hide();
                    AppUtils.processErrorResponse(this.toastr, error);
                });

    }
}
