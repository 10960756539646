import { Component, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { IconDefinition, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { TableModel } from 'src/app/models/table.model';
import { SelectListItemModel } from 'src/app/models/select.list.item.model';
import { ImageFilterModel } from 'src/app/admin/models/image.filter.model';
import { EventService } from 'src/app/admin/services/event.service';
import { UserService } from 'src/app/admin/services/user.service';

@Component({
    selector: 'app-image-filter-drawer',
    templateUrl: './image.filter.drawer.component.html'
})

export class ImageFilterDrawerComponent implements OnInit {
    @ViewChild('filterDrawer', { static: false }) filterDrawer: NgbDropdown;
    @Input() tableModel: TableModel;
    @Input() filterModel: ImageFilterModel;
    @Output() doFilter = new EventEmitter();

    events = new Array<SelectListItemModel>();
    photographers = new Array<SelectListItemModel>();
    faFilter: IconDefinition;
    faSearch: IconDefinition;

    constructor(private eventService: EventService,
        private userService: UserService) { }

    ngOnInit() {
        this.faFilter = faFilter;
        this.faSearch = faSearch;
        this.loadEvents();
        this.loadPhotographers();
    }

    setImageStatus(status: number) {
        this.tableModel.orderByDirection = 'desc';
        this.filterModel.status = status;
        this.applyFilters();
    }

    loadEvents() {
        this.eventService.getSelectItems().subscribe(
            (events: Array<SelectListItemModel>) => {
                this.events = events;
            },
            error => {
                console.log(error);
            });
    }

    loadPhotographers() {
        this.userService.getContributorSelectItems().subscribe(
            (response) => {
                this.photographers = response;
            },
            error => {
                console.log(error);
            });
    }

    photographerChanged() {
        if (this.filterModel.photographer) {
            this.filterModel.resetPhotographer = false;
        } else {
            this.filterModel.resetPhotographer = true;
        }
        this.applyFilters();
    }

    applyFilters() {

        this.doFilter.emit();
        setTimeout(() => {
            this.filterDrawer.close();
        }, 300);
    }

}
