import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PaymentCreateModel } from 'src/app/models/payment.create.model';

@Injectable({
    providedIn: 'root'
})

export class PaymentService {

    constructor(private http: HttpClient) { }

    createPaymentIntent = (model: PaymentCreateModel) =>
        this.http.post(`${environment.apiUrl}/api/payment/create-intent`, model)

    confirmPaymentIntent = (paymentIntentId: string) =>
        this.http.post(`${environment.apiUrl}/api/payment/confirm-intent/${paymentIntentId}`, null)

    cancelPaymentIntent = (paymentIntentId: string) =>
        this.http.post(`${environment.apiUrl}/api/payment/cancel-intent/${paymentIntentId}`, null)

}

