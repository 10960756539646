export class ContributorEditModel {
    public id: number;
    public firstName: number;
    public lastName: string;
    public email: string;
    public address: string;
    public city: string;
    public country: string;
    public zipCode: string;
    public bankName: string;
    public accountNumber: string;
    public ifsc: string;
    public uniqueNumber: string;
    public commission: number;
}
