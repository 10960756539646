import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/app/helpers/app.utils';
import { ImageTypeModel } from 'src/app/models/image.type.model';
import { EventService } from 'src/app/services/event.service';
import { TableModel } from 'src/app/models/table.model';
import { Subscription } from 'rxjs';
import { ImageDetail } from 'src/app/models/image-detail.model';
import { PremiumEventDetailModel } from 'src/app/models/premium.event.detail.model';

@Component({
  selector: 'app-premium-event-list',
  templateUrl: './premium.event.list.component.html',
})

export class PremiumEventListComponent implements OnInit {
  tableModel = new TableModel();
  premiumModel = new Array<PremiumEventDetailModel>();
  totalPages: number;
  subscriptions = new Array<Subscription>();
  isPremiumEventloaded = false;
  imageTypes = new Array<ImageTypeModel>();
  photoGalleryMosaicItems: Array<any>;
  imageModel = new Array<ImageDetail>();
  selectedParentEventId: number;
  constructor(private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private eventService: EventService) { }

  ngOnInit() {
    this.loadEvents();
    this.imageTypes = AppUtils.getImageTypes();
  }

  loadEvents() {
    this.spinner.show();
    this.eventService.getPremiumEvent(this.tableModel).subscribe(
      pagedList => {
        this.premiumModel.push(...pagedList.items);
        this.totalPages = pagedList.totalPages;
        this.isPremiumEventloaded = true;
        this.updateEventPhotoGallery();
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.isPremiumEventloaded = true;
        AppUtils.processErrorResponse(this.toastr, error);
      });
  }

  loadMoreEvents() {
    this.tableModel.page += 1;
  }

  updateEventPhotoGallery() {

    const imageHeight = window.innerWidth > 1900 ? 350 : 300;
    const tempArray = new Array<any>();

    if (this.isPremiumEventloaded) {

      this.premiumModel.forEach((item) => {

        const imageWidth = Math.round(imageHeight * item.coverImageWidth / item.coverImageHeight);

        tempArray.push(
          {
            identifier: item.id,
            fullSizePreview: null,
            lowSizePreview: item.coverImageUrl,
            realWidth: imageWidth,
            realHeight: imageHeight,
            eventTitle: item.title,
            eventDate: item.date,
            eventCount: item.eventCount,
            imageCount: item.imageCount,
            itemCount: item.eventCount > 0 ? item.eventCount : item.imageCount,
            isParentEvent: true
          });
      });
    }
    this.photoGalleryMosaicItems = tempArray;
  }
}
