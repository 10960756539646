import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlbumModel } from '../models/album.model';

@Injectable({
  providedIn: 'root'
})
export class ImageCategoryService {

  constructor(private http: HttpClient) { }

  getCategories = () => this.http.get<AlbumModel[]>(`${environment.apiUrl}/api/image-category/list`);
}
